import { companyConstants } from '../_constants';

const initialStatus = {
  successAction: false,
  items: [],
  total: 0,
  loading: false,
  error: null
};

export function companies(state = initialStatus, action) {
  switch (action.type) {
    case companyConstants.KICK_REQUEST:
      return {
        ...state,
        successAction: false,
        doingAction: true,
        actionError: false,
        actionErrorMsg: null
      };

    case companyConstants.KICK_SUCCESS:
      const key = state.items.findIndex(item => item.id === action.company.id);
      let ii = state.items;

      if (key in ii) {
        ii.splice(key, 1, action.company);
      } else {
        ii.push(action.company);
      }

      return {
        ...state,
        successAction: true,
        doingAction: false,
        actionError: false,
        actionErrorMsg: null,
        items: ii
      };
      break;

    case companyConstants.KICK_FAILURE:
      return {
        ...state,
        successAction: false,
        doingAction: false,
        actionError: true,
        actionErrorMsg: action.error
      };
    case companyConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case companyConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.companies,
        total: action.meta.total,
        loading: false
      };
    case companyConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    default:
      return state;
  }
}
