import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowForwardIos';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction

} from '@material-ui/core';
import { history } from 'helpers';
import LongMenu from './LongMenu';



const useStyles = makeStyles(theme => ({
  root: {},
  listItem: {
    fontWeight: "strong",
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2),
    backgroundColor: 'lightgray',

  },
  actions: {
    justifyContent: 'flex-end'
  }
}));




const ItemList = ({ icon, currentItemId, className, items, itemMapper, onClick, actionPath }) => {


  const classes = useStyles();

  const handleClick = (item) => {
    if (item.action) {
      history.push(item.action)
    } else {
      if (onClick) onClick(item.id);
    }
  }
  return (
    <List>
      {items.map(i => {
        const item = itemMapper(i); if (!item) return null;
        return (

          <ListItem key={item.id} >
            <ListItemAvatar style={{ color: '#546e7a' }}>{item.icon ? item.icon : icon}</ListItemAvatar>
            <ListItemText
              classes={{ root: classes.listItem }}
              primary={item.primary}
              secondary={item.secondary}

            />
            <ListItemSecondaryAction>
              {item.actions ? <LongMenu options={item.actions}></LongMenu>
                : <IconButton edge="end" aria-label="show" onClick={() => handleClick(item)} >
                  <ArrowRightIcon ></ArrowRightIcon>
                </IconButton>

              }

            </ListItemSecondaryAction>
            <Divider></Divider>
          </ListItem>


        )
      }
      )}
    </List>

  );
};

ItemList.propTypes = {
  className: PropTypes.string,
};

export default ItemList;
