import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Button, LinearProgress, Toolbar as MuiToolbar } from '@material-ui/core';
import moment from 'moment';
import SearchFrom from './SearchFrom';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const defaultFormState = {
  text: '',
  department: 0,
  tag: 0,
  from: '',
  to: '',
}

const Toolbar = props => {
  const { className, setFilter, handleClick, handleImport, handleExport, reducer, ...rest } = props;

  const classes = useStyles();

  const [formValues, setFormvalues] = useState(defaultFormState);

  useEffect(() => {
    setFilter(formValues)
  }, [formValues])


  const doingExport = reducer.doingExport;
  

  const handleSearchFormChange = (formValues) => {
    setFormvalues(formValues)

  }

  const handleImportClick = () => {
    handleImport && handleImport(null);
  };
  const handleExportClick = () => {
    handleExport && handleExport(null);
  };
  const handleAddClick = () => {
    handleClick && handleClick(null);
  };

  return (
    <AppBar color="default" position="sticky">
      <MuiToolbar>
        <SearchFrom handleChange={handleSearchFormChange} />
      


        <span className={classes.spacer} />
        <Button style={{ marginRight: '10px' }} color="" variant="contained" onClick={handleImportClick}>
          Import
        </Button>
        <Button disabled={doingExport} style={{ marginRight: '10px' }} color="" variant="contained" onClick={handleExportClick}>
          Export
          {doingExport && (<LinearProgress ></LinearProgress>)}
        </Button>
        <Button color="primary" variant="contained" onClick={handleAddClick}>
          New
        </Button>
      </MuiToolbar>
    </AppBar>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
