import { authHeader } from '../helpers';
import handleResponse from './handleResponse';

export const companyService = {
  getAll,
  getById,
  exportExcel,
  _delete,
  doAction
};

function doAction(id, action, data = []) {
  return kick({
    action: action,
    companyId: id,
    data: data
  });
}
function kick(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(
    process.env.REACT_APP_API_ENDPOINT + `/api/company-kick`,
    requestOptions
  ).then(handleResponse);
}

function exportExcel() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  fetch(
    process.env.REACT_APP_API_ENDPOINT + `/api/company/export`,
    requestOptions
  ).then(res => {
    const filename = res.headers
      .get('Content-Disposition')
      .split('filename=')[1];
    res.blob().then(blob => {
      var fileURL = window.URL.createObjectURL(blob);

      var fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.download = filename;
      fileLink.click();
    });
  });
}

function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(
    process.env.REACT_APP_API_ENDPOINT + `/api/company/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getAll(text = '', page = '', per_page = '') {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    process.env.REACT_APP_API_ENDPOINT +
      `/api/company?page=${page}&per_page=${per_page}`,
    requestOptions
  ).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    process.env.REACT_APP_API_ENDPOINT + `/api/company/${id}`,
    requestOptions
  ).then(handleResponse);
}
