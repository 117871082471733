/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Chip, FormControl, InputLabel, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { attachmentActions } from '_actions';

export {
  ZnAttachmentModelSelect
}

const useStyles = makeStyles((theme) => ({

  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    // marginTop: theme.spacing(3),
  },
}));

const ZnAttachmentModelSelect = (props) => {

  const {
    name,
    onChange,
    value,
  } = props;


  // load available options on rendering
  const items = useSelector(state => state.attachments.items)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(attachmentActions.getAll());
  }, []);


  const classes = useStyles();

  return (
    <TextField
      variant='outlined'
      fullWidth
      margin="normal"
      labelId='attachment-model-salect-label'
      select
      SelectProps={{
        multiple: true,
        renderValue: (selected) => (
          <div className={classes.chips}>
            {selected.map((value, k) => {

              const item = items.find((item) => item.id === value)


              return item && (<Chip
                key={k}
                label={item.name ?? '---'}
                className={classes.chip} />)


            })}
          </div>
        )
      }}
      label="Attachments"
      // fullWidth
      name={name}
      value={value ?? []}
      onChange={onChange}

    >
      <MenuItem disabled value="">Attachments</MenuItem>
      {items.map((v, k) => (
        <MenuItem key={k} value={v.id}>{v.name}</MenuItem>
      ))}

    </TextField>

  );

}
