import { configConstants } from '../_constants';

export const configActions = {
    resetLeadSearch,
    setDepartment,
    setUser,
    setLeadPage,
    setLeadRowsPerPage,
    setLeadSearchTexts,
    setLeadSearchText,
    setLeadSearchEmail,
    setLeadSearchPhone,
    setLeadSearchSubject,
    setLeadSearchNotes,
    setLeadSearchStatuses,
    setLeadSearchDates,
    setLeadSearchDateFrom,
    setLeadSearchDateTo,
    setMessageSearchText,
    setMessageSearchStatuses,
    setActiveSearchBox,
    setNewsletterPage,
    setNewsletterRowsPerPage,
    setSurveyPage,
    setSurveyRowsPerPage,
    
};

function setDepartment(departmentId ) {
    return { type: configConstants.SET_DEPARTMENT, departmentId } 
}

function setUser(userId ) {
    return { type: configConstants.SET_USER, userId } 
}

function resetLeadSearch() {
    return { type: configConstants.RESET_LEAD_SEARCH } 
}

function setLeadSearchTexts(leadSearchTexts) {
    return { type: configConstants.SET_LEAD_SEARCH_TEXTS, leadSearchTexts } 
}

function setLeadSearchText(leadSearchText) {
    return { type: configConstants.SET_LEAD_SEARCH_TEXT, leadSearchText } 
}

function setLeadSearchEmail(leadSearchEmail) {
    return { type: configConstants.SET_LEAD_SEARCH_EMAIL, leadSearchEmail } 
}

function setLeadSearchPhone(leadSearchPhone) {
    return { type: configConstants.SET_LEAD_SEARCH_PHONE, leadSearchPhone } 
}

function setLeadSearchSubject(leadSearchSubject) {
    return { type: configConstants.SET_LEAD_SEARCH_SUBJECT, leadSearchSubject } 
}

function setLeadSearchNotes(leadSearchNotes) {
    return { type: configConstants.SET_LEAD_SEARCH_NOTES, leadSearchNotes } 
}


function setLeadSearchStatuses(leadSearchStatuses) {
    return { type: configConstants.SET_LEAD_SEARCH_STATUSES, leadSearchStatuses } 
}

function setLeadSearchDates(from, to) {
    return { type: configConstants.SET_LEAD_SEARCH_DATES, from, to } 
}

function setLeadSearchDateFrom(from) {
    return { type: configConstants.SET_LEAD_SEARCH_DATE_FROM, from } 
}

function setLeadSearchDateTo(to) {
    return { type: configConstants.SET_LEAD_SEARCH_DATE_TO, to } 
}



function setLeadPage(page) {
    return { type: configConstants.SET_LEAD_PAGE, page } 
}
function setLeadRowsPerPage(rowsPerPage) {
    return { type: configConstants.SET_LEAD_ROWS_PER_PAGE, rowsPerPage } 
}

function setMessageSearchText(messageSearchText) {
    return { type: configConstants.SET_MESSAGE_SEARCH_TEXT, messageSearchText } 
}
function setMessageSearchStatuses(messageSearchStatuses) {
    return { type: configConstants.SET_MESSAGE_SEARCH_STATUSES, messageSearchStatuses } 
}

function setActiveSearchBox(activeSearchBox) {
    return { type: configConstants.SET_ACTIVE_SEARCH_BOX, activeSearchBox } 
}

function setNewsletterPage(page) {
    return { type: configConstants.SET_NEWSLETTER_PAGE, page } 
}
function setNewsletterRowsPerPage(rowsPerPage) {
    return { type: configConstants.SET_NEWSLETTER_ROWS_PER_PAGE, rowsPerPage } 
}

function setSurveyPage(page) {
    return { type: configConstants.SET_SURVEY_PAGE, page } 
}
function setSurveyRowsPerPage(rowsPerPage) {
    return { type: configConstants.SET_SURVEY_ROWS_PER_PAGE, rowsPerPage } 
}