import { customerConstants } from '../_constants';

const initialStatus = {
  successAction: false,
  items: [],
  total: 0,
  loading: false,
  error: null,
  doingExport: false,
};

export function customers(state = initialStatus, action) {
  switch (action.type) {
    case customerConstants.KICK_REQUEST:
      return {
        ...state,
        successAction: false,
        doingAction: true,
        actionError: false,
        actionErrorMsg: null
      };

    case customerConstants.KICK_SUCCESS:



      const key = state.items.findIndex(item => item.id === action.customer.id);
      let ii = state.items;

      if (key in ii) {
        ii.splice(key, 1, action.customer);
      } else {
        ii.push(action.customer);
      }

      return {
        ...state,
        successAction: true,
        doingAction: false,
        actionError: false,
        actionErrorMsg: null,
        items: ii,
        item: action.customers,
        item: action.customer,


      };


      break;

    case customerConstants.KICK_FAILURE:
      return {
        ...state,
        successAction: false,
        doingAction: false,
        actionError: true,
        actionErrorMsg: action.error
      };

    case customerConstants.IMPORT_REQUEST:
      return {
        ...state,
        successAction: false,
        doingAction: true,
        actionError: false,
        actionErrorMsg: null
      };

    case customerConstants.IMPORT_SUCCESS:

      return {
        ...state,
        successAction: true,
        doingAction: false,
        actionError: false,
        actionErrorMsg: null,
      };
      break;

    case customerConstants.IMPORT_FAILURE:
      return {
        ...state,
        successAction: false,
        doingAction: false,
        actionError: true,
        actionErrorMsg: action.error.message
      };

    case customerConstants.EXPORT_REQUEST:
      return {
        ...state,
        doingExport: true,
      };

    case customerConstants.EXPORT_SUCCESS:

      return {
        ...state,
        doingExport: false,
      };
      break;

    case customerConstants.EXPORT_FAILURE:
      return {
        ...state,
        doingExport: false,
      };
    case customerConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case customerConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.customers,
        total: action.meta.total,
        loading: false
      };
    case customerConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case customerConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };

    case customerConstants.GETONE_SUCCESS:
      return {
        ...state,
        item: action.customers,
        loading: false
      };
    case customerConstants.GETONE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    default:
      return state;
  }
}
