import { attachmentConstants } from '../_constants';
import { attachmentService } from '../_services';
import { alertActions } from './alert.actions';
import { alertConstants } from '../_constants';
import { createRef } from 'react'



export const attachmentActions = {
    add,
    addFromMessageModel,
    attach,
    remove,
    getAllByMessageId,

    save,
    getAll,
    getOne,
    download,


    _delete,

};


function _delete(id) {

    return dispatch => {
        dispatch(request());

        attachmentService._delete(id)
            .then(
                attachments => dispatch(getAll()),
                error => dispatch(failure(error))
            );
    };

    function request() {
        return { type: attachmentConstants.DELETE_REQUEST }
    }
    function success(user) {
        return { type: attachmentConstants.DELETE_SUCCESS, user }
    }
    function failure(error) {
        return { type: attachmentConstants.DELETE_FAILURE, id, error }
    }
}

function download(attachementId) {



    attachmentService.download(attachementId).then(result => {

        return result.blob().then(blob => {
            const filename = result.headers.get('Content-Disposition').split('filename=')[1].replace(/['"]+/g, '');
            
            return {
              filename: filename,
              blob: blob
            }
          }) .then(res => {
            var downloadUrl = URL.createObjectURL(res.blob);
            var a = document.createElement("a");
            a.href = downloadUrl;
            a.download = res.filename;
            document.body.appendChild(a);
            a.click();
    
        });


    })
   


    // attachmentService.download(attachementId).then(result => {

    //     const filename = res.headers.get('Content-Disposition').split('filename=')[1];
    //     const blob = result.blob();

    //     return { blob, filename }

    //     result.blob().then(blob => {

    //         // var blob = new Blob([], {type: 'application/pdf'});

    //         var downloadUrl = URL.createObjectURL(blob);
    //         var a = document.createElement("a");
    //         a.href = downloadUrl;
    //         a.download = 'fileName.pdf';
    //         document.body.appendChild(a);
    //         a.click();

    //     });

    // });



}


function addFromMessageModel(modelName, messageId, messageModelId) {

    return dispatch => {
        dispatch(request());
        attachmentService.addFromMessageModel(modelName, messageId, messageModelId)
            .then(
                attachments => {
                    //  dispatch(alertActions.success('File added'));
                    dispatch(success(attachments));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return { type: attachmentConstants.ATTACH_REQUEST };
    }
    function success(attachments) {
        return { type: attachmentConstants.ATTACH_SUCCESS, attachments };
    }
    function failure(error) {
        return { type: attachmentConstants.ATTACH_FAILURE, error };
    }

}

function add(modelName, messageId, fileName) {

    return dispatch => {
        dispatch(request());
        attachmentService.add(modelName, messageId, fileName)
            .then(
                attachments => {
                    dispatch(alertActions.success('File added'));
                    dispatch(success(attachments));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return { type: attachmentConstants.ATTACH_REQUEST };
    }
    function success(attachments) {
        return { type: attachmentConstants.ATTACH_SUCCESS, attachments };
    }
    function failure(error) {
        return { type: attachmentConstants.ATTACH_FAILURE, error };
    }

}

function save(attachment) {

    return dispatch => {
        dispatch(request());
        attachmentService.doAction(attachment.id, attachmentConstants.ACTION_SAVE, attachment)
            .then(
                result => {
                    dispatch(alertActions.success('Attachment Saved'));
                    dispatch(success(result.data));
                },
                error => {
                    dispatch(failure(error));
                }
            );


    };

    function request() {
        return { type: attachmentConstants.KICK_REQUEST };
    }
    function success(attachment) {
        return { type: attachmentConstants.KICK_SUCCESS, attachment };
    }
    function failure(error) {
        return { type: attachmentConstants.KICK_FAILURE, error };
    }
}

function getAll() {
    return (dispatch, getState) => {

        const { departmentId } = getState().config;
        dispatch(request());

        attachmentService.getAll([departmentId])
            .then(
                attachments => dispatch(success(attachments.data)),
                error => dispatch(failure(error))
            );
    };

    function request() {
        return { type: attachmentConstants.GETALL_REQUEST }
    }
    function success(attachments) {
        return { type: attachmentConstants.GETALL_SUCCESS, attachments }
    }
    function failure(error) {
        return { type: attachmentConstants.GETALL_FAILURE, error }
    }
}

function getOne(id) {


    return dispatch => {
        dispatch(request());

        attachmentService.getById(id)
            .then(
                attachment => dispatch(success(attachment.data)),
                error => dispatch(failure(error))
            );
    };

    function request() {
        return { type: attachmentConstants.GETONE_REQUEST }
    }
    function success(attachment) {
        return { type: attachmentConstants.GETONE_SUCCESS, attachment }
    }
    function failure(error) {
        return { type: attachmentConstants.GETONE_FAILURE, id, error }
    }
}

function remove(modelName, messageId, fileName) {
    return dispatch => {
        dispatch(request());
        attachmentService.remove(modelName, messageId, fileName)
            .then(
                attachments => {
                    dispatch(alertActions.success('File removed'));
                    dispatch(success(attachments));
                },
                error => {
                    dispatch(failure(error));
                }
            );


    };

    function request() {
        return { type: attachmentConstants.REMOVE_REQUEST };
    }
    function success(attachments) {
        return { type: attachmentConstants.REMOVE_SUCCESS, attachments };
    }
    function failure(error) {
        return { type: attachmentConstants.REMOVE_FAILURE, error };
    }
}
function attach(modelName, messageId, file) {

    return dispatch => {
        dispatch(request());
        attachmentService.attach(modelName, messageId, file)
            .then(
                attachments => {

                    dispatch(success(attachments));
                    dispatch(alertActions.success('File attached'));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return { type: attachmentConstants.ATTACH_REQUEST };
    }
    function success(attachments) {
        return { type: attachmentConstants.ATTACH_SUCCESS, attachments };
    }
    function failure(error) {
        return { type: attachmentConstants.ATTACH_FAILURE, error };
    }

}

function getAllByMessageId(modelName, messageId) {

    return dispatch => {
        dispatch(request());

        attachmentService.getAllByMessageId(modelName, messageId)
            .then(
                attachments => dispatch(success(attachments)),
                error => dispatch(failure(error)),
            );
    };

    function request() {
        return { type: attachmentConstants.GETALL_BY_MESSAGE_REQUEST }
    }
    function success(attachments) {
        return { type: attachmentConstants.GETALL_BY_MESSAGE_SUCCESS, attachments }
    }
    function failure(error) {
        return { type: alertConstants.ERROR, attachment: error.attachment };
    }
}

