import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';
import {  history } from 'helpers';
import { LeadStatus } from 'views/Lead/LeadsDetail';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    // display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));




const MessagesTable = props => {
  const { className, messages, ...rest } = props;

  const classes = useStyles();

  const [selectedMessages, setSelectedMessages] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Message</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {messages.slice(page*rowsPerPage, (page*rowsPerPage)+rowsPerPage).map(message => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={message.id}
                    selected={selectedMessages.indexOf(message.id) !== -1}
                    onClick={() => history.push("/leads/" + message.leadId)}
                  >
                    <TableCell >
                            <Typography variant="h5"   align="left" >
                            {message.senderName} {'<' + message.senderEmail + '>'}
                            </Typography>
                            <Typography align="left">
                            {message.subject}
                            </Typography>
                           
                    </TableCell>
                    <TableCell >
                            
                            <Typography align="right">{moment(message.date).fromNow()}</Typography>
                            <Typography align="right"><LeadStatus  status={message.leadStatus} /></Typography>

                    </TableCell>
                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={messages.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]}
        />
      </CardActions>
    </Card>
  );
};

MessagesTable.propTypes = {
  className: PropTypes.string,
};

export default MessagesTable;
