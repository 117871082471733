import React, { useEffect } from 'react';
import ZnTextField from 'common/znFields/ZnTextField';
import { useState } from 'react';
import { leadActions } from '_actions';
import { useDispatch, useSelector } from 'react-redux';
import validate from 'validate.js';
import ZnDraft2Area from 'common/znFields/ZnDraft2Area';
import draftToHtml from 'draftjs-to-html';
import { ZnMessageModelSelect } from 'common/znFields/ZnMessageModelSelect';
import ZnAttachmentsArea from 'common/znFields/ZnAttachmentsArea';
import { attachmentActions } from '_actions/attachment.actions';
import messageSign from './MessageSign';
import { Backdrop, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { ZnEmailSelect } from 'common/znFields/ZnUserSelect';



const useStyles = makeStyles(theme => ({
  
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    color: '#fff',
  },
}));

const schema = {
  subject: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 200
    }
  },
 

};

const defaultFormState = {
  loaded: false,
  isValid: false,
  values: {
    leadId: 'n/a',
    name: '',
    subject: '',
    cc: '',
    department: '',
    language: '',
    body: "",
    tmpId: null,
    attachments: [],
  },
  touched: {},
  errors: {}
}


const MessageBoxForm = props => {

  const { lead, open, setOpen, message } = props;

  const dispatch = useDispatch();

  const classes = useStyles();

  


  const userId = useSelector(state => state.authentication.user.attributes.id);
  const users = useSelector(state => state.users.items);
  
  const appendSing = (bodyPlain) => {


    const userInfo = users.find(item => {
      return item.id === userId
    })

    const firma = messageSign(
      userInfo.sign_name,
      userInfo.sign_role,
      userInfo.sign_email,
      userInfo.sign_mobile,
      userInfo.sign_phone
    );
    let body = {
      blocks: [],
      entityMap: {}
    };

    if (bodyPlain) {
      body = JSON.parse(bodyPlain);
    }

    body.blocks = body.blocks.concat(firma.contentBlocks);
    body.entityMap = { ...body.entityMap, ...firma.entityMap };

    const val = JSON.stringify(body);
    return val;

  }

  

  const getDefaultFormState = () => {
    return {
      ...defaultFormState,
      values: {
        ...defaultFormState.values,
        body: appendSing()
      }
    };
  }


  const handleMessageModelChange = (event, values) => {
    event.persist();
    
    if(values.code === undefined || ! values.code) return;
    const model = models.find(item => item.id === values.code);
    if (model.body) {
      model.body = appendSing(model.body.replace('%LEAD_NAME%', lead.name));
    }

    console.log(model)

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        subject: model.subject,
        cc: model.cc,
        body: model.body,
        attachments: model.attachmentModelsDetail,
        // attachments: model.attachments,
      }

    }));

    // dispatch(attachmentActions.addFromMessageModel('message', tmpId, values.code));

  };
  const models = useSelector(state => state.messageModels.items);

  /*
   * handle field changes
   */
  const handleChange = (event) => {
    
    event.persist();
    handleFieldChange(event.target.name, event.target.type === 'checkbox'
      ? event.target.checked
      : event.target.value);
  };

  const ccHandleChange = (event, values) => {
    handleFieldChange('cc', values.join(','));
  };

  const handleBodyChange = (value) => {
    handleFieldChange('body', value);
  }

  const handleFieldChange = (fieldName, value) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [fieldName]: value,
      },
      touched: {
        ...formState.touched,
        [fieldName]: true
      }
    }));
  };

  const sendMessage = () => {

    if (!formState.isValid) return;

    setFormState(formState => ({
      ...formState,
      touched: schema
    }));

    setSendRequested(true);

    dispatch(leadActions.sendMessage(formState.values.leadId, {
      ...formState.values,
      tmpId: tmpId,
      body: draftToHtml(JSON.parse(formState.values.body))
    }));
  }

  
  const sendMailRequest = useSelector(state => state.leads.sendMailRequest);
  
  useEffect(()=> {
    if(sendMailRequest === true) {
      sendMessage();
    }
  }, [sendMailRequest])

  /*
   * handle submit
   */
  const handleKeyPressed = event => {
    if (event.type === 'keypress' && event.key !== 'Enter') {
      return;
    }
    event.preventDefault();
    sendMessage();
  };

  /*
   * handle statuses
   */
  const doingAction = useSelector(state => state.leads.sending);
  //const successAction = useSelector(state => state.leads.successAction);
  const actionErrorMsg = useSelector(state => state.leads.error);

  const [sentRequested, setSendRequested] = useState(false);
  useEffect(() => {
    if (doingAction === false && sentRequested === true) handleClose();
  }, [doingAction]);


  const handleClose = () => {
    if (!doingAction) {
      setOpen(false);
      setFormState(getDefaultFormState());
    }
  };

  const [formState, setFormState] = useState(getDefaultFormState());

  const formValidation = () => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }

  useEffect(() => {
    formValidation();
  }, [formState.values]);


  const [tmpId, setTmpId] = useState(Date.now());


  useEffect(() => {
    if (message) {
      setFormState({
        ...formState,
        values: {
          ...formState.values,
          ...message,
        }
      });
    } else {
      setFormState(getDefaultFormState());
    }

    if (message.id) {
      setTmpId(message.id);
    } else {
      setTmpId(Date.now());
    }

  }, [message, open]);


  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      isValid: actionErrorMsg ? false : true,
      errors: actionErrorMsg ? actionErrorMsg.errors : {},
    }));
  }, [actionErrorMsg]);

  const hasError = (field) => {

    return formState.errors && formState.errors[field] ? true : false;
  }

  const getError = (field) => {
    if (!formState.errors) return null;
    return formState.errors[field] && formState.errors[field][0];
  }

  // ATTACHMENTS

  const messageAttachment = useSelector(state => state.attachments.message);
  useEffect(() => {

    if (!(messageAttachment instanceof Object)) return;

    const attachments = formState.values.attachments;
    
    attachments.push({
      id: messageAttachment.id,
      name: messageAttachment.name,
    });
    handleFieldChange('attachments', attachments);

  }, [messageAttachment]);

  const attachments = useSelector(state => state.attachments);
  // useEffect(() => {
  //   dispatch(attachmentActions.getAllByMessageId('message', tmpId));
  // }, []);

  const attachmentsAreaHadleAttach = (event) => {
    dispatch(attachmentActions.attach('message', tmpId, event.target.files[0]));

  }
  const attachmentsAreaHadleSelect = (attachment) => {
    
    const media = attachment.media.shift();
    if (!media) {
      alert("Error: selected attachment has no file !!")
      return;
    }
    const attachments = formState.values.attachments;
    
    attachments.push({
      id: media.id,
      name: media.filename + '.' + media.extension,
    });
    handleFieldChange('attachments', attachments);  
    //dispatch(attachmentActions.add('message', tmpId, fileName));

  }
  const attachmentsAreaHadleRemove = (attachmentId) => {
    const attachments = formState.values.attachments.filter(obj => obj.id !== attachmentId);
    handleFieldChange('attachments', attachments);
    //dispatch(attachmentActions.remove('message', tmpId, fileName));
  }
  const attachmentsAreaHadleDownload = (fileName) => {
    attachmentActions.download(`message/${tmpId}/`, fileName);
  }


  return (
    <div>
      <Backdrop className={classes.backdrop} open={doingAction} >
        <CircularProgress color="inherit" />
      </Backdrop>
        <Grid container spacing={3}>
          <Grid item xs={12}>

          <ZnMessageModelSelect 
            onChange={handleMessageModelChange} 
            language={lead.jurisdiction}/>
          <ZnTextField
            onKeyPress={handleKeyPressed}
            name="subject"
            label="Subject"
            onChange={handleChange}
            value={formState.values.subject}
            error={hasError('subject')}
            helperText={getError('subject')}
            margin="normal"
            variant="outlined"
          />
          <ZnEmailSelect
           name="cc"
           label="Cc"
           onChange={ccHandleChange}
           value={formState.values.cc}
           error={hasError('cc')}
           helperText={getError('cc')}
           margin="normal"
           variant="outlined"
          />
          <ZnAttachmentsArea
            tmpId={tmpId}
            selectedAttachments={formState.values.attachments ?? []}
            attachments={attachments}
            handleRemove={attachmentsAreaHadleRemove}
            handleAttach={attachmentsAreaHadleAttach}
            handleSelect={attachmentsAreaHadleSelect}
            handleDownload={attachmentsAreaHadleDownload}
          ></ZnAttachmentsArea>

          <ZnDraft2Area
            onChange={handleBodyChange}
            value={formState.values.body}
          />
          </Grid>
        </Grid>
       
    </div>
  );
}

MessageBoxForm.defaultProps = {
  open: false,
  message: {},
}
export default MessageBoxForm;
