import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const loggerMiddleware = createLogger();

const enhancer = composeEnhancers(
  applyMiddleware(
    thunkMiddleware,
  //  loggerMiddleware
  )
);

export const store = createStore(
  rootReducer,
  enhancer
);