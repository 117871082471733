import React, {  useEffect } from 'react';
import { Router } from 'react-router-dom';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import { history } from './helpers';
import { SnackbarProvider } from 'notistack';
import Notifier from 'Notifier';
import { userActions } from '_actions';
import { useDispatch, useSelector } from 'react-redux';



Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

const App = props => {

    const dispatch = useDispatch();
    
    const loggedIn = useSelector(state => state.authentication.loggedIn);

    useEffect(() => {
      if (loggedIn) {
        dispatch(userActions.getAll());
      }
    }, []);

    return (
      <SnackbarProvider 
        autoHideDuration={3000}
        maxSnack={5}  
        anchorOrigin={{ vertical: 'top', horizontal: 'right', }}>
        <Notifier />
        <ThemeProvider theme={theme}>
          <Router basename={''} history={history}>
            <Routes />
          </Router>
        </ThemeProvider>
      </SnackbarProvider >
    );
  
}

export default App;


