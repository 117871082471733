import { sourceConstants } from '../_constants';
import { sourceService } from '../_services';
import { alertActions } from '.';


export const sourceActions = {
    save,
    getAll,
    getOne,
    _delete,
};




function save(source) {

    return dispatch => {

        dispatch(request());

        sourceService.doAction(source.id, sourceConstants.ACTION_SAVE, source)
            .then(
                result => {
                    dispatch(alertActions.success('Source Saved'));
                    dispatch(success(result.data));
                },
                error => {
                    dispatch(failure(error));
                }
            );
       
            
    };

    function request() {
        return { type: sourceConstants.KICK_REQUEST };
    }
    function success(source) {
        return { type: sourceConstants.KICK_SUCCESS, source };
    }
    function failure(error) {
        return { type: sourceConstants.KICK_FAILURE, error };
    }
}

function getAll(text = '', page = '', per_page = '') {
    return (dispatch, getState) => {
        const {departmentId} = getState().config;

        dispatch(request());

        sourceService.getAll([departmentId], text, page, per_page)
            .then(
                sources => {
                    dispatch(success(sources.data, sources.meta));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };

    function request() { 
        return { type: sourceConstants.GETALL_REQUEST } 
    }
    function success(sources, meta) { 
        return { type: sourceConstants.GETALL_SUCCESS, sources, meta} 
    }
    function failure(error) { 
        return { type: sourceConstants.GETALL_FAILURE, error } 
    }
}

function getOne(id) {
    
    return dispatch => {
        dispatch(request());

        sourceService.getById(id)
            .then(
                source => dispatch(success(source.data)),
                error => dispatch(failure(error))
            );
    };

    function request() { 
        return { type: sourceConstants.GETONE_REQUEST } 
    }
    function success(source) { 
        return { type: sourceConstants.GETONE_SUCCESS, source } 
    }
    function failure(error) { 
        return { type: sourceConstants.GETONE_FAILURE, id, error } 
    }
}

function _delete(id) {
    
    return dispatch => {
        dispatch(request());

        sourceService._delete(id)
            .then(
                source => dispatch(getAll()),
                //source => dispatch(success(source.data)),
                error => dispatch(failure(error))
            );
    };

    function request() { 
        return { type: sourceConstants.DELETE_REQUEST } 
    }
    // function success(source) { 
    //     return { type: sourceConstants.DELETE_SUCCESS, source } 
    // }
    function failure(error) { 
        return { type: sourceConstants.DELETE_FAILURE, id, error } 
    }
}



