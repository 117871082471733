import { authHeader } from '../helpers';
import handleResponse from './handleResponse';

export const surveyService = {
    getAll,
    getById,
    exportExcel,
};

function exportExcel() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    fetch(process.env.REACT_APP_API_ENDPOINT + `/api/survey/export`, requestOptions)
        .then(
            res => {
                const filename = res.headers.get('Content-Disposition').split('filename=')[1];
                res.blob().then(blob => {
                    var fileURL = window.URL.createObjectURL(blob);
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.download = filename;
                    fileLink.click();
                });
        });
}

function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/survey/${id}`, requestOptions).then(handleResponse);
}





function getAll(page = '', per_page = '') {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/survey?page=${page}&per_page=${per_page}`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/survey/${id}`, requestOptions).then(handleResponse);
}


