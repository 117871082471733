import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { Box, Card, CardContent, CardHeader, Paper } from '@material-ui/core';
import Typography from 'theme/typography';



const LeadsByCountryAndDept = props => {
  const { series = [], categories = [], name = '' } = props;




  const cartData = {
    options: {
      chart: {
        type: 'donut',
        id: "LeadsByCountryAndDept",

      },
      xaxis: {
        categories: categories
      },
      labels: categories,

    },
    series: series
  };


  return (categories && series) ?
    <Card>
      <CardHeader title={name}
      subheader="All incoming leads by country">
      </CardHeader>
      <CardContent>
        { cartData.series.length > 0 ? 
        <ReactApexChart
          type="donut"
          options={cartData.options}
          series={cartData.series}
          height={350} />
          : <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={350}
        >
          <Box>no data</Box>
        </Box>
          
          }
      </CardContent>
    </Card> : <>loading</>

};

LeadsByCountryAndDept.propTypes = {
  series: PropTypes.array,
  categories: PropTypes.array,
};

export default LeadsByCountryAndDept;
