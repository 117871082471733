
import React from 'react';
import { useSelector } from 'react-redux';
import { List } from './components';

import { TagIcon as Icon } from 'icons';
import { tagActions as actions } from '_actions';


const listMapper = (item) => {
  return {
    id: item.id,
    action: null,
    primary: item.name,
    secondary: item.customerCount > 0 ? `${item.customerCount} customers` : 'not used',

  };
}

const icon = <Icon />

const View = () => {

  const reducer = useSelector(state => state.tags);

  return <List
    mapper={listMapper}
    icon={icon}
    actions={actions}
    reducer={reducer}
  />;
};

export default View;

