import { analyticConstants } from '../_constants';
import { analyticService } from '../_services';

export const analyticActions = {
    getAll,
    getUser,
    getDepartment,
    getCart,
    getCart3,
};

function getCart3(from,to) {
    return (dispatch) => {

        dispatch(request());

        analyticService.getCart3(from,to)
            .then(
                analytics => dispatch(success(analytics)),
                error => dispatch(failure(error))
            );
    };

    function request() {
        return { type: analyticConstants.GET_CART_3_REQUEST }
    }
    function success(analytics) {
        return { type: analyticConstants.GET_CART_3_SUCCESS, analytics }
    }
    function failure(error) {
        return { type: analyticConstants.GET_CART_3_FAILURE, error }
    }
}

function getCart(filters = {}) {
    return (dispatch) => {

        dispatch(request());

        analyticService.getCart(filters)
            .then(
                analytics => dispatch(success(analytics)),
                error => dispatch(failure(error))
            );
    };

    function request() {
        return { type: analyticConstants.GET_CART_REQUEST }
    }
    function success(analytics) {
        return { type: analyticConstants.GET_CART_SUCCESS, analytics }
    }
    function failure(error) {
        return { type: analyticConstants.GET_CART_FAILURE, error }
    }
}


function getAll(from,to) {
    return (dispatch) => {

        dispatch(request());

        analyticService.getAll(from,to)
            .then(
                analytics => dispatch(success(analytics)),
                error => dispatch(failure(error))
            );
    };

    function request() {
        return { type: analyticConstants.GETALL_REQUEST }
    }
    function success(analytics) {
        return { type: analyticConstants.GETALL_SUCCESS, analytics }
    }
    function failure(error) {
        return { type: analyticConstants.GETALL_FAILURE, error }
    }
}


function getUser(from,to) {
    return (dispatch) => {

        dispatch(request());

        analyticService.getUser(from,to)
            .then(
                analytics => dispatch(success(analytics)),
                error => dispatch(failure(error))
            );
    };

    function request() {
        return { type: analyticConstants.GETUSER_REQUEST }
    }
    function success(analytics) {
        return { type: analyticConstants.GETUSER_SUCCESS, analytics }
    }
    function failure(error) {
        return { type: analyticConstants.GETUSER_FAILURE, error }
    }
}


function getDepartment(from,to) {
    return (dispatch) => {

        dispatch(request());

        analyticService.getDepartment(from,to)
            .then(
                analytics => dispatch(success(analytics)),
                error => dispatch(failure(error))
            );
    };

    function request() {
        return { type: analyticConstants.GETDEPARTMENT_REQUEST }
    }
    function success(analytics) {
        return { type: analyticConstants.GETDEPARTMENT_SUCCESS, analytics }
    }
    function failure(error) {
        return { type: analyticConstants.GETDEPARTMENT_FAILURE, error }
    }
}


