import React from 'react';
import {  useSelector } from 'react-redux';
import { leadConstants } from '_constants';
import SummaryBox from '../SummaryBox';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';

const LeadsErp = props => {
  
  const total = useSelector(state => state.leads.overview.erp);
  

  return (
    <SummaryBox 
      icon={<ListAltOutlinedIcon fontSize="large" />}
      title="Loaded into Elibra"
      subtitle={leadConstants.LEAD_STATUS_MESSAGES.LEAD_STATUS_ERP.process_action}
      total={total}
      color={leadConstants.LEAD_STATUS_COLORS.ERP}
      statuses={[
        leadConstants.LEAD_STATUS_ERP,
      ]}
    />
  );
};

export default LeadsErp;
