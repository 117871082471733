import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { MessageModelsToolbar, MessageModelsTable } from '.';
import { useDispatch, useSelector } from 'react-redux';
import { messageModelActions } from '../../../../_actions';
import MessageModelDetail from './MessageModelDetail';
import { isConstructorDeclaration } from 'typescript';
import ZnDraftArea from 'common/znFields/ZnDraftArea';
import ItemList from 'common/ItemList';
import { MessageModelIcon } from '../../../../icons';

import { Button, Divider, List, ListItem, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core';
import { debounce } from 'underscore';
import { ZnButton } from 'common/znFields/ZnButton';
import { ZnModalConfirm } from 'common/znFields/ZnModalConfirm';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    backgroundColor: "white",
  },
  content: {
    marginTop: theme.spacing(0)
  }
}));

const MessageModelList = () => {
  const classes = useStyles();
  const messageModels = useSelector(state => state.messageModels)
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({
    text: "",
  });


  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const hadleFilterChange = (value) => {
    setFilter(value);
    setPage(0);
  }



  // load data

  useEffect(() => {
    dispatch(messageModelActions.getAll(filter, page + 1, rowsPerPage));
  }, [page, rowsPerPage, filter]);

  // detail edit modal
  const [open, setOpen] = React.useState(false);
  const [messageModelId, setMessageModelId] = React.useState(null);
  const [messageModel, setMessageModel] = React.useState(null);

  useEffect(() => {
    const key = messageModels.items.findIndex(item => item.id === messageModelId);

    if (key in messageModels.items) {
      setMessageModel(messageModels.items[key]);
    } else {
      setMessageModel(null);
    }

  }, [messageModelId, messageModels]);


  const handleMessageModelClick = (newId) => {
    setMessageModelId(newId);
    setOpen(true);
  }


  const messageModelMapper = (messageModel) => {
    const withAttchments = (messageModel.attachments.length > 0) ? 'WITH ATTACHMENTS' : '';
    return {
      id: messageModel.id,
      actions: [
        <Button onClick={e => handleMessageModelClick(messageModel.id)} color="primary" >Edit</Button>,
        <Button onClick={e => handleMessageModelClickDelete(messageModel.id)} color="primary" >Delete</Button>,
      ],
      primary: messageModel.name,
      secondary: '(#' + messageModel.id + ') ' + withAttchments,
    };
  }





  const [modalConfirmData, setModalConfirmData] = useState();
  const [modalConfirmOpened, setModalConfirmOpened] = useState(false);
  const modalConfirmConfirmed = (id) => {
    setModalConfirmOpened(false);
    dispatch(messageModelActions._delete(id));
  }
  const modalConfirmDenied = (id) => {
    setModalConfirmOpened(false);
  }

  const handleMessageModelClickDelete = (id) => {
    setModalConfirmData(id);
    setModalConfirmOpened(true);
  }





  return (
    <div className={classes.root}>
      <MessageModelsToolbar setFilter={hadleFilterChange} handleMessageModelClick={handleMessageModelClick} />
      <div className={classes.content}>
        <Table>
          <TableHead>
            <TableRow>

              {/* <TableCell>Name</TableCell> */}
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Attachments</TableCell>
              <TableCell>Old Attachments</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messageModels.items && messageModels.items.map(messageModel => {

              const oldAttachmentNames = messageModel.attachments.map(ii => ii.name)
              const newAttachmentNames = messageModel.attachmentModelsDetail.map(ii => ii.name)


              return (
                <TableRow
                  // className={classes.tableRow}
                  // hover
                  key={messageModel.id}
                >
                  <TableCell align='center'>
                    <MessageModelIcon fontSize='medium' color='action' />
                  </TableCell>
                  <TableCell>
                    (#{messageModel.id}){messageModel.subject}
                  </TableCell>

                  <TableCell valign='top'>
                    <List>
                      {messageModel.attachmentModelsDetail.sort().map((at, ak) => (
                        <ListItem key={ak} >
                          <Typography color={!oldAttachmentNames.includes(at.name) ? 'primary' : ''} >
                            {at.name}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </TableCell>

                  <TableCell valign='top'>
                    <Fragment>
                      <List>
                        {messageModel.attachments.sort().map((at, ak) => (
                          <ListItem key={ak} >
                            <Typography color={!newAttachmentNames.includes(at.name) ? 'primary' : ''} >
                              {at.name}
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    </Fragment>
                  </TableCell>

                  <TableCell>
                    <Button onClick={e => handleMessageModelClick(messageModel.id)} color="primary" >Edit</Button>
                    <Button onClick={e => handleMessageModelClickDelete(messageModel.id)} color="primary" >Delete</Button>
                  </TableCell>
                </TableRow>
              )

            })}
          </TableBody>
        </Table>
        {/* <ItemList icon={(<MessageModelIcon />)} items={messageModels.items} itemMapper={messageModelMapper} onClick={handleMessageModelClick} /> */}
        <MessageModelDetail handleMessageModelClickDelete={handleMessageModelClickDelete} setOpen={setOpen} open={open} messageModel={messageModel}></MessageModelDetail>
      </div>
      {(messageModels.items && messageModels.items.length > 0) && (
        <TablePagination
          component="div"
          count={messageModels.total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200, 1000]}
        />
      )}
      <ZnModalConfirm
        confirmTitle="Do you really want to delete it ?"
        confirmText={`Please confirm you want to permanently delete.`}
        data={modalConfirmData}
        onConfirm={modalConfirmConfirmed}
        onDeny={modalConfirmDenied}
        opened={modalConfirmOpened}
      ></ZnModalConfirm>
    </div>
  );
};

export default MessageModelList;



