export const companyConstants = {
  GETALL_REQUEST: 'COMPANY_GETALL_REQUEST',
  GETALL_SUCCESS: 'COMPANY_GETALL_SUCCESS',
  GETALL_FAILURE: 'COMPANY_GETALL_FAILURE',

  SYNCALL_REQUEST: 'COMPANY_SYNCALL_REQUEST',
  SYNCALL_SUCCESS: 'COMPANY_SYNCALL_SUCCESS',
  SYNCALL_FAILURE: 'COMPANY_SYNCALL_FAILURE',

  EXPORT_REQUEST: 'COMPANY_EXPORT_REQUEST',
  EXPORT_SUCCESS: 'COMPANY_EXPORT_SUCCESS',
  EXPORT_FAILURE: 'COMPANY_EXPORT_FAILURE',

  ACTION_SAVE: 'COMPANY_ACTION_SAVE',

  KICK_REQUEST: 'COMPANY_KICK_REQUEST',
  KICK_SUCCESS: 'COMPANY_KICK_SUCCESS',
  KICK_FAILURE: 'COMPANY_KICK_FAILURE',

  GETONE_REQUEST: 'COMPANY_GETONE_REQUEST',
  GETONE_SUCCESS: 'COMPANY_GETONE_SUCCESS',
  GETONE_FAILURE: 'COMPANY_GETONE_FAILURE',

  DELETE_REQUEST: 'COMPANY_DELETE_REQUEST',
  DELETE_SUCCESS: 'COMPANY_DELETE_SUCCESS',
  DELETE_FAILURE: 'COMPANY_DELETE_FAILURE',

  RECOVER_REQUEST: 'COMPANY_RECOVER_REQUEST',
  RECOVER_SUCCESS: 'COMPANY_RECOVER_SUCCESS',
  RECOVER_FAILURE: 'COMPANY_RECOVER_FAILURE'
};
