export const sourceFormConstants = {

    ACTION_SAVE: 'SOURCE_FORM_ACTION_SAVE',
    ACTION_UPDATE_PASSWORD: 'SOURCE_FORM_ACTION_UPDATE_PASSWORD',

    KICK_REQUEST: 'SOURCE_FORM_KICK_REQUEST',
    KICK_SUCCESS: 'SOURCE_FORM_KICK_SUCCESS',
    KICK_FAILURE: 'SOURCE_FORM_KICK_FAILURE',

    GETALL_REQUEST: 'SOURCE_FORM_GETALL_REQUEST',
    GETALL_SUCCESS: 'SOURCE_FORM_GETALL_SUCCESS',
    GETALL_FAILURE: 'SOURCE_FORM_GETALL_FAILURE',

    GETONE_REQUEST: 'SOURCE_FORM_GETONE_REQUEST',
    GETONE_SUCCESS: 'SOURCE_FORM_GETONE_SUCCESS',
    GETONE_FAILURE: 'SOURCE_FORM_GETONE_FAILURE',

    DELETE_REQUEST: 'SOURCE_FORM_DELETE_REQUEST',
    DELETE_SUCCESS: 'SOURCE_FORM_DELETE_SUCCESS',
    DELETE_FAILURE: 'SOURCE_FORM_DELETE_FAILURE',


};
