import { newsletterConstants } from '../_constants';

const initialStatus = {
  successAction: false,
  items: [],
  total: 0,
  messageItems: [],
  loading: false,
  error: null,

};

export function newsletters(state = initialStatus, action) {

  switch (action.type) {
    case newsletterConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case newsletterConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.newsletters,
        total: action.meta.total,
        loading: false
      };
    case newsletterConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case newsletterConstants.SYNCALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case newsletterConstants.SYNCALL_SUCCESS:
      return {
        ...state,
        items: action.newsletters,
        total: action.meta.total,
        loading: false
      };
    case newsletterConstants.SYNCALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
}