import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';
import MessageBoxList from './MessageBoxList';

const useStyles = makeStyles(() => ({
  root: {},
}));

const MessageBox = props => {

  const { lead, className, showMailForm, setShowMailForm, ...rest } = props;

  const classes = useStyles();


  const getMessages = () => {
    if (!lead) return [];
    if (!Array.isArray(lead.messages)) return [];

    return lead.messages;
  }




  return (
    <Fragment>
      <Divider />
      <Card {...rest} mt={2} className={clsx(classes.root, className)}>
        <CardHeader title="E-mail messages" />
        <CardContent>
          <MessageBoxList messages={getMessages()}></MessageBoxList>
        </CardContent>
      </Card>
    </Fragment>
    );
};

MessageBox.propTypes = {
  className: PropTypes.string
};

export default MessageBox;
