import { companyConstants } from '../_constants';
import { companyService } from '../_services';
import { alertActions } from './alert.actions';

export const companyActions = {
  save,
  getAll,
  getOne,
  _delete
};

function save(company) {
  return dispatch => {
    dispatch(request());

    companyService
      .doAction(company.id, companyConstants.ACTION_SAVE, company)
      .then(
        result => {
          dispatch(alertActions.success('Company Saved'));
          dispatch(success(result.data));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: companyConstants.KICK_REQUEST };
  }
  function success(company) {
    return { type: companyConstants.KICK_SUCCESS, company };
  }
  function failure(error) {
    return { type: companyConstants.KICK_FAILURE, error };
  }
}

function getAll(text = '', page = '', per_page = '') {
  return dispatch => {
    dispatch(request());

    companyService.getAll(text, page, per_page).then(
      companies => {
        dispatch(success(companies.data, companies.meta));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: companyConstants.GETALL_REQUEST };
  }
  function success(companies, meta) {
    return { type: companyConstants.GETALL_SUCCESS, companies, meta };
  }
  function failure(error) {
    return { type: companyConstants.GETALL_FAILURE, error };
  }
}

function getOne(id) {
  return dispatch => {
    dispatch(request());

    companyService.getById(id).then(
      company => dispatch(success(company.data)),
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: companyConstants.GETONE_REQUEST };
  }
  function success(company) {
    return { type: companyConstants.GETONE_SUCCESS, company };
  }
  function failure(error) {
    return { type: companyConstants.GETONE_FAILURE, id, error };
  }
}

function _delete(id) {
  return dispatch => {
    dispatch(request());

    companyService._delete(id).then(
      company => dispatch(getAll()),
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: companyConstants.DELETE_REQUEST };
  }
  function failure(error) {
    return { type: companyConstants.DELETE_FAILURE, id, error };
  }
}
