import React, { useEffect, useState } from 'react';
import {
    List, ListItem,  Button, Typography, Divider,
  FormControlLabel, Checkbox,    Input, 
} from '@material-ui/core';
import { ZnDepartmentSimpleSelect } from 'common/znFields/ZnDepartmentSelect';
import { ZnUserSimpleSelect } from 'common/znFields/ZnUserSelect';
import { useDispatch, useSelector } from 'react-redux';
import { configActions } from '_actions/config.actions';
import { leadConstants } from '_constants';


import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';



const SearchForm = props => {


  const dispatch = useDispatch();

  const activeSearchBox = useSelector(state => state.config.activeSearchBox);


  const leadSearchText = useSelector(state => state.config.leadSearchText);

  const leadSearchEmail = useSelector(state => state.config.leadSearchEmail);
  const leadSearchPhone = useSelector(state => state.config.leadSearchPhone);
  const leadSearchSubject = useSelector(state => state.config.leadSearchSubject);
  const leadSearchNotes = useSelector(state => state.config.leadSearchNotes);

  const leadSearchStatuses = useSelector(state => state.config.leadSearchStatuses);
  const leadSearchDateFrom = useSelector(state => state.config.leadSearchDatesFrom);
  const leadSearchDateTo = useSelector(state => state.config.leadSearchDatesTo);

 

  // status
  const handleLeadSearchStatusChange = event => {

    const checked = event.target.checked;
    const value = event.target.value;

    let sts = [...leadSearchStatuses];
    if (sts.indexOf(value) >= 0)  sts.splice(sts.indexOf(value), 1);
    
    if (checked)  sts.push(value);

    dispatch(
      configActions.setLeadSearchStatuses(sts)
    );
  };

  const getLeadStautsChecked = (status) => {
    const val = leadSearchStatuses.includes(status);
    return val;
  }

  const handleDateFromChange = (from) => {
    dispatch(
      configActions.setLeadSearchDateFrom(from)
    );
  }

  const handleDateToChange = (to) => {
    dispatch(
      configActions.setLeadSearchDateTo(to)
    );
  }


  
  

  const [searchTexts, setSearchTexts] = useState({});


  useEffect(()=> { 
    setSearchTexts({
      leadSearchText,
      leadSearchEmail,
      leadSearchPhone,
      leadSearchSubject,
      leadSearchNotes
    }); 
  }, [leadSearchText,leadSearchEmail, leadSearchPhone,leadSearchSubject,leadSearchNotes]);

 
  const handleLeadSearchTextsChange = event => {
    let pr =  {};
    pr[event.target.name] = event.target.value;
    setSearchTexts({...searchTexts, ...pr });
  };
  const handleLeadSearchTextsKeyPress = event => {
    if (event.type === 'keypress' && event.key === 'Enter') {
      dispatch(configActions.setLeadSearchTexts(searchTexts));
    }
  };
  
  const currentUserId = useSelector(state => state.authentication.user.attributes.id);
  const onlyMine = event => {
    dispatch(configActions.setUser(currentUserId));
  }

  const resetSearch = () => {
    dispatch(configActions.resetLeadSearch());
  }

  const departmentId = useSelector(state => state.config.departmentId);

  const handleChangeDepartment = value => {
    dispatch(configActions.setDepartment(value));
  };

  return (
    <div style={{ padding: "0 15px" }}>
      <List>
       
        <ListItem>
          <Button onClick={resetSearch} >Reset Search</Button>
        </ListItem>
        <ListItem>
          <Button onClick={onlyMine}>Show Only Mine</Button>
        </ListItem>
      </List>
      <List>
        <ListItem>
          <Input 
            autoComplete="ppp" 
            name="leadSearchText" 
            onChange={handleLeadSearchTextsChange}
            onKeyPress={handleLeadSearchTextsKeyPress}
            fullWidth={true} 
            placeholder="Search" 
            value={searchTexts.leadSearchText} ></Input>
        </ListItem>
        <ListItem><Input value={searchTexts.leadSearchEmail} autoComplete="ppp" name="leadSearchEmail" onChange={handleLeadSearchTextsChange} onKeyPress={handleLeadSearchTextsKeyPress} fullWidth={true} placeholder="email" ></Input></ListItem>
        <ListItem><Input value={searchTexts.leadSearchPhone} autoComplete="ppp" name="leadSearchPhone" onChange={handleLeadSearchTextsChange} onKeyPress={handleLeadSearchTextsKeyPress} fullWidth={true} placeholder="phone" ></Input></ListItem>
        <ListItem><Input value={searchTexts.leadSearchSubject} autoComplete="ppp" name="leadSearchSubject" onChange={handleLeadSearchTextsChange} onKeyPress={handleLeadSearchTextsKeyPress} fullWidth={true} placeholder="subject" ></Input></ListItem>
        <ListItem><Input value={searchTexts.leadSearchNotes} autoComplete="ppp" name="leadSearchNotes" onChange={handleLeadSearchTextsChange} onKeyPress={handleLeadSearchTextsKeyPress} fullWidth={true} placeholder="notes" ></Input></ListItem>
      </List>
      <List>
        <ListItem>
          <Typography variant="h5">Deps & Users</Typography>
          <Divider></Divider>
        </ListItem>
        <ListItem>
          <ZnDepartmentSimpleSelect value={departmentId} onChange={handleChangeDepartment}></ZnDepartmentSimpleSelect>
        </ListItem>
        <ListItem>
          <ZnUserSimpleSelect></ZnUserSimpleSelect>
        </ListItem>
      </List>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <List>
          <ListItem>
            <Typography variant="h5">Dates</Typography>
            <Divider></Divider>
          </ListItem>
          <ListItem>
            <KeyboardDatePicker
              margin="normal"
              label="From Day"
              format="dd/MM/yyyy"
              value={leadSearchDateFrom}
              onChange={handleDateFromChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </ListItem>
          <ListItem>
            <KeyboardDatePicker
              margin="normal"
              label="To Day"
              format="dd/MM/yyyy"
              value={leadSearchDateTo}
              onChange={handleDateToChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </ListItem>
        </List>
      </MuiPickersUtilsProvider>


      {activeSearchBox === 'lead' && (
        <List>
          <ListItem>
            <Typography variant="h5">Statuses</Typography>
            <Divider></Divider>
          </ListItem>
          <ListItem>
            <List style={{ marginLeft: -15 }} >
              <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                <FormControlLabel control={<Checkbox onChange={handleLeadSearchStatusChange}
                  value={leadConstants.LEAD_STATUS_NEW_MESSAGE}
                  checked={getLeadStautsChecked(leadConstants.LEAD_STATUS_NEW_MESSAGE)}
                  color="primary" />}
                  label="New Message" />
              </ListItem>
              <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                <FormControlLabel control={<Checkbox onChange={handleLeadSearchStatusChange}
                  value={leadConstants.LEAD_STATUS_NEW}
                  checked={getLeadStautsChecked(leadConstants.LEAD_STATUS_NEW)}
                  color="primary" />}
                  label="New Lead" />
              </ListItem>
              <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                <FormControlLabel control={<Checkbox onChange={handleLeadSearchStatusChange}
                  value={leadConstants.LEAD_STATUS_CHECKED}
                  checked={getLeadStautsChecked(leadConstants.LEAD_STATUS_CHECKED)}
                  color="primary" />}
                  label="Checked" />
              </ListItem>
              <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                <FormControlLabel control={<Checkbox onChange={handleLeadSearchStatusChange}
                  value={leadConstants.LEAD_STATUS_FIRST_CONTACT}
                  checked={getLeadStautsChecked(leadConstants.LEAD_STATUS_FIRST_CONTACT)}
                  color="primary" />}
                  label="Processing" />
              </ListItem>
              <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                <FormControlLabel control={<Checkbox onChange={handleLeadSearchStatusChange}
                  value={leadConstants.LEAD_STATUS_OK}
                  checked={getLeadStautsChecked(leadConstants.LEAD_STATUS_OK)}
                  color="primary" />}
                  label="Converted" />
              </ListItem>
              <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                <FormControlLabel control={<Checkbox
                  onChange={handleLeadSearchStatusChange}
                  value={leadConstants.LEAD_STATUS_ERP}
                  checked={getLeadStautsChecked(leadConstants.LEAD_STATUS_ERP)}
                  color="primary" />}
                  label="Elibra" />
              </ListItem>
              <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                <FormControlLabel control={<Checkbox
                  onChange={handleLeadSearchStatusChange}
                  value={leadConstants.LEAD_STATUS_ERP_COMPLETED}
                  checked={getLeadStautsChecked(leadConstants.LEAD_STATUS_ERP_COMPLETED)}
                  color="primary" />}
                  label="Matter Completed" />
              </ListItem>
              <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                <FormControlLabel control={<Checkbox onChange={handleLeadSearchStatusChange}
                  value={leadConstants.LEAD_STATUS_KO}
                  checked={getLeadStautsChecked(leadConstants.LEAD_STATUS_KO)}
                  color="primary" />}
                  label="Not Converted" />
              </ListItem>
              <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                <FormControlLabel control={<Checkbox onChange={handleLeadSearchStatusChange}
                  value={leadConstants.LEAD_STATUS_SPAM}
                  checked={getLeadStautsChecked(leadConstants.LEAD_STATUS_SPAM)}
                  color="primary" />}
                  label="Spam" />
              </ListItem>
              <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                <FormControlLabel control={<Checkbox onChange={handleLeadSearchStatusChange}
                  value={leadConstants.LEAD_STATUS_AUTO_SPAM}
                  checked={getLeadStautsChecked(leadConstants.LEAD_STATUS_AUTO_SPAM)}
                  color="primary" />}
                  label="AutoSpam" />
              </ListItem>
              <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                <FormControlLabel control={<Checkbox onChange={handleLeadSearchStatusChange}
                  value={leadConstants.LEAD_STATUS_AUTO_REPLY}
                  checked={getLeadStautsChecked(leadConstants.LEAD_STATUS_AUTO_REPLY)}
                  color="primary" />}
                  label="AutoReply" />
              </ListItem>
            </List>
          </ListItem>
        </List>
      )}

    </div>


  );
};

export default SearchForm;
