import React, {  Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowForwardIos';

import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  
  Grid,
  Hidden,
  
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';
import { history } from 'helpers';
import { LeadStatus } from 'views/Lead/LeadsDetail';
import { ZnDepartmentValue } from 'common/znFields/ZnDepartmentSelect';
import { ZnUserValue } from 'common/znFields/ZnUserSelect';




const useStyles = makeStyles(theme => ({
  root: {},
  listItem : {
      fontWeight:"strong",
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));




const LeadsTable = ({ className, leads }) => {


  const classes = useStyles();


  return (
    <Fragment>
      <Hidden smDown>
        <Table>
          <TableHead>
            <TableRow >
              <TableCell colSpan="1">ID</TableCell>
              <TableCell colSpan="2">Lead</TableCell>
              <TableCell colSpan="1" >Department</TableCell>
              <TableCell colSpan="1" >Assigned to</TableCell>
              <TableCell colSpan="1" align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {leads.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map(lead => ( */}
            {leads.map(lead => (
              <TableRow
                style={{ backgroundColor: "white" }}
                className={classes.tableRow}
                hover
                key={lead.id}
                // selected={selectedLeads.indexOf(lead.id) !== -1}
                onClick={() => history.push("/leads/" + lead.id)}
              >
                <TableCell >
                  #{lead.id}
                </TableCell>
                <TableCell colSpan="2">

                  <Grid container justify="space-between">
                    <Typography variant="h5" align="left" >
                      {lead.email}
                    </Typography>

                  </Grid>

                  <Grid container justify="space-between">
                    <Typography align="left">
                      {lead.name}
                    </Typography>

                  </Grid>

                  <Grid container justify="space-between">

                    <Typography align="left">
                      {lead.phone}
                    </Typography>

                  </Grid>

                </TableCell>

                <TableCell >
                  <ZnDepartmentValue value={lead.department}></ZnDepartmentValue>
                </TableCell>
                <TableCell >
                  <ZnUserValue value={lead.user}></ZnUserValue>
                </TableCell>

                <TableCell >
                  <Grid container justify="flex-end">
                    <Typography align="right">
                      Updated {moment(lead.updated_at).fromNow()}
                    </Typography>
                  </Grid>
                  <Grid container justify="flex-end">
                    <Typography align="left">&nbsp;</Typography>
                  </Grid>
                  <Grid container justify="flex-end">
                    <LeadStatus status={lead.status} />
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Hidden>
      <Hidden mdUp>

      <List className={classes.listRoot} >
          {leads.map(item => (
            <Fragment key={item.id}>
              
              <ListItem  >
                <ListItemAvatar>
                  <Avatar aria-label="recipe" className={classes.avatar}
                    alt={item.email}>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                classes={{root: classes.listItem}}
                
                  primary={item.name + " <" + item.email + ">"}
                  
                  secondary={(
                    <Fragment>
                      <ZnDepartmentValue value={item.department} />
                      &nbsp;-&nbsp;
                      <ZnUserValue value={item.user} />
                      <br/>
                      {"#" + item.id + " - " + moment(item.updated_at).fromNow()}
                    </Fragment>)}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="show"
                    onClick={() => history.push("/leads/" + item.id)}
                  >
                    <ArrowRightIcon ></ArrowRightIcon>
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider></Divider>
            </Fragment>
          ))}
        </List>

            

        {/* {leads.map((lead, key) => (

          <Card
            key={key}
            style={{ marginBottom: "10px" }}
            onClick={() => history.push("/leads/" + lead.id)} >
            <CardHeader

              action={
                <IconButton aria-label="settings">
                  <MoreVertIcon />
                </IconButton>
              }
              title={<Fragment>{lead.name}</Fragment>}
              subheader={
                <Fragment>
                  {lead.email}<br />
                  <ZnDepartmentValue value={lead.department} /> / <ZnUserValue value={lead.user} /><br />
                    #{lead.id}
                </Fragment>
              }
            />
          </Card>

        ))} */}

      </Hidden>
    </Fragment>
  );
};

LeadsTable.propTypes = {
  className: PropTypes.string,
};

export default LeadsTable;
