export const newsletterConstants = {

    
   
    
 
    GETALL_REQUEST: 'NEWSLETTER_GETALL_REQUEST',
    GETALL_SUCCESS: 'NEWSLETTER_GETALL_SUCCESS',
    GETALL_FAILURE: 'NEWSLETTER_GETALL_FAILURE',

    SYNCALL_REQUEST: 'NEWSLETTER_SYNCALL_REQUEST',
    SYNCALL_SUCCESS: 'NEWSLETTER_SYNCALL_SUCCESS',
    SYNCALL_FAILURE: 'NEWSLETTER_SYNCALL_FAILURE',
    
};
