import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { MailOutlined } from '@material-ui/icons';
import { MessagesToolbar } from '.';
import { useDispatch, useSelector } from 'react-redux';
import { messageActions } from '../../../../_actions';
import { Typography, AppBar, Button, Card, CardContent, Grid, TablePagination, Toolbar, Link, Chip } from '@material-ui/core';
import ItemList from 'common/ItemList';
import MessageAutoData from './MessageAutoData';
import clsx from 'clsx';
import MessageDetail from './MessageDetail';
import { leadConstants } from '_constants';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    backgroundColor: "white",
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  content: {
    marginTop: theme.spacing(0)
  },
  spacer: {
    flexGrow: 1
  },
  footerAppBar: {
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - 240px)'
    },
    top: 'auto',
    bottom: 0,
  },
}));



const MessageList = () => {
  const classes = useStyles();
  const messages = useSelector(state => state.messages);
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({
    source_form_id: '',
    statuses: [], 
    text: ''
  });


  const messageSearchText = useSelector(state => state.config.messageSearchText);
  const messageSearchStatuses = useSelector(state => state.config.messageSearchStatuses);
 


  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [page, setPage] = useState(0);
  
  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

 

  // load data
  useEffect(() => {
    dispatch(messageActions.getAll(
      filter.source_form_id, 
      filter.statuses, 
      filter.text, 
      '', 
      false,
      page +1, 
      rowsPerPage));
  }, [page, rowsPerPage, filter]);



  // item mapping

  const messageMapper = (message) => {

    
    let autoDataInfo = 'NO AUTODATA';
    if(message.auto_data) {
      const jsonData = message.auto_data.json_data ? 'AUTODATA JSON - ' : '';
      const regexData = message.auto_data.regex_data ? 'AUTODATA REGEX - ' : '';
      autoDataInfo = jsonData + regexData;
    } 

    const form = message.auto_data.source_form ? message.auto_data.source_form : null;
    
    const sourceName = message.auto_data.source ? message.auto_data.source.name : null;
    
    const statusMapper = (status) => {

      if (leadConstants.LEAD_STATUS_MESSAGES[status] == undefined) {
        return  (<Chip variant="default" size="small" style={{
          backgroundColor: "gray",
          color: '#ffffff'
        }} label="ND" />);
      }
      const sts = leadConstants.LEAD_STATUS_MESSAGES[status].process_status;
      const color = leadConstants.LEAD_STATUS_MESSAGES[status].process_color;
      
      return (<Chip variant="default" size="small" style={{
        backgroundColor: color,
        color: '#ffffff'
      }} label={sts} />);
    }
      
    return {
      id: message.id,
      // action : "/leads/" + message.leadId,
      primary: <Fragment>
        {statusMapper(message.lead.status)} #{message.leadId} {message.lead.source != '' && '[' + message.lead.source + ']'} <br/>
        {message.subject} 
        {form && (<Fragment><br/>{sourceName} (Form: #{form.id} {form.name}) <Link target='blank' href={form.url}>{form.url}</Link></Fragment>)}</Fragment>,
      secondary: autoDataInfo + message.senderEmail + ' ' + moment(message.updated_at).fromNow(),
      // secondary: <MessageAutoData auto_data={message.auto_data}/>,
    };
  }

  const [currentMessage, setCurrentMessage] = useState();

  const onItemClick = (itemId) => {
    setCurrentMessage(itemId);
  }

  const cancelClick = () => {
    setCurrentMessage(null);
  }


  

  const hadleFilterChange = (value) => {
    setFilter(value);
    setPage(0);
  }

  return (
    <div className={classes.root} 
    style={{
      // width: '100%',
      // border : '1px solid red', 
      // height:'100vh', 
      //  paddingTop: '0',
      // paddingBottom: '54px',
      // position: 'fixed',
      // top:0,
      // right:0,
      // height:"100%",
      
    }}
    > 
      <MessagesToolbar setFilter={hadleFilterChange} />
      
      <Grid  container justify="space-between">
        <Grid item xs={12} sm={currentMessage && 4}>
          <div
          style={{
            height: "75vh", 
           // width: "58vw", 
            overflowY: "scroll",
            // position: "fixed",
            right: 0,
            top: 130,
            // bottom: 100,
            }}
            >
          <ItemList icon={<MailOutlined fontSize="large" />} onClick={onItemClick} currentItemId={currentMessage} items={messages.items} itemMapper={messageMapper} actionPath="/messages/"/>
          </div>
        </Grid>
        {currentMessage && (<Grid item xs={12} sm={8} style={{
          // height: "75vh", 
          // width: "58vw", 
          // overflowY: "scroll",
          // // position: "fixed",
          // right: 0,
          // top: 130,
          // // bottom: 100,
          }}>
          <Card >
            <CardContent >
              
              <div className={classes.row}>
                

                <span className={classes.spacer} />
                <Button onClick={cancelClick}>CLOSE</Button>
              </div>
              <MessageDetail messageId={currentMessage}></MessageDetail>
              
              
            </CardContent>
          </Card>
        </Grid>)}
        
      </Grid>

      <AppBar position="fixed" color="" className={classes.footerAppBar}>
        <Toolbar disableGutters={true} style={{ minHeight: "auto", backgroundColor: "#f5f5f5", color: "black", display: 'flex', justifyContent: 'flex-end' }}>
          { (messages.items && messages.items.length > 0) && ( <TablePagination component="div"
            count={messages.total}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]} />)}
        </Toolbar>
      </AppBar> 
    </div>
  );
};

export default MessageList;



