import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ZnTextField from 'common/znFields/ZnTextField';
import ZnCountrySelect from 'common/znFields/ZnCountrySelect';
import { withSnackbar } from 'notistack';
import validate from 'validate.js';
import { ZnDepartmentSelect } from 'common/znFields/ZnDepartmentSelect';
import { ZnUserSelect } from 'common/znFields/ZnUserSelect';



const schema = {
  // name: {
  //   presence: { allowEmpty: false, message: 'is required' },
  //   length: {
  //     minimum: 2,
  //     maximum: 64
  //   }
  // },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  // phone: {
  //   presence: { allowEmpty: false, message: 'is required' },
  //   length: {
  //     maximum: 64
  //   }
  // },
  // jurisdiction: {
  //   presence: { allowEmpty: false, message: 'is required' },
  // },
  department: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  user: {
    presence: { allowEmpty: false, message: 'is required' },
  },


};

const LeadForm = props => {
  
  const { values, onSubmit, onFormValidation, requestSubmit, className, ...rest } = props;

  /**
   * form managerment
   */
  const [formState, setFormState] = useState({
    loaded: false,
    isValid: false,
    values: {
      name: '',
      email: '',
      phone: '',
      company: '',
      jurisdiction: '',
      department: '',
      user: '',
      other_info: '',
    },
    touched: {},
    errors: {}
  });

  /**
   * form validation
   */
  const formValidation = () => {
    const errors = validate(formState.values, schema);
    onFormValidation(errors ? false : true);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }

  const hasError = (field) => {
    return formState.errors[field] ? true : false;
  }

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      values: values
    }))
  }, [values]);

  useEffect(() => {
    if (requestSubmit === true) {
      submit();
    }
  }, [requestSubmit]);


  useEffect(() => {
    formValidation();
  }, [formState.values]);

  /**
   * 
   * form change 
   */
  const handleChange = (event) => {
    event.persist();
    handleFiedChange(event.target.name, event.target.type === 'checkbox'
      ? event.target.checked
      : event.target.value);
  };

  const jurisdictionHandleChange = (event, values) => {
    handleFiedChange('jurisdiction', values ? values.code : "");
  };

  const departmentHandleChange = (event, values) => {
    handleFiedChange('department', values ? values.code : "");
  };

  const userHandleChange = (event, values) => {
    handleFiedChange('user', values ? values.code : "");
  };


  const handleFiedChange = (fieldName, value) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [fieldName]: value,

      },
      touched: {
        ...formState.touched,
        [fieldName]: true
      }
    }));
  };

  /**
   * 
   * form submit
   */
  const handleSubmit = event => {
    if (event.type === 'keypress' && event.key !== 'Enter') {
      return;
    }
    submit();
  }

  const submit = () => {
    formValidation();
    onSubmit(formState.values, formState.isValid);
  }

  return (
    <Fragment>
      <Grid
        container
        spacing={1}
        wrap="wrap"
      >
        <Grid item xs={12} lg={6}>
          <ZnDepartmentSelect
            id="department"
            label="Department"
            onChange={departmentHandleChange}
            onKeyPress={handleSubmit}
            value={formState.values.department}
            error={hasError('department')}
            helperText={
              hasError('department') ? formState.errors.department[0] : null
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
        <ZnUserSelect
            id="user"
            label="Assigned to"
            onChange={userHandleChange}
            onKeyPress={handleSubmit}
            value={formState.values.user}
            error={hasError('user')}
            helperText={
              hasError('user') ? formState.errors.user[0] : null
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        wrap="wrap"
      >
        <Grid item xs={12} lg={6}>
          <ZnTextField
            onKeyPress={handleSubmit}
            name="email"
            label="Lead Email"
            onChange={handleChange}
            value={formState.values.email}
            error={hasError('email')}
            helperText={
              hasError('email') ? formState.errors.email[0] : null
            }
            margin="normal"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ZnTextField
            onKeyPress={handleSubmit}
            name="phone"
            label="Phone"
            onChange={handleChange}
            value={formState.values.phone}
            error={hasError('phone')}
            helperText={
              hasError('phone') ? formState.errors.phone[0] : null
            }
            margin="normal"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <ZnTextField
            onKeyPress={handleSubmit}
            name="name"
            label="Name"
            onChange={handleChange}
            value={formState.values.name}
            error={hasError('name')}
            helperText={
              hasError('name') ? formState.errors.name[0] : null
            }
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ZnTextField
            onKeyPress={handleSubmit}
            name="company"
            label="Company"
            onChange={handleChange}
            value={formState.values.company}
            error={hasError('company')}
            helperText={
              hasError('company') ? formState.errors.company[0] : null
            }
            margin="normal"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ZnCountrySelect
            id="jurisdiction"
            label="Jurisdiction"
            onChange={jurisdictionHandleChange}
            onKeyPress={handleSubmit}
            value={formState.values.jurisdiction}
            error={hasError('jurisdiction')}
            helperText={
              hasError('jurisdiction') ? formState.errors.jurisdiction[0] : null
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        wrap="wrap"
      >
        <Grid item xs={12} lg={12}>
          <ZnTextField
            name="other_info"
            label="Other info"
            onChange={handleChange}
            value={formState.values.other_info}
            error={hasError('other_info')}
            helperText={
              hasError('other_info') ? formState.errors.other_info[0] : null
            }
            margin="normal"
            variant="outlined"
            multiline
            rows="6"
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        wrap="wrap"
      >
        <Grid item xs={12} lg={12}>
          <ZnTextField
            name="amount"
            label="Elibra Amount"
            onChange={handleChange}
            value={formState.values.amount}
            error={hasError('amount')}
            helperText='Please insert the amount of the fee paid by client (including only the item "Onorari")'
            // helperText={
            //   hasError('amount') ? formState.errors.amount[0] : 'ppp'
            // }
            margin="normal"
            variant="outlined"
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

LeadForm.defaultProps = {
  onSubmit: function (values) { },
  onFormValidation: function (isValid) { },
}

LeadForm.propTypes = {
  onSubmit: PropTypes.func,
  onFormValidation: PropTypes.func,
};

export default withSnackbar(LeadForm);

