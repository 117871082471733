export const surveyConstants = {

    
   
    
 
    GETALL_REQUEST: 'SURVEY_GETALL_REQUEST',
    GETALL_SUCCESS: 'SURVEY_GETALL_SUCCESS',
    GETALL_FAILURE: 'SURVEY_GETALL_FAILURE',

    SYNCALL_REQUEST: 'SURVEY_SYNCALL_REQUEST',
    SYNCALL_SUCCESS: 'SURVEY_SYNCALL_SUCCESS',
    SYNCALL_FAILURE: 'SURVEY_SYNCALL_FAILURE',

    EXPORT_REQUEST: 'SURVEY_EXPORT_REQUEST',
    EXPORT_SUCCESS: 'SURVEY_EXPORT_SUCCESS',
    EXPORT_FAILURE: 'SURVEY_EXPORT_FAILURE',

    
};
