import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouteWithLayout = props => {
  const { 
    layout: Layout, 
    component: Component, 
    ...rest 
  } = props;

  return (
    <Route
      {...rest}
      
      render={matchProps => (
        props.protected === "true" && ! localStorage.getItem('user') ? (
          <Redirect
            to={{
              pathname: `/sign-in`
            }}
          />
        ): (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        ))}
    ></Route>
    
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
