import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import ZnTextField from 'common/znFields/ZnTextField';
import { useDispatch, useSelector } from 'react-redux';
import validate from 'validate.js';

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  }
};

const defaultFormSchema = {
  loaded: false,
  isValid: false,
  values: {
    name: '',
    phone: '',
    email: ''
  },
  touched: {},
  errors: {}
};

const Detail = props => {
  const { open, setOpen, item, actions, reducer } = props;

  const doingAction = reducer.doingAction;
  const successAction = reducer.successAction;
  const actionErrorMsg = reducer.actionErrorMsg;

  const dispatch = useDispatch();

  const handleChange = event => {
    event.persist();
    handleFieldChange(
      event.target.name,
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    );
  };

  const handleFieldChange = (fieldName, value) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [fieldName]: value
      },
      touched: {
        ...formState.touched,
        [fieldName]: true
      }
    }));
  };

  const handleSubmit = event => {
    if (!formState.isValid) return;

    if (event.type === 'keypress' && event.key !== 'Enter') {
      return;
    }
    event.preventDefault();
    setFormState(formState => ({
      ...formState,
      touched: schema
    }));

    dispatch(actions.save(formState.values));
  };

  const handleDelete = event => {
    if (formState.values.id) {
      dispatch(actions._delete(formState.values.id));
    }
    setOpen(false);
  };

  useEffect(() => {
    if (successAction === true) handleClose();
  }, [successAction]);

  const handleClose = () => {
    if (!doingAction) {
      setOpen(false);
      setFormState(defaultFormSchema);
    }
  };
  const [formState, setFormState] = useState(defaultFormSchema);

  const formValidation = () => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  };

  useEffect(() => {
    formValidation();
  }, [formState.values]);

  useEffect(() => {
    if (item) {
      setFormState({
        ...formState,
        values: item
      });
    } else {
      setFormState(defaultFormSchema);
    }
  }, [item, open]);

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      isValid: actionErrorMsg ? false : true,
      errors: actionErrorMsg ? actionErrorMsg.errors : {}
    }));
  }, [actionErrorMsg]);

  const hasError = field => {
    if (!formState.errors) return false;
    if (!formState.errors[field]) return false;
    return formState.errors[field] ? true : false;
  };

  const getError = field => {
    if (!formState.errors[field]) return null;

    return formState.errors[field] && formState.errors[field][0];
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {item ? 'Company detail' : 'New company'}
        </DialogTitle>
        <DialogContent>
          <ZnTextField
            onKeyPress={handleSubmit}
            name="name"
            label="Name"
            onChange={handleChange}
            value={formState.values.name || ''}
            error={hasError('name')}
            helperText={getError('name')}
            margin="normal"
          />

          <ZnTextField
            onKeyPress={handleSubmit}
            name="phone"
            label="Phone"
            onChange={handleChange}
            value={formState.values.phone || ''}
            error={hasError('phone')}
            helperText={getError('phone')}
            margin="normal"
            variant="outlined"
          />
          <ZnTextField
            onKeyPress={handleSubmit}
            name="email"
            label="Email"
            onChange={handleChange}
            value={formState.values.email || ''}
            error={hasError('email')}
            helperText={getError('email')}
            margin="normal"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Save</Button>
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Detail.defaultProps = {
  open: false,
  item: {}
};
export default Detail;
