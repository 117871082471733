import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { leadActions, messageActions } from '_actions';
import { CardContent, Card } from '@material-ui/core';
import { useParams } from 'react-router';
import MessageBox from './MessageBox';
import LeadBox from './LeadBox';
import theme from 'theme';
import { leadConstants } from '_constants';
import MessageBoxForm from './MessageBoxForm';
import clsx from 'clsx';
import LeadCard from './LeadCard';
import LeadFormToolbar from './LeadFormToolbar';


const useStyles = makeStyles(() => ({
  root: {
    padding: theme.spacing(0)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  leftColumn: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
}));

const LeadDetail = props => {

  const { className, ...rest } = props;

  const { id } = useParams();

  const classes = useStyles();

  const dispatch = useDispatch();

  const lead = useSelector(state => state.leads.item)

  const showLead = function () {
    return (lead 
      && lead.status !== leadConstants.LEAD_STATUS_NEW_MESSAGE 
      && lead.status !== leadConstants.LEAD_STATUS_SPAM
      );
  }

  useEffect(() => {
    dispatch(leadActions.getOne(id), null);
  }, []);

  useEffect(() => {
    dispatch(messageActions.getAllByLeadId(id), null);
  }, []);

  

  const [showMailForm, setShowMailForm] = useState(false);
  
  // handle toolbar actions
  const onCancel = () => {
    setShowMailForm(false);
  }

  const onSkip = () => {
    dispatch(leadActions.doAction(lead.id, leadConstants.LEAD_ACTION_SET_AS_FIRST_CONTACT));
    setShowMailForm(false);
  }
  
  const onSend = () => {
    dispatch(leadActions.sendMailRequest());
  }


  return (
    <div className={classes.root}>
      

      { showMailForm===true && (
        <Fragment>
          <LeadFormToolbar onCancel={onCancel} lead={lead} onSend={onSend} onSkip={onSkip}/>
          <Card {...rest} mt={2} className={clsx(classes.root, className)}>
            {/* <CardHeader title="Send First Reply" /> */}
            <CardContent>
              <LeadCard values={lead}></LeadCard> 
                <MessageBoxForm
                  // submitForm={submit}
                  // afterSend={afterSend}
                  lead={lead}
                  setOpen={setShowMailForm} 
                  open={showMailForm} 
                  message={{leadId : lead && lead.id}} />
            </CardContent>
          </Card>
          <MessageBox lead={lead}  showMailForm={showMailForm} setShowMailForm={setShowMailForm}/>
        </Fragment>
      )}

      
      { (showMailForm===false) && (
        <Fragment>
            <LeadBox showLead={showLead} lead={lead}  setShowMailForm={setShowMailForm}/>
        </Fragment>

      )}
    </div>
  );
};
export default LeadDetail;



