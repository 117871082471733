import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { ZnDepartmentValue } from 'common/znFields/ZnDepartmentSelect';
import { ZnUserValue } from 'common/znFields/ZnUserSelect';



const LeadCard = props => {

  const { values } = props;

  return (
    <Grid
    container
    spacing={1}
    wrap="wrap"
  >
    <Grid item align="left"  >
      
      <Typography variant="h4" >{values.name}</Typography>
      <Typography >{values.email || ''}</Typography>
      <Typography >{values.phone || ''}</Typography>
      <Typography>{values.company || ''}</Typography>
      <Typography>{values.jurisdiction || ''}</Typography>
      <Typography>{values.other_info || ''}</Typography>
      <Typography ><ZnDepartmentValue value={values.department} />&nbsp;<ZnUserValue value={values.user} /></Typography>
    </Grid>

  </Grid>

  );
};

LeadCard.defaultProps = {
  values: {},
}

LeadCard.propTypes = {
  
};

export default LeadCard;

