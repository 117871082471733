import { messageModelConstants } from '../_constants';
import { messageModelService } from '../_services';
import { alertActions } from '.';
import { history } from '../helpers';

export const messageModelActions = {
    save,
    getAll,
    getOne,
    _delete,

};


function _delete(id) {
    
    return dispatch => {
        dispatch(request());

        messageModelService._delete(id)
            .then(
                messageModels => dispatch(getAll()),
                error => dispatch(failure(error))
            );
    };

    function request() { 
        return { type: messageModelConstants.DELETE_REQUEST } 
    }
    function success(user) { 
        return { type: messageModelConstants.DELETE_SUCCESS, user } 
    }
    function failure(error) { 
        return { type: messageModelConstants.DELETE_FAILURE, id, error } 
    }
}

function save(messageModel) {

    return dispatch => {
        dispatch(request());
        messageModelService.doAction(messageModel.id, messageModelConstants.ACTION_SAVE, messageModel)
            .then(
                result => {
                    dispatch(alertActions.success('Model Saved'));
                    dispatch(success(result.data));
                },
                error => {
                    dispatch(failure(error));
                }
            );
       
            
    };

    function request() {
        return { type: messageModelConstants.KICK_REQUEST };
    }
    function success(messageModel) {
        return { type: messageModelConstants.KICK_SUCCESS, messageModel };
    }
    function failure(error) {
        return { type: messageModelConstants.KICK_FAILURE, error };
    }
}

function getAll(filter, page = '', per_page = '') {
    return (dispatch, getState) => {

        const {departmentId} = getState().config;

        dispatch(request());

        messageModelService.getAll([departmentId], filter?.text ?? '', filter?.withAttachments ?? '', page, per_page)
            .then(
                messageModels => {
                    dispatch(success(messageModels.data, messageModels.meta));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { 
        return { type: messageModelConstants.GETALL_REQUEST } 
    }
    function success(messageModels, meta) { 
        return { type: messageModelConstants.GETALL_SUCCESS, messageModels, meta } 
    }
    function failure(error) { 
        return { type: messageModelConstants.GETALL_FAILURE, error } 
    }
}

function getOne(id) {

    
    return dispatch => {
        dispatch(request());

        messageModelService.getById(id)
            .then(
                messageModel => dispatch(success(messageModel.data)),
                error => dispatch(failure(error))
            );
    };

    function request() { 
        return { type: messageModelConstants.GETONE_REQUEST } 
    }
    function success(messageModel) { 
        return { type: messageModelConstants.GETONE_SUCCESS, messageModel } 
    }
    function failure(error) { 
        return { type: messageModelConstants.GETONE_FAILURE, id, error } 
    }
}

