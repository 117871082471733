import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button, Chip, Paper } from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";


const styles = {
  root: {
    background: "white"
  },
  input: {
    background: "white",
    "&:hover": {
      background: "white"
    },
    "&:visited": {
      background: "white"
    },
    "&:focus": {
      background: "white"
    },
    "&:blur": {
      background: "red"
    },
    "&:active": {
      background: "white"
    }
  }
};

function ZnAttachmentField(props) {

  const { classes, fileName, onAttach, onAttachDelete, onAttachClick} = props;


  const [formState, setFormState] = useState({
    loaded: false,
    isValid: false,
    values: {
      file: "", 
      fileName: "",
    },
    errors: {}
  });

  const handleFieldChange = (fieldName, value) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [fieldName]: value,

      },
    }));
  };

  useEffect(() => {
    if (fileName) {
      setFormState({
        ...formState,
        values: {
          fileName
        },
      });
    } else {
      setFormState({
        loaded: false,
        isValid: false,
        values: {
          
          file: "", 
          fileName: "",

        },
        touched: {},
        errors: {}
      });
    }
  }, [fileName]);

  const hasError = (field) => {
    return (formState.errors && formState.errors[field]) ? true : false;
  }

  const getError = (field) => {
    return (formState.errors && formState.errors[field]) && formState.errors[field][0];
  }


  const handleClick = event => {
    event.target.value = "";

  }

  const handleAttach = event => {
    handleFieldChange('file', event.target.files[0] && event.target.files[0]);
    handleFieldChange('fileName', event.target.files[0] && event.target.files[0].name);
    onAttach(event.target.files[0] && event.target.files[0], event.target.files[0] && event.target.files[0].name);
  }

  const handleAttachDelete = event => {
    handleFieldChange('file', ``);
    handleFieldChange('fileName', ``);
    onAttachDelete();
  }

  const handleAttachClick = event => {

    if (onAttachClick) {
      onAttachClick(formState.values.fileName);
    }
  }

  return (
    <Paper elevation={1} style={hasError('file') ?
      { marginBottom: "10px", padding: "10px", border: "1px solid red" } :
      { marginBottom: "10px", padding: "10px" }}>
      {(formState.values.fileName && formState.values.fileName !== ``) ? (
        <Chip
          label={formState.values.fileName }
          onDelete={() => { handleAttachDelete() }}
          onClick={() => { handleAttachClick() }}
        />
      ) : (
          <Button color="primary"
            component="label"
          >Add File<input
              onChange={handleAttach}
              onClick={handleClick}
              type="file"
              style={{ display: "none" }}
            />
          </Button>
        )}
    </Paper>



  );
}

ZnAttachmentField.defaultProps = {
  name: "",
  label: "",
  value: "",
  readOnly: false,
}

ZnAttachmentField.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ZnAttachmentField);


