import { sourceConstants } from '../_constants';

const initialStatus =  {
  successAction: false,
  items : [],
  total: 0,
  item : null,
  loading : false,
  error : null,

};

export function source(state = initialStatus, action) {

  
  switch (action.type) {
    case sourceConstants.KICK_REQUEST:
      return {
        ...state,
        successAction: false,
        doingAction: true,
        actionError: false,
        actionErrorMsg: null,

      };

    case sourceConstants.KICK_SUCCESS:
      const key = state.items.findIndex(item => item.id === action.source.id);
      let ii = state.items;
      
      if (key in ii) {
        ii.splice(key, 1, action.source);
      } else {
        ii.push(action.source);
      }

      return {
        ...state,
        successAction: true,
        doingAction: false,
        actionError: false,
        actionErrorMsg: null,
        items: ii,
      };
      break;

    case sourceConstants.KICK_FAILURE:
        return {
          ...state,
          successAction: false,
          doingAction: false,
          actionError: true,
          actionErrorMsg: action.error,


        };



    case sourceConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sourceConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.sources,
        total: action.meta.total,

        loading: false
      };
    case sourceConstants.GETALL_FAILURE:
      return { 
        ...state,
        error: action.error,
        loading: false
      };
    case sourceConstants.GETONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sourceConstants.GETONE_SUCCESS:
      return {
        ...state,
        item: action.source,
        loading: false
      };
    case sourceConstants.GETONE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case sourceConstants.DELETE_REQUEST:
      // add 'deleting:true' property to source being deleted
      return {
        ...state,
        items: state.items.map(source =>
          source.id === action.id
            ? { ...source, deleting: true }
            : source
        )
      };
    case sourceConstants.DELETE_SUCCESS:
      // remove deleted source from state
      return {
        ...state,
        items: state.items.filter(source => source.id !== action.id)
      };
    case sourceConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to source 
      return {
        ...state,
        items: state.items.map(source => {
          if (source.id === action.id) {
            // make copy of source without 'deleting:true' property
            const { deleting, ...sourceCopy } = source;
            // return copy of source with 'deleteError:[error]' property
            return { ...sourceCopy, deleteError: action.error };
          }

          return source;
        })
      };
    default:
      return state;
  }
}