import React, { useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { UsersToolbar, UsersTable } from '.';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../../_actions';
import UserDetail from './UserDetail';
import ItemList from 'common/ItemList';
import { TablePagination } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    backgroundColor: "white",
  },
  content: {
    marginTop: theme.spacing(0)
  }
}));

const UserList = () => {
  const classes = useStyles();
  const users = useSelector(state => state.users)
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({
    text :  '',
  });


  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [page, setPage] = useState(0);
  
  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  // load data

  useEffect(() => {
    dispatch(userActions.getAll(filter.text, page +1, rowsPerPage));
  }, [page, rowsPerPage, filter]);

 

  // detail edit modal

  const [open, setOpen] = React.useState(false);
  const [userId, setUserId] = React.useState(null);
  const [user, setUser] = React.useState(null);

  useEffect(() => {
    const key = users.items.findIndex(item => item.id === userId);

    if (key in users.items) {
      setUser(users.items[key]);
    } else {
      setUser(null);
    }
    
  }, [userId, users]);

  
  const handleUserClick = (newId) => {
    setUserId(newId);
    setOpen(true);
  }



  // item mapping
 
  const userMapper = (user) => {
    return {
      id: user.id,
      action : null,
      primary: user.name + ' (' + user.sign_role + ')',
      secondary: user.email,
      
    };
  }

  const hadleFilterChange = (value) => {
    setFilter(value);
    setPage(0);
  }

 
  // renderer

  return (
    <div className={classes.root}>
      <UsersToolbar  setFilter={hadleFilterChange} handleUserClick={handleUserClick}/>
      
      <div className={classes.content}>
        <ItemList items={users.items} itemMapper={userMapper} onClick={handleUserClick}/>
        <UserDetail setOpen={setOpen} open={open} user={user}></UserDetail>
      </div>

      { (users.items && users.items.length > 0) && (
      <TablePagination
          component="div"
          count={users.total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]}
        />
      )}
    </div>
  );
};

export default UserList;



