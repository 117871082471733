import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { surveyActions } from '_actions';
import { Badge,  Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';
import { configActions } from '_actions/config.actions';
import SurveyIcon from '@material-ui/icons/QuestionAnswer';
import Toolbar from './Toolbar';




const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    backgroundColor: "white",

  },
  content: {
    marginTop: theme.spacing(0)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    color: '#fff',
  },
}));


const SurveyList = () => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const surveys = useSelector(state => state.surveys);
  const surveyReload = useSelector(state => state.config.surveyReload);
  const page = useSelector(state => state.config.surveySearchPage);
  const rowsPerPage = useSelector(state => state.config.surveySearchRowsPerPage);

  const handlePageChange = (event, page) => {
    dispatch(configActions.setSurveyPage(page));
  };

  const handleRowsPerPageChange = event => {
    dispatch(configActions.setSurveyRowsPerPage(event.target.value));
  };


  // search box
  useEffect(() => {
    dispatch(configActions.setActiveSearchBox('survey'));
  }, []);
  useEffect(() => {
    return () => {
      dispatch(configActions.setActiveSearchBox(null));
    };
  }, []);

  useEffect(() => {
    dispatch(surveyActions.getAll(page + 1, rowsPerPage,));
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (!surveyReload) return;
    dispatch(surveyActions.getAll(page + 1, rowsPerPage,));
  }, [surveyReload]);



  return (
    <div className={classes.root}>
      <Toolbar></Toolbar>
      <div className={classes.content}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Privacy</TableCell>
                <TableCell align="right">Account</TableCell>
                <TableCell align="right">Competence</TableCell>
                <TableCell align="right">Response speed</TableCell>
                <TableCell align="right">Kindness</TableCell>
                <TableCell align="right">Value Money</TableCell>
                <TableCell align="right">Willingnes</TableCell>
                <TableCell align="right">Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {surveys.items.map((row) => (
                <TableRow
                  key={row.token}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center">
                    <SurveyIcon></SurveyIcon>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.lead.name + " <" + row.lead.email + ">"} <br />

                    <Link href={'https://www.lexia.it/survey/?token=' + row.token} target="_blank">View suvey form</Link>
                  </TableCell>
                  <TableCell align="center">
                    {row.submission ? <Badge color='primary' badgeContent="Submitted" />
                      : <Badge color='green' badgeContent="Pending" />}
                  </TableCell>

                  <TableCell align="right">{row.submission ? row.submission.extras.privacy : '-'}</TableCell>
                  <TableCell align="right">{row.submission ? row.submission.extras.account_name : '-'}</TableCell>
                  <TableCell align="right">{row.submission ? row.submission.extras.competence : '-'}</TableCell>
                  <TableCell align="right">{row.submission ? row.submission.extras.fast_response_speed : '-'}</TableCell>
                  <TableCell align="right">{row.submission ? row.submission.extras.kindness : '-'}</TableCell>
                  <TableCell align="right">{row.submission ? row.submission.extras.value_for_money : '-'}</TableCell>
                  <TableCell align="right">{row.submission ? row.submission.extras.willingnes : '-'}</TableCell>
                  <TableCell align="right">{row.submission ? row.submission.extras.comment : '-'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      

      {(surveys.items && surveys.items.length > 0) && (
        <TablePagination
          component="div"
          count={surveys.total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]}
        />
      )}
    </div>
  );
};

export default SurveyList;



