import { attachmentConstants } from '../_constants';

const initialStatus = {
  successAction: false,
  items: [],
  messageItems: [],
  loading: false,
  error: null,

};

export function attachments(state = initialStatus, action) {


  switch (action.type) {


    case attachmentConstants.KICK_REQUEST:
      return {
        ...state,
        successAction: false,
        doingAction: true,
        actionError: false,
        actionErrorMsg: null,
        loading: false,
      };

    case attachmentConstants.KICK_SUCCESS:
      const key = state.items.findIndex(item => item.id === action.attachment.id);
      let ii = state.items;

      if (key in ii) {
        ii.splice(key, 1, action.attachment);
      } else {
        ii.push(action.attachment);
      }

      return {
        ...state,
        successAction: true,
        doingAction: false,
        actionError: false,
        actionErrorMsg: null,
        items: ii,
      };
      break;

    case attachmentConstants.KICK_FAILURE:
      return {
        ...state,
        successAction: false,
        doingAction: false,
        actionError: true,
        actionErrorMsg: action.error,
        loading: false,
      };




    case attachmentConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case attachmentConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.attachments,
        loading: false
      };
    case attachmentConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };


      case attachmentConstants.GETALL_BY_MESSAGE_REQUEST:
        return {
          ...state,
          loading: true
        };
      case attachmentConstants.GETALL_BY_MESSAGE_SUCCESS:
        return {
          ...state,
          messageItems: action.attachments,
          loading: false
        };
      case attachmentConstants.GETALL_BY_MESSAGE_FAILURE:
        return {
          ...state,
          error: action.error,
          loading: false
        };


    case attachmentConstants.GETONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case attachmentConstants.GETONE_SUCCESS:
      return {
        ...state,
        item: action.attachment,
        loading: false
      };
    case attachmentConstants.GETONE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case attachmentConstants.ATTACH_REQUEST:
      return {
        ...state,
        loading: true
      };
    case attachmentConstants.ATTACH_SUCCESS:

      return {
        ...state,
      //  messageItems: action.attachments,
        [action.attachments.modelName]: action.attachments.attachment,
        
        loading: false
      };
    case attachmentConstants.ATTACH_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case attachmentConstants.REMOVE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case attachmentConstants.REMOVE_SUCCESS:
      return {
        ...state,
        messageItems: action.attachments,
        loading: false
      };
    case attachmentConstants.REMOVE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };



    default:
      return state;
  }
}