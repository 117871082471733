import React from 'react';
import {  MessagesList } from './components';

const Message = () => {
  return (
    <MessagesList />
  );
};

export default Message;
