import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Button, FormControlLabel, Switch, Toolbar } from '@material-ui/core';
import { SearchInput } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const MessageModelsToolbar = props => {
  const { className, setFilter, handleMessageModelClick, ...rest } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    text: "",
  });

  const [typing, setTyping] = useState(false);



  useEffect(()=>{

    setTyping(setTimeout(function () {
      setFilter(formState);
    }, 400));

  }, [formState] )

  const handleTextChange = event => {
    if (typing !== false) {
      clearTimeout(typing);
      setTyping(false);
    }
    const st = {
      ...formState,
      text: event.target.value
    }
    setFormState(st);
    // setTyping(setTimeout(function () {
    //   setFilter(st);
    // }, 400));
  };

  const handleWithAttachmentsChange = event => {
    const st = {
      ...formState,
      withAttachments: event.target.checked
    }
    setFormState(st);
  }

  const handleAddMessageModelClick = () => {
    handleMessageModelClick(null);
  }

  return (
    <AppBar color="default" position="sticky">
      <Toolbar>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search"
          onChange={handleTextChange}
          name="search"
        />
        <FormControlLabel
          control={<Switch onChange={handleWithAttachmentsChange} name="checkedA" />}
          label="With Attachments"
        />

        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddMessageModelClick}
        >
          New
        </Button>
      </Toolbar>
    </AppBar>
  );
};

MessageModelsToolbar.propTypes = {
  className: PropTypes.string
};

export default MessageModelsToolbar;
