import React from 'react';
import {  useSelector } from 'react-redux';
import { leadConstants } from '_constants';
import SummaryBox from '../SummaryBox';
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined';


const LeadsOpened = props => {
  
  const total = useSelector(state => state.leads.overview.processing);

  return (
    <SummaryBox 
      icon={<CachedOutlinedIcon fontSize="large" />}
      title="Processing"
      subtitle={leadConstants.LEAD_STATUS_MESSAGES.LEAD_STATUS_FIRST_CONTACT.process_action}
      total={total}
      color={leadConstants.LEAD_STATUS_COLORS.PROCESSING}
      statuses={[
        leadConstants.LEAD_STATUS_FIRST_CONTACT,
      ]}
    />
  );
};

export default LeadsOpened;

