import { messageModelConstants } from '../_constants';

const initialStatus = {
  successAction: false,
  doingAction: false,
  actionError: false,
  actionErrorMsg: null,
  items: [],
  total: 0,
  item: null,
  loading: false,
  error: null,

};

export function messageModels(state = initialStatus, action) {


  switch (action.type) {
    case messageModelConstants.KICK_REQUEST:
      return {
        ...state,
        successAction: false,
        doingAction: true,
        actionError: false,
        actionErrorMsg: null,
        loading: false,
      };

    case messageModelConstants.KICK_SUCCESS:
      const key = state.items.findIndex(item => item.id === action.messageModel.id);
      let ii = state.items;

      if (key in ii) {
        ii.splice(key, 1, action.messageModel);
      } else {
        ii.push(action.messageModel);
      }

      return {
        ...state,
        successAction: true,
        doingAction: false,
        actionError: false,
        actionErrorMsg: null,
        items: ii,
      };
      break;

    case messageModelConstants.KICK_FAILURE:
      return {
        ...state,
        successAction: false,
        doingAction: false,
        actionError: true,
        actionErrorMsg: action.error,
        loading: false,
      };

    case messageModelConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case messageModelConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.messageModels,
        total: action.meta.total,
        loading: false
      };
    case messageModelConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case messageModelConstants.GETONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case messageModelConstants.GETONE_SUCCESS:
      return {
        ...state,
        item: action.messageModel,
        loading: false
      };
    case messageModelConstants.GETONE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    default:
      return state;
  }
}