
import React from 'react';
import { useSelector } from 'react-redux';
import { List} from './components';

import { SourceIcon } from 'icons';
import { sourceActions as actions } from '_actions';


const listMapper = (item) => {
  return {
    id: item.id,
    action : null,
    primary: item.name ,
    secondary: null,
    
  };
}

const icon = <SourceIcon />

const View = () => {

  const reducer = useSelector(state => state.source);

  return <List 
    mapper={listMapper} 
    icon={icon}
    actions={actions}
    reducer={reducer}
    />;
};

export default View;

