import { authHeader } from '../helpers';
import handleResponse from './handleResponse';

export const newsletterService = {
    getAll,
    syncAll
};

function getAll(page = '', per_page = '') {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/newsletter?` + `&page=${page}&per_page=${per_page}`, requestOptions).then(handleResponse);
}

function syncAll(ids) {
    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(ids)
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/newsletter`, requestOptions).then(handleResponse);
}




