import React, { useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { AttachmentsToolbar, AttachmentsTable } from '.';
import { useDispatch, useSelector } from 'react-redux';
import { attachmentActions } from '../../../../_actions';
import AttachmentDetail from './AttachmentDetail';
import { isConstructorDeclaration } from 'typescript';
import ZnDraftArea from 'common/znFields/ZnDraftArea';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const AttachmentList = () => {
  const classes = useStyles();
  const attachments = useSelector(state => state.attachments)
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({
    text :  "",
    statuses : []
  });

  useEffect(() => {
    dispatch(attachmentActions.getAll());
  }, []);

  useEffect(() => {
    getFiltered();
  }, []);

  const [open, setOpen] = React.useState(false);
  const [attachmentId, setAttachmentId] = React.useState(null);
  const [attachment, setAttachment] = React.useState(null);

  useEffect(() => {
    const key = attachments.items.findIndex(item => item.id === attachmentId);

    if (key in attachments.items) {
      setAttachment(attachments.items[key]);
    } else {
      setAttachment(null);
    }
    
  }, [attachmentId, attachments]);

  
  const handleAttachmentClick = (newId) => {
    setAttachmentId(newId);
    setOpen(true);
  }

  const handleAttachmentClickDelete = (id) => {
    dispatch(attachmentActions._delete(id));
  }

  const getFiltered = () => {
    
    return attachments.items.filter(
      (attachment) => {
        const text = filter.text.toLowerCase();
        
        return (
          attachment.name && attachment.name.toLowerCase().includes(text) 
          ||
          attachment.fileName && attachment.fileName.toLowerCase().includes(text) 
        );
      }
    );
    
  }

 

  return (
    <div className={classes.root}>
      <AttachmentsToolbar  setFilter={setFilter} handleAttachmentClick={handleAttachmentClick}/>
      <div className={classes.content}>
        <AttachmentsTable handleAttachmentClick={handleAttachmentClick} 
        handleAttachmentClickDelete={handleAttachmentClickDelete}
        attachments={getFiltered()} />
        <AttachmentDetail setOpen={setOpen} open={open} attachment={attachment}></AttachmentDetail>
      </div>
      {/* <ZnDraftArea ></ZnDraftArea> */}
    </div>
  );
};

export default AttachmentList;



