import { leadConstants } from '../_constants';
import { leadService } from '../_services';
import { alertActions } from './alert.actions';

export const leadActions = {
    addDocument,
    fetchMail,
    getAll,
    getAllProcessing,
    getAllOK,
    getAllButMessage,
    getOne,
    update,
    doAction,
    sendMailRequest,
    sendMessage,
    getOverview,
};


function addDocument(id, data = []) {

    return dispatch => {

        dispatch(request());
        leadService.doAction(id, leadConstants.LEAD_ACTION_ADD_DOCUMENT, data)
            .then(
                leads => {
                    dispatch(alertActions.success('Document Added'));
                    dispatch(success(leads.data));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return { type: leadConstants.GETONE_REQUEST };
    }
    function success(lead) {
        return { type: leadConstants.GETONE_SUCCESS, lead };
    }
    function failure(error) {
        return { type: leadConstants.GETONE_FAILURE, id, error };
    }
}


function fetchMail() {
    return getAll(1, 50, true);
}

function sendMailRequest() {
    return { type: leadConstants.SEND_MAIL_REQUEST};
}

function sendMessage(id, data = [], test = false) {

    return dispatch => {

        data['test'] = test;

        dispatch(request());
        leadService.doAction(id, leadConstants.LEAD_ACTION_SEND_MESSAGE, data, test)
            .then(
                leads => {
                    dispatch(alertActions.success('Email sent'));
                    dispatch(success(leads.data));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return { type: leadConstants.SEND_MESSAGE_REQUEST };
    }
    function success(lead) {
        return { type: leadConstants.SEND_MESSAGE_SUCCESS, lead };
    }
    function failure(error) {
        return { type: leadConstants.SEND_MESSAGE_FAILURE, id, error };
    }
}

function doAction(id, action, data = []) {

    return dispatch => {

        dispatch(request());
        leadService.doAction(id, action, data)
            .then(
                leads => {
                    dispatch(alertActions.success('Lead Status Updated'));
                    dispatch(success(leads.data));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return { type: leadConstants.GETONE_REQUEST };
    }
    function success(lead) {
        return { type: leadConstants.GETONE_SUCCESS, lead };
    }
    function failure(error) {
        return { type: leadConstants.GETONE_FAILURE, id, error };
    }
}

function getAllButMessage( status= [], text = '',  page = '', per_page = ''){
    return getAll(status, text,  page , per_page );
}

function getAllProcessing(){
    return getAll([
        leadConstants.LEAD_STATUS_CHECKED,
        leadConstants.LEAD_STATUS_FIRST_CONTACT,
    ]);
}

function getAllOK(){
    return getAll([
        leadConstants.LEAD_STATUS_OK,
    ]);
}

function getAll( page = '', per_page = '', fetchMail = false) {

    
    return (dispatch, getState) => {
        const {
            departmentId, 
            userId, 
            leadSearchStatuses, 
            leadSearchText,
            leadSearchEmail,
            leadSearchPhone,
            leadSearchSubject,
            leadSearchNotes,
            leadSearchDatesFrom, 
            leadSearchDatesTo 
        } = getState().config;

        
        dispatch(request());

        leadService.getAll(
            [departmentId], 
            [userId], 
            leadSearchStatuses, 
            leadSearchText,
            leadSearchEmail,
            leadSearchPhone,
            leadSearchSubject,
            leadSearchNotes,
            leadSearchDatesFrom, 
            leadSearchDatesTo,  
            page, 
            per_page, 
            fetchMail
            ).then(
                leads => {
                    dispatch(success(leads.data, leads.meta));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return { type: leadConstants.GETALL_REQUEST }
    }
    function success(leads, meta) {
        return { type: leadConstants.GETALL_SUCCESS, leads, meta }
    }
    function failure(error) {
        return { type: leadConstants.GETALL_FAILURE, error }
    }
}

function getOne(id) {

    return dispatch => {
        dispatch(request());

        leadService.getById(id)
            .then(
                leads => {
                    dispatch(alertActions.success('Lead loaded'));

                    dispatch(success(leads.data));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return { type: leadConstants.GETONE_REQUEST };
    }
    function success(lead) {
        return { type: leadConstants.GETONE_SUCCESS, lead };
    }
    function failure(error) {
        return { type: leadConstants.GETONE_FAILURE, id, error };
    }
}

function update(lead) {
    return dispatch => {
        dispatch(request());

        leadService.update(lead)
            .then(
                lead => {
                    dispatch(alertActions.success('Lead updated'));
                    dispatch(success(lead.data));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error));
                }
            );
    };

    function request() { 
        return { type: leadConstants.UPDATE_REQUEST };
    }
    function success(lead) { 
        return { type: leadConstants.UPDATE_SUCCESS, lead };
    }
    function failure(error) { 
        return { type: leadConstants.UPDATE_FAILURE, error };
    }
}

function getOverview() {

    
    return (dispatch, getState) => {

        //const {departmentId, userId} = getState().config;
        const {
            departmentId, 
            userId, 
            leadSearchStatuses, 
            leadSearchText,
            leadSearchEmail,
            leadSearchPhone,
            leadSearchSubject,
            leadSearchNotes,
            leadSearchDatesFrom, 
            leadSearchDatesTo 
        } = getState().config;

        dispatch(request());

        leadService.getOverview(
            [departmentId], 
            [userId], 
            leadSearchStatuses, 
            leadSearchText,
            leadSearchEmail,
            leadSearchPhone,
            leadSearchSubject,
            leadSearchNotes,
            leadSearchDatesFrom, 
            leadSearchDatesTo,
        )
            .then(
                overview => {
                    dispatch(success(overview));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return { type: leadConstants.GETOVERVIEW_REQUEST }
    }
    function success(overview) {
        return { type: leadConstants.GETOVERVIEW_SUCCESS, overview }
    }
    function failure(error) {
        return { type: leadConstants.GETOVERVIEW_FAILURE, error }
    }
}