import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Checkbox, FormControlLabel, Hidden, AppBar, Toolbar as MuiToolbar, Button } from '@material-ui/core';
import { SearchInput } from 'components';
import { ZnSourceFormSelect } from 'common/znFields/ZnSourceSelect';

import { leadConstants } from '_constants';
import { messageService } from '_services';


const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  fabProgress: {
    position: 'absolute',
    top: -4,
    left: -4,
    zIndex: 1,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

const MessagesToolbar = props => {

  const { className, setFilter, ...rest } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    values: {
      text: "",
      statuses: [],
      source_form_id: null
    }

  });

  useEffect(() => {
    setFilter(formState.values);
  }, [formState]);


  const handleChange = (event) => {
    event.persist();
    const checked = event.target.checked;
    const value = event.target.value;

    let sts = formState.values.statuses;
    if (event.target.type === 'checkbox') {
      
      if (checked) {
        sts.push(value);
      } else {
        sts.splice(sts.indexOf(value), 1);
      }
    } else {
      sts = value;
    }
    handleFieldChange(event.target.name, sts);
  };

  const handleTextChange = event => {
    handleFieldChange('text', event.target.value);
  };

  const selectHandleChange = (value) => {
    handleFieldChange('source_form_id', value);
  };

  const handleFieldChange = (fieldName, value) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [fieldName]: value,
      },
      touched: {
        ...formState.touched,
        [fieldName]: true
      }
    }));
  };



  const exportExcel = () =>{
    messageService.exportExcel();
  }


  return (
    <AppBar color="default" position="sticky">
      <MuiToolbar>

        <SearchInput
          className={classes.searchInput}
          placeholder="Search"
          onChange={handleTextChange}
          name="search"
        />
      <div style={{width:"150px", margin: "0 20px"}}>
        <ZnSourceFormSelect
        style={{maxWidth: "100px"}}
          id="source_form_id"
          label="Form"
          onChange={selectHandleChange}
        /></div>
        <Hidden mdDown  >
          <FormControlLabel control={<Checkbox onChange={handleChange} name="statuses" value={leadConstants.LEAD_STATUS_NEW_MESSAGE} color="primary" />} label="New" />
          <FormControlLabel control={<Checkbox onChange={handleChange} name="statuses" value={leadConstants.LEAD_STATUS_SPAM} color="primary" />} label="Spam" />
          <FormControlLabel control={<Checkbox onChange={handleChange} name="statuses" value={leadConstants.LEAD_STATUS_AUTO_SPAM} color="primary" />} label="AutoSpam" />
        </Hidden>
        
        <span className={classes.spacer} />
        <Button  onClick={exportExcel} >Export</Button>

        


      </MuiToolbar>
    </AppBar>


  );
};

MessagesToolbar.propTypes = {
  className: PropTypes.string
};

export default MessagesToolbar;
