import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ZnTextField from 'common/znFields/ZnTextField';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validate from 'validate.js';
import { ZnSourceWebsiteSelect } from 'common/znFields/ZnSourceSelect';

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: { maximum: 64}
  },
  url: {
    presence: { allowEmpty: false, message: 'is required' },
    length: { maximum: 250}
  },
  source_website_id: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  json_form: {
    presence: { allowEmpty: true, message: 'is required' },
    length: { maximum: 150}
  },
};

const defaultFormSchema = {
  loaded: false,
  isValid: false,
  values: {
    name: '',
    url: '',
    subject: '',
    source_website_id: '',
    json_form: '',
  },
  touched: {},
  errors: {}
};


const Detail = props => {

  const { open, setOpen, item, actions, reducer } = props;

  const doingAction = reducer.doingAction;
  const successAction = reducer.successAction;
  const actionErrorMsg = reducer.actionErrorMsg;

  const dispatch = useDispatch();

  const handleChange = (event) => {
    event.persist();
    handleFieldChange(event.target.name, event.target.type === 'checkbox'
      ? event.target.checked
      : event.target.value);
  };
  const selectHandleChange = (event, values) => {
    handleFieldChange('source_website_id', values ? values.code : "");
  };

  const handleFieldChange = (fieldName, value) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [fieldName]: value,
      },
      touched: {
        ...formState.touched,
        [fieldName]: true
      }
    }));
  };

  const handleSubmit = event => {

    if (!formState.isValid) return;

    if (event.type === 'keypress' && event.key !== 'Enter') {
      return;
    }
    event.preventDefault();
    setFormState(formState => ({
      ...formState,
      touched: schema
    }));


    dispatch(actions.save(formState.values));
  };

  const handleDelete = event => {
    if (formState.values.id) {
      dispatch(actions._delete(formState.values.id));
    }
    setOpen(false);
  };


  useEffect(() => {
    if (successAction === true) handleClose();
  }, [successAction]);

  const handleClose = () => {
    if (!doingAction) {
      setOpen(false);
      setFormState(defaultFormSchema);
    }
  };

  const [formState, setFormState] = useState(defaultFormSchema);

  const formValidation = () => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }

  useEffect(() => {
    formValidation();
  }, [formState.values]);

  useEffect(() => {

    if (item) {
      setFormState({
        ...formState,
        values: item,
      });
    } else {
      setFormState(defaultFormSchema);
    }
  }, [item, open]);


  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      isValid: actionErrorMsg ? false : true,
      errors: actionErrorMsg ? actionErrorMsg.errors : {},
    }));
  }, [actionErrorMsg]);

  const hasError = (field) => {
    if (!formState.errors) return false;
    if (!formState.errors[field]) return false;
    return formState.errors[field] ? true : false;
  }

  const getError = (field) => {
    if (!formState.errors[field]) return null;

    return formState.errors[field] && formState.errors[field][0];
  }



  return (
    <div>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {item ? "Edit item" : "New item"}
        </DialogTitle>
        <DialogContent>

          <ZnSourceWebsiteSelect
            id="source_website_id"
            label="Website"
            onChange={selectHandleChange}
            onKeyPress={handleSubmit}
            value={formState.values.source_website_id}
            error={hasError('source_website_id')}
            helperText={
              hasError('source_website_id') ? formState.errors.source_website_id[0] : null
            }
          />

          <ZnTextField
            onKeyPress={handleSubmit}
            name="name"
            label="Name"
            onChange={handleChange}
            value={formState.values.name || ""}
            error={hasError('name')}
            helperText={
              getError('name')
            }
            margin="normal"
          />

          <ZnTextField
            onKeyPress={handleSubmit}
            name="url"
            label="URL"
            onChange={handleChange}
            value={formState.values.url || ""}
            error={hasError('url')}
            helperText={
              getError('url')
            }
            margin="normal"
          />

          <ZnTextField
            onKeyPress={handleSubmit}
            name="subject"
            label="Subject"
            onChange={handleChange}
            value={formState.values.subject || ""}
            error={hasError('subject')}
            helperText={
              getError('subject')
            }
            margin="normal"
          />

          <ZnTextField
            onKeyPress={handleSubmit}
            name="json_form"
            label="Json form"
            onChange={handleChange}
            value={formState.values.json_form || ""}
            error={hasError('json_form')}
            helperText={
              getError('json_form')
            }
            margin="normal"
          />

        </DialogContent>
        <DialogActions >
          <Button onClick={handleClose} >
            Cancel
          </Button>
          <Button onClick={handleSubmit} >
            Save
          </Button>
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

Detail.defaultProps = {
  open: false,
  item: {},
}
export default Detail;
