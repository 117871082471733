import { WebsiteIcon } from 'icons';
import React from 'react';
import { List} from './components';


const listMapper = (item) => {
  return {
    id: item.id,
    action : null,
    primary: item.full_name ,
    secondary: item.json_website,
    
  };
}

const icon = <WebsiteIcon />

const View = () => {
  return <List 
    mapper={listMapper} 
    icon={icon}
    />;
};

export default View;




