import React, { useState, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  List,
  Avatar,
  Box,
} from '@material-ui/core';

import { leadConstants } from '_constants';
import { withSnackbar } from 'notistack';
import theme from 'theme';
import moment from 'moment';
import LeadNoteForm from './LeadNoteForm';
import { leadActions } from '_actions';
import { ZnUserValue } from 'common/znFields/ZnUserSelect';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: theme.spacing(0),
    
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  item: {
    display: 'flex',
    flexDirection: 'row'
  },
}));



const LeadNotes = props => {

  const dispatch = useDispatch();

  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar, lead, className, ...rest } = props;


  const getNotes = () => {
    if (lead) return lead.notes;
    return [];
  }


  // FORM SUBMIT
  const [requestSubmit, setRequestSubmit] = useState(false);
  
  const handleRequestSubmit = () => {
    setRequestSubmit(true);
  }

  const onSubmit = (values, isValid) => {
    setRequestSubmit(false);
    if (!isValid) {
      props.enqueueSnackbar('Cannot send data. Check form errors!', { variant: "error" });
      return;
    }
    
    setAction(false);
    dispatch(leadActions.doAction(lead.id, leadConstants.LEAD_ACTION_ADD_NOTE, values));
    setFormEdit(false);
    

  }

  const [formIsValid, setFormIsValid] = useState(false);
  const [action, setAction] = useState(null);


  const onFormValidation = (isValid) => {
    setFormIsValid(isValid);
  }

  useEffect(()=> {
    if (lead && lead.status === leadConstants.LEAD_STATUS_NEW && !formIsValid) {
      setFormEdit(true);
    }
  }, [formIsValid, lead]);

  const [formEdit, setFormEdit] = useState(false);

  const setIsFormEditable = (value) => {
    setFormEdit(value);
  }

  const editClick = () => {
    setIsFormEditable(true);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  const cancelClick = () => {
    setIsFormEditable(false);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }


  

  return (
      <Card {...rest} mt={2} className={clsx(classes.root, className)}>
        <CardHeader title="Notes"  action={formEdit ? (
                <Fragment>
                  <Button onClick={handleRequestSubmit}>SAVE</Button>
                  <Button onClick={cancelClick}>CLOSE</Button>
                </Fragment>
              ) : (
                  <Fragment>
                    <Button onClick={(e) => editClick()}>NEW NOTE</Button>
                  </Fragment>
                )}/>
        <CardContent>

          {(formEdit) ? (<LeadNoteForm 
          values="" 
          onSubmit={onSubmit} 
          onFormValidation={onFormValidation}
          requestSubmit={requestSubmit}/>) : null }

          {getNotes().length === 0 && !formEdit ? (
              <Box  style={{ justifyContent: "center", display: "flex" }} >
                <Typography >
                Click the "NEW NOTE" button to add the first one.</Typography>
                {/* <Button  mx="auto"  onClick={(e) => editClick()}>ADD THE FIRST NOTE!</Button> */}
              </Box>
          ) : (
            <List className={classes.root}>
              {getNotes().map( note  => ( 
                <Card className={classes.root}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={classes.avatar} alt={note.user}>
                      </Avatar>
                    }
                    title={<ZnUserValue value={note.userId}/>}
                    subheader={moment(note.created_at).format('DD/MM/YYYY HH:mm')}
                  />
                  <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                    {note.content}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </List>
          )}
        </CardContent>
      </Card>
  );
};

LeadNotes.propTypes = {
  className: PropTypes.string
};

export default withSnackbar(LeadNotes);

