import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  IconButton,
  Collapse, CircularProgress, CardActions
} from '@material-ui/core';
import moment from 'moment';
import theme from 'theme';
import { nl2br } from 'helpers';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { messageConstants } from '_constants';
import { Email,  SyncProblem, Telegram } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { leadActions } from '_actions';



const useStyles = makeStyles(() => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  cardHeaderContent: {
    flexGrow: 0
  },
  cardHeaderRoot: {
    justifyContent: "flex-end"
  }

}));


const outgoingCardStyle = makeStyles(() => ({
  
  content: {
    flexGrow: 0
  },
  root: {
    justifyContent: "flex-end"
  }

}));

const incomingCardStyle = makeStyles(() => ({
  
  content: {
    flexGrow: 1
  },
  root: {
    justifyContent: "flex-star"
  }

}));


const MessageBoxListItem = props => {

  const classes = useStyles();

  const incomingCardClasses = incomingCardStyle();
  const outgoingCardClasses = outgoingCardStyle();
  const getCardHeaderClasses = () => {
    return incomingCardClasses;
    return message.direction === messageConstants.DIRECTION_INCOMING ? incomingCardClasses : outgoingCardClasses;
  }

  const { className, message, messageCount, ...rest } = props;

  const [expanded, setExpanded] = useState(messageCount === 1 ? true : false);
  
  const collapse = () => {
    setExpanded(false);
  };


  const expand = () => {
    setExpanded(true);
  };

  
const getMessageStatusIcon = () => {
  if (message.direction === messageConstants.DIRECTION_INCOMING) {
    return (<Email style={{color: "green"}} />);
  }

  if (message.message_status === messageConstants.STATUS_SENT) {
    return (<Telegram style={{color: "green"}} />); 
  }

  if (message.message_status === messageConstants.STATUS_ERROR) {
    return (<SyncProblem style={{color: "red"}} />); 
  }

  return (<CircularProgress />);

  
}

const dispatch = useDispatch();

const handleSendAgainClick = (event) => {
  dispatch(
    leadActions.sendMessage(message.leadId, {messageId: message.id}, false)
  );
}

const handleDismissClick = (event) => {
  
}

  return (
        <Card className={classes.root} key={message.id}>
          <CardHeader classes={getCardHeaderClasses()}
          avatar = {
            <Fragment>
              {getMessageStatusIcon()}
            </Fragment>}
            // avatar={ message.direction == messageConstants.DIRECTION_INCOMING ? <Email style={{color: "green"}} /> : <Telegram style={{color: "red"}} /> 
              // <Avatar aria-label="recipe" className={classes.avatar}
              //   alt={message.senderEmail}>
              // </Avatar>
            // }
            title={
              <Fragment>
                <Typography variant="h5">{message.subject}</Typography>
                <Typography variant="h6">
                {message.senderName + " <"  + message.senderEmail + ">"}
                </Typography>
              </Fragment>
            }
            // { <Fragment>"From: " +  +  </Fragment>}
            subheader={
              <Fragment>
                To: {message.reciptEmail}<br/>
                #{message.id} {moment(message.date).fromNow()} 
              </Fragment>
            }
            

            action={
              <Fragment>
                {expanded ? (<IconButton  size="small" onClick={collapse}><ExpandLessIcon/></IconButton>) 
                  : (<IconButton  size="small" onClick={expand}><ExpandMoreIcon/></IconButton>)}
              </Fragment>
            }
          />
          
          <Collapse in={expanded} >
            <CardContent>
              <Typography
                variant="body1"
                color="textSecondary"
                component="p"
                dangerouslySetInnerHTML={{ __html: nl2br(message.body) }}
              >
              </Typography>
            </CardContent>
          </Collapse>
          {message.message_status == messageConstants.STATUS_ERROR && (
            <CardActions>
              <Alert severity="error">This message has not been sent! You can retry or dismiss this alert.</Alert>
              <Button size="small" onClick={handleSendAgainClick}>Retry</Button>
              <Button size="small" onClick={handleDismissClick}>Dismiss</Button>
            </CardActions>
          )}
        </Card>
  );
};



MessageBoxListItem.defaultProps = {
  message: {},
  messageCount: null,
}

MessageBoxListItem.propTypes = {
  className: PropTypes.string
};

export default MessageBoxListItem;
