import React, { useEffect, Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid,  Toolbar, AppBar, Modal, Dialog, DialogTitle, DialogActions, DialogContent, Button, IconButton, Typography } from '@material-ui/core';
import { Table } from './components';
import { useDispatch, useSelector } from 'react-redux';
import { analyticActions } from '_actions';
import ZnDateRange from 'common/znFields/ZnDateRange';
import { addDays } from 'date-fns';
import moment from 'moment';



const defaultStartDate = new Date('2020-01-01');
const defaultEndDate =  new Date();

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const Analytics = () => {

  const classes = useStyles();

  const data = useSelector(state => state.analytics)

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const handleDateRangeChange = (dateRange) => {
    setStartDate(dateRange.startDate);
    setEndDate(dateRange.endDate);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    const from = moment(startDate).format('YYYY-MM-DD');
    const to = moment(endDate).format('YYYY-MM-DD');

    dispatch(analyticActions.getAll(from, to));
    dispatch(analyticActions.getUser(from, to));
    dispatch(analyticActions.getDepartment(from, to));
  }, [startDate, endDate]);



  return (
    <Fragment>
      <AppBar color="default" position="sticky">
            <Toolbar>
              <ZnDateRange 
              onChange ={handleDateRangeChange}
              defaultStartDate={defaultStartDate}
              defaultEndDate={defaultEndDate}
              />
            </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Table name="All" data={data.all} />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Table name="Department" data={data.department} />
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Table name="User" data={data.user} />
          </Grid>

        </Grid>
      </div>
    </Fragment>
  );
};

export default Analytics;
