import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { messageActions } from '_actions';
import { TableContainer, Table, TableBody, TableRow, TableCell, Paper, Grid, Typography } from '@material-ui/core';
import { useParams } from 'react-router';
import theme from 'theme';


const useStyles = makeStyles(() => ({
  root: {
    padding: theme.spacing(0)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  leftColumn: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
}));

const MessageDetail = props => {

  const { className, messageId, ...rest } = props;

  const { id } = useParams();

  const classes = useStyles();

  const dispatch = useDispatch();

  const message = useSelector(state => state.messages.item)


  useEffect(() => {
    if (!messageId) return;
    dispatch(messageActions.getOne(messageId), null);
  }, [messageId]);



  return (
    <div className={classes.root}>
      {message && message.auto_data ? (
<Fragment>
<Typography variant='h5'>LeadId #{message.leadId}</Typography>

        <Grid spacing={1} container justify="space-between" >
          <Grid item xs={12} sm={6}>
            <TableContainer component={Paper}>
              <Table aria-label="table">
                <TableBody>
                  
                  <TableRow >
                    <TableCell component="th" scope="row">subject</TableCell>
                    <TableCell >{message.subject}</TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell component="th" scope="row">body</TableCell>
                    <TableCell >
                      {message.htmlMessage || true ? (
                        <p dangerouslySetInnerHTML={{ __html: message.body }}></p>
                      ) : (
                        <p>{message.body}</p>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell component="th" scope="row">mail-id</TableCell>
                    <TableCell >#{message.id}</TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TableContainer component={Paper}>
              <Table aria-label="table">
                <TableBody>
                  <TableRow >
                    <TableCell component="th" scope="row"><b>Filters mached</b></TableCell>
                    <TableCell ><b>{message.lead.source.split("|").map((filterName, j) => {
                      return (<Fragment>{filterName}<br/></Fragment>);
                    })}</b></TableCell>
                  </TableRow>
                  {Object.keys(message.auto_data).map(
                    (label, k) => {
                      if (message.auto_data[label] && message.auto_data[label] != '')
                        if (label !== null && !['json_data', 'regex_data', 'id', 'lead_message_id', 'created_at', 'updated_at'].includes(label)) {
                          return (
                            <TableRow key={k}>
                              <TableCell component="th" scope="row">{label}</TableCell>
                              <TableCell >{message.auto_data[label]}</TableCell>
                            </TableRow>);
                        }
                    }
                  )}


                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        </Fragment>
      ) : 'loading...'}

    </div>
  );
};
export default MessageDetail;



