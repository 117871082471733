import React, { Fragment, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles, Typography, IconButton, Grid, Box, Paper, Chip } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { leadConstants } from '_constants';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles(() => ({

  btnSuccess: {
    color: green[500]
  },
  dialogAction: {
    justifyContent: "center"
  },
}));





const SuccessButton = withStyles(theme => ({
  root: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


const LeadActionModal = props => {

  const classes = useStyles();


  const { lead, open, handleAction, handleClose, actions, leadStatus, defaultMessage } = props;
  const [isOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const [title, setTitle] = useState();
  const [message, setMessage] = useState();

  useEffect(() => {
    if (!leadStatus) return;

    setTitle(leadConstants.LEAD_STATUS_MESSAGES[leadStatus].title);

    // if (lead.amount < 1 && lead.status == leadConstants.LEAD_STATUS_ERP) {
    //   setMessage('You must add the Elibra Amount \nbefore set Lead as completed into Elibra.');
    // }
    if ((lead.amount < 1 || lead.documents.length < 1) && lead.status == leadConstants.LEAD_STATUS_OK) {
      setMessage('You must add the Elibra data file into DOCUMENTS \nand the  the amount of the fee paid by client\nbefore set Lead as loaded into Elibra.');
    } else {
      setMessage(leadConstants.LEAD_STATUS_MESSAGES[leadStatus].message);
    }
  }, [leadStatus]);

  const handleClick = (e) => {

    const action = e.currentTarget.value;
    handleAction(e, action);
  }

  const handleCloseClick = () => {
    handleClose();
    setIsOpen(false);
  };

  return (
    <div>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={isOpen}
        onClose={handleClose}
      
        aria-labelledby="form-dialog-title">


        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Box display="flex" justifyContent="center" >
            <Typography variant="h4">Confirm action</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" >
            <Typography variant="subtitle1">{defaultMessage || message}</Typography>
          </Box>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogAction }}  >
          { 
            ( lead 
              && (
                !((lead.amount < 1 || lead.documents.length < 1) && lead.status == leadConstants.LEAD_STATUS_OK)
                ||
                actions[0] === leadConstants.LEAD_ACTION_SET_PREVIOUS_STATUS
              ) 
              
            ) ? 
          actions.map(a => (
            <Button
            variant="outlined"
              key={a}
              value={a}
              onClick={(e) => handleClick(e)}>
              {leadConstants.LEAD_ACTION_MESSAGES[a].title}
            </Button>)

          ) : (
            <Fragment></Fragment>
          )
        }


        </DialogActions>
      </Dialog>
    </div>
  );
}

LeadActionModal.defaultProps = {
  open: false,
}
export default LeadActionModal;
