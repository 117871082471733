import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  TextField
} from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {}
}));

const UserData = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const user = useSelector(state => state.authentication.user.attributes);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        
        title="User Info"
        subheader="Current user informations"
      />
      <Divider />
      <CardContent>
        <TextField style={{ marginBottom: '1rem' }} disabled fullWidth label="Id" name="id" value={user.id} />
        <TextField style={{ marginBottom: '1rem' }} disabled fullWidth label="Name" name="name" value={user.name} />
        <TextField style={{ marginBottom: '1rem' }} disabled fullWidth label="Email" name="email" value={user.email} />
      </CardContent>
    </Card>
  );
};

UserData.propTypes = {
  className: PropTypes.string
};

export default UserData;
