import React, {  useEffect } from 'react';
import {  EditorState, convertToRaw,convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './ZnDraft2Area.css';
import { useState } from 'react';




 

const ZnDraft2Area = (props) => {

  const {onChange, value} = props;

  const[editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(()=> {
    if (value && value !== JSON.stringify(convertToRaw(editorState.getCurrentContent()))) {
      const contentState = convertFromRaw(JSON.parse(value));
      const editor = EditorState.createWithContent(contentState);
      setEditorState(EditorState.moveFocusToEnd(editor));
    }
  }, [value]);

  const onEditorChange = (editorState) => {
    setEditorState(editorState);
    const contentState = convertToRaw(editorState.getCurrentContent());
    onChange(JSON.stringify(contentState));
  }

  return (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorChange}
        toolbar={{
          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 
          // 'embedded', 'emoji', 'image', 'remove', 'history'
          ],
          fontSize: {
            options: [14],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined
          },
          fontFamily: {
            options: ['Calibri'],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
          },
        
         
        }}
        
        />
    </div>      

   );
}


ZnDraft2Area.defaultProps = {
  value: "",
  onChange: (e, v) => {},
}




export default ZnDraft2Area;


