import React from 'react';
import {  useSelector } from 'react-redux';
import { leadConstants } from '_constants';
import SummaryBox from '../SummaryBox';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';


const LeadsOk = props => {
  
  const total = useSelector(state => state.leads.overview.ok);

  return (
    <SummaryBox 
    icon={<AssignmentTurnedInOutlinedIcon fontSize="large" />}
      title="Converted"
      subtitle={leadConstants.LEAD_STATUS_MESSAGES.LEAD_STATUS_OK.process_action}
      total={total}
      color={leadConstants.LEAD_STATUS_COLORS.CONVERTED}
      statuses={[
        leadConstants.LEAD_STATUS_OK,
      ]}
    />
  );
};

export default LeadsOk;
