import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  TextField
} from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {}
}));

const SignData = props => {
  const { className, ...rest } = props;

  const classes = useStyles();


  
  const user = useSelector(state => state.authentication.user.attributes);
  
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
        <CardHeader
          title="Sign Info"
          subheader="Current user sign informations"
        />
        <Divider />
        <CardContent>
          
        
        <TextField style={{marginBottom: '1rem'}}  disabled fullWidth label="Name" name="sign_name" value={user.sign_name} />
        <TextField style={{marginBottom: '1rem'}}  disabled fullWidth label="Role" name="sign_Role" value={user.sign_role} />
        <TextField style={{marginBottom: '1rem'}}  disabled fullWidth label="Email" name="sign_email" value={user.sign_email} />
        <TextField style={{marginBottom: '1rem'}}  disabled fullWidth label="Phone" name="sign_phone" value={user.sign_phone} />
        <TextField style={{marginBottom: '1rem'}}  disabled fullWidth label="Mobile" name="sign_mobile" value={user.sign_mobile} />
          
        </CardContent>
      
    </Card>
  );
};

SignData.propTypes = {
  className: PropTypes.string
};

export default SignData;
