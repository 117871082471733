import React, { Fragment, useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, IconButton, Typography } from '@material-ui/core';
import { DateRangePicker, defaultStaticRanges, createStaticRanges } from 'react-date-range';
import FilterIcon from '@material-ui/icons/DateRange';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { makeStyles } from "@material-ui/styles";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file


const staticRanges = [
  ...createStaticRanges([
    {
      label: 'All',
      range: () => ({
        // startDate: new Date('2020-01-01'),
        // endDate: new Date()
      })
    }
  ]),
  ...defaultStaticRanges,
];



const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const defaultState = [{
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection',
}]

function ZnDateRange({ title, name, onChange, defaultStartDate, defaultEndDate, slim }) {


  const classes = useStyles();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    setStartDate(defaultStartDate)
  }, [defaultStartDate])

  useEffect(() => {
    setEndDate(defaultEndDate)
  }, [defaultEndDate])


  useEffect(() => {
    onChange && onChange({
      target: {
        name: name,
        value: {
          startDate: startDate,
          endDate: endDate,
        }

      }
      
    });
  }, [startDate, endDate]);


  const [open, setOpen] = useState(false);

  const [state, setState] = useState(defaultState)


  const onRangeChange = (item) => {
    setState([item.selection])
  }

  const onClickApply = () => {
    setStartDate(state[0].startDate);
    setEndDate(state[0].endDate);
    setOpen(false);
  }

  const onClickClear = () => {
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    setState(defaultState)
    setOpen(false);
  }

  const onClickClose = () => {
    setOpen(false);
  }



  return (
    <Fragment>
      <Button onClick={event => setOpen(true)}>
        {!slim && (
          <IconButton >
            <FilterIcon />
          </IconButton>
        )}
        <Typography>
          <>
            {startDate || endDate ?
              <>
                {slim ? "From " : ''}{startDate && moment(startDate).format('ll')}
                {slim ? ' - ' : ' to '} {endDate && moment(endDate).format('ll')}
              </> :
              <>
                {title ? title : 'Choose date range'}
              </>}
          </>
        </Typography>
      </Button>
      <Dialog open={open} fullWidth={true} maxWidth="md" >
        <DialogTitle>
          {title ? title : 'Choose date range'}
          <IconButton className={classes.closeButton} onClick={event => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DateRangePicker
            onChange={onRangeChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction="horizontal"
            maxDate={new Date()}
          // staticRanges={staticRanges}

          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickClose}>Close</Button>
          <Button onClick={onClickClear}>Clear</Button>
          <Button onClick={onClickApply}>Apply</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

ZnDateRange.defaultProps = {
  onChange: () => { },

}


export default ZnDateRange;


