import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar as MuiToolbar, Button } from '@material-ui/core';
import { surveyService } from '_services';


const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  fabProgress: {
    position: 'absolute',
    top: -4,
    left: -4,
    zIndex: 1,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

const Toolbar = props => {

  const { className, setFilter, ...rest } = props;

  const classes = useStyles();

  const exportExcel = () =>{
    surveyService.exportExcel();
  }



  return (
    <AppBar color="default" position="sticky">
      <MuiToolbar>
        <span className={classes.spacer} />
        <Button  onClick={exportExcel} >Export</Button>
      </MuiToolbar>
    </AppBar>


  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
