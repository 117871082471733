/* eslint-disable no-use-before-define */
import React  from 'react';
import ZnSelect from './ZnSelect';



export default function ZnCountrySelect(props) {

  const { id, label, onChange, onKeyPress,  value, error, helperText } = props;


  return (
    <ZnSelect
      id={id}
      label={label}
      onChange={onChange}
      onKeyPress={onKeyPress}
      value={value}
      error={error}
      helperText={helperText}
      items={countries}
      
    />

    
  );

}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countries = [
  { code: 'AD', label: 'Andorra +376' },
  { code: 'AE', label: 'United Arab Emirates +971' },
  { code: 'AF', label: 'Afghanistan +93' },
  { code: 'AG', label: 'Antigua and Barbuda +1-268' },
  { code: 'AI', label: 'Anguilla +1-264' },
  { code: 'AL', label: 'Albania +355' },
  { code: 'AM', label: 'Armenia +374' },
  { code: 'AO', label: 'Angola +244' },
  { code: 'AQ', label: 'Antarctica +672' },
  { code: 'AR', label: 'Argentina +54' },
  { code: 'AS', label: 'American Samoa +1-684' },
  { code: 'AT', label: 'Austria +43' },
  { code: 'AU', label: 'Australia +61', suggested: true },
  { code: 'AW', label: 'Aruba +297' },
  { code: 'AX', label: 'Alland Islands +358' },
  { code: 'AZ', label: 'Azerbaijan +994' },
  { code: 'BA', label: 'Bosnia and Herzegovina +387' },
  { code: 'BB', label: 'Barbados +1-246' },
  { code: 'BD', label: 'Bangladesh +880' },
  { code: 'BE', label: 'Belgium +32' },
  { code: 'BF', label: 'Burkina Faso +226' },
  { code: 'BG', label: 'Bulgaria +359' },
  { code: 'BH', label: 'Bahrain +973' },
  { code: 'BI', label: 'Burundi +257' },
  { code: 'BJ', label: 'Benin +229' },
  { code: 'BL', label: 'Saint Barthelemy +590' },
  { code: 'BM', label: 'Bermuda +1-441' },
  { code: 'BN', label: 'Brunei Darussalam +673' },
  { code: 'BO', label: 'Bolivia +591' },
  { code: 'BR', label: 'Brazil +55' },
  { code: 'BS', label: 'Bahamas +1-242' },
  { code: 'BT', label: 'Bhutan +975' },
  { code: 'BV', label: 'Bouvet Island +47' },
  { code: 'BW', label: 'Botswana +267' },
  { code: 'BY', label: 'Belarus +375' },
  { code: 'BZ', label: 'Belize +501' },
  { code: 'CA', label: 'Canada +1', suggested: true },
  { code: 'CC', label: 'Cocos (Keeling) Islands +61' },
  { code: 'CD', label: 'Congo, Republic of the +242' },
  { code: 'CF', label: 'Central African Republic +236' },
  { code: 'CG', label: 'Congo, Democratic Republic of the +243' },
  { code: 'CH', label: 'Switzerland +41' },
  { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
  { code: 'CK', label: 'Cook Islands +682' },
  { code: 'CL', label: 'Chile +56' },
  { code: 'CM', label: 'Cameroon +237' },
  { code: 'CN', label: 'China +86' },
  { code: 'CO', label: 'Colombia +57' },
  { code: 'CR', label: 'Costa Rica +506' },
  { code: 'CU', label: 'Cuba +53' },
  { code: 'CV', label: 'Cape Verde +238' },
  { code: 'CW', label: 'Curacao +599' },
  { code: 'CX', label: 'Christmas Island +61' },
  { code: 'CY', label: 'Cyprus +357' },
  { code: 'CZ', label: 'Czech Republic +420' },
  { code: 'DE', label: 'Germany +49', suggested: true },
  { code: 'DJ', label: 'Djibouti +253' },
  { code: 'DK', label: 'Denmark +45' },
  { code: 'DM', label: 'Dominica +1-767' },
  { code: 'DO', label: 'Dominican Republic +1-809' },
  { code: 'DZ', label: 'Algeria +213' },
  { code: 'EC', label: 'Ecuador +593' },
  { code: 'EE', label: 'Estonia +372' },
  { code: 'EG', label: 'Egypt +20' },
  { code: 'EH', label: 'Western Sahara +212' },
  { code: 'ER', label: 'Eritrea +291' },
  { code: 'ES', label: 'Spain +34' },
  { code: 'ET', label: 'Ethiopia +251' },
  { code: 'FI', label: 'Finland +358' },
  { code: 'FJ', label: 'Fiji +679' },
  { code: 'FK', label: 'Falkland Islands (Malvinas) +500' },
  { code: 'FM', label: 'Micronesia, Federated States of +691' },
  { code: 'FO', label: 'Faroe Islands +298' },
  { code: 'FR', label: 'France +33', suggested: true },
  { code: 'GA', label: 'Gabon +241' },
  { code: 'GB', label: 'United Kingdom +44' },
  { code: 'GD', label: 'Grenada +1-473' },
  { code: 'GE', label: 'Georgia +995' },
  { code: 'GF', label: 'French Guiana +594' },
  { code: 'GG', label: 'Guernsey +44' },
  { code: 'GH', label: 'Ghana +233' },
  { code: 'GI', label: 'Gibraltar +350' },
  { code: 'GL', label: 'Greenland +299' },
  { code: 'GM', label: 'Gambia +220' },
  { code: 'GN', label: 'Guinea +224' },
  { code: 'GP', label: 'Guadeloupe +590' },
  { code: 'GQ', label: 'Equatorial Guinea +240' },
  { code: 'GR', label: 'Greece +30' },
  { code: 'GS', label: 'South Georgia and the South Sandwich Islands +500' },
  { code: 'GT', label: 'Guatemala +502' },
  { code: 'GU', label: 'Guam +1-671' },
  { code: 'GW', label: 'Guinea-Bissau +245' },
  { code: 'GY', label: 'Guyana +592' },
  { code: 'HK', label: 'Hong Kong +852' },
  { code: 'HM', label: 'Heard Island and McDonald Islands +672' },
  { code: 'HN', label: 'Honduras +504' },
  { code: 'HR', label: 'Croatia +385' },
  { code: 'HT', label: 'Haiti +509' },
  { code: 'HU', label: 'Hungary +36' },
  { code: 'ID', label: 'Indonesia +62' },
  { code: 'IE', label: 'Ireland +353' },
  { code: 'IL', label: 'Israel +972' },
  { code: 'IM', label: 'Isle of Man +44' },
  { code: 'IN', label: 'India +91' },
  { code: 'IO', label: 'British Indian Ocean Territory +246' },
  { code: 'IQ', label: 'Iraq +964' },
  { code: 'IR', label: 'Iran, Islamic Republic of +98' },
  { code: 'IS', label: 'Iceland +354' },
  { code: 'IT', label: 'Italy +39' },
  { code: 'JE', label: 'Jersey +44' },
  { code: 'JM', label: 'Jamaica +1-876' },
  { code: 'JO', label: 'Jordan +962' },
  { code: 'JP', label: 'Japan +81', suggested: true },
  { code: 'KE', label: 'Kenya +254' },
  { code: 'KG', label: 'Kyrgyzstan +996' },
  { code: 'KH', label: 'Cambodia +855' },
  { code: 'KI', label: 'Kiribati +686' },
  { code: 'KM', label: 'Comoros +269' },
  { code: 'KN', label: 'Saint Kitts and Nevis +1-869' },
  { code: 'KP', label: "Korea, Democratic People's Republic of", phone: '850' },
  { code: 'KR', label: 'Korea, Republic of +82' },
  { code: 'KW', label: 'Kuwait +965' },
  { code: 'KY', label: 'Cayman Islands +1-345' },
  { code: 'KZ', label: 'Kazakhstan +7' },
  { code: 'LA', label: "Lao People's Democratic Republic", phone: '856' },
  { code: 'LB', label: 'Lebanon +961' },
  { code: 'LC', label: 'Saint Lucia +1-758' },
  { code: 'LI', label: 'Liechtenstein +423' },
  { code: 'LK', label: 'Sri Lanka +94' },
  { code: 'LR', label: 'Liberia +231' },
  { code: 'LS', label: 'Lesotho +266' },
  { code: 'LT', label: 'Lithuania +370' },
  { code: 'LU', label: 'Luxembourg +352' },
  { code: 'LV', label: 'Latvia +371' },
  { code: 'LY', label: 'Libya +218' },
  { code: 'MA', label: 'Morocco +212' },
  { code: 'MC', label: 'Monaco +377' },
  { code: 'MD', label: 'Moldova, Republic of +373' },
  { code: 'ME', label: 'Montenegro +382' },
  { code: 'MF', label: 'Saint Martin (French part) +590' },
  { code: 'MG', label: 'Madagascar +261' },
  { code: 'MH', label: 'Marshall Islands +692' },
  { code: 'MK', label: 'Macedonia, the Former Yugoslav Republic of +389' },
  { code: 'ML', label: 'Mali +223' },
  { code: 'MM', label: 'Myanmar +95' },
  { code: 'MN', label: 'Mongolia +976' },
  { code: 'MO', label: 'Macao +853' },
  { code: 'MP', label: 'Northern Mariana Islands +1-670' },
  { code: 'MQ', label: 'Martinique +596' },
  { code: 'MR', label: 'Mauritania +222' },
  { code: 'MS', label: 'Montserrat +1-664' },
  { code: 'MT', label: 'Malta +356' },
  { code: 'MU', label: 'Mauritius +230' },
  { code: 'MV', label: 'Maldives +960' },
  { code: 'MW', label: 'Malawi +265' },
  { code: 'MX', label: 'Mexico +52' },
  { code: 'MY', label: 'Malaysia +60' },
  { code: 'MZ', label: 'Mozambique +258' },
  { code: 'NA', label: 'Namibia +264' },
  { code: 'NC', label: 'New Caledonia +687' },
  { code: 'NE', label: 'Niger +227' },
  { code: 'NF', label: 'Norfolk Island +672' },
  { code: 'NG', label: 'Nigeria +234' },
  { code: 'NI', label: 'Nicaragua +505' },
  { code: 'NL', label: 'Netherlands +31' },
  { code: 'NO', label: 'Norway +47' },
  { code: 'NP', label: 'Nepal +977' },
  { code: 'NR', label: 'Nauru +674' },
  { code: 'NU', label: 'Niue +683' },
  { code: 'NZ', label: 'New Zealand +64' },
  { code: 'OM', label: 'Oman +968' },
  { code: 'PA', label: 'Panama +507' },
  { code: 'PE', label: 'Peru +51' },
  { code: 'PF', label: 'French Polynesia +689' },
  { code: 'PG', label: 'Papua New Guinea +675' },
  { code: 'PH', label: 'Philippines +63' },
  { code: 'PK', label: 'Pakistan +92' },
  { code: 'PL', label: 'Poland +48' },
  { code: 'PM', label: 'Saint Pierre and Miquelon +508' },
  { code: 'PN', label: 'Pitcairn +870' },
  { code: 'PR', label: 'Puerto Rico +1' },
  { code: 'PS', label: 'Palestine, State of +970' },
  { code: 'PT', label: 'Portugal +351' },
  { code: 'PW', label: 'Palau +680' },
  { code: 'PY', label: 'Paraguay +595' },
  { code: 'QA', label: 'Qatar +974' },
  { code: 'RE', label: 'Reunion +262' },
  { code: 'RO', label: 'Romania +40' },
  { code: 'RS', label: 'Serbia +381' },
  { code: 'RU', label: 'Russian Federation +7' },
  { code: 'RW', label: 'Rwanda +250' },
  { code: 'SA', label: 'Saudi Arabia +966' },
  { code: 'SB', label: 'Solomon Islands +677' },
  { code: 'SC', label: 'Seychelles +248' },
  { code: 'SD', label: 'Sudan +249' },
  { code: 'SE', label: 'Sweden +46' },
  { code: 'SG', label: 'Singapore +65' },
  { code: 'SH', label: 'Saint Helena +290' },
  { code: 'SI', label: 'Slovenia +386' },
  { code: 'SJ', label: 'Svalbard and Jan Mayen +47' },
  { code: 'SK', label: 'Slovakia +421' },
  { code: 'SL', label: 'Sierra Leone +232' },
  { code: 'SM', label: 'San Marino +378' },
  { code: 'SN', label: 'Senegal +221' },
  { code: 'SO', label: 'Somalia +252' },
  { code: 'SR', label: 'Suriname +597' },
  { code: 'SS', label: 'South Sudan +211' },
  { code: 'ST', label: 'Sao Tome and Principe +239' },
  { code: 'SV', label: 'El Salvador +503' },
  { code: 'SX', label: 'Sint Maarten (Dutch part) +1-721' },
  { code: 'SY', label: 'Syrian Arab Republic +963' },
  { code: 'SZ', label: 'Swaziland +268' },
  { code: 'TC', label: 'Turks and Caicos Islands +1-649' },
  { code: 'TD', label: 'Chad +235' },
  { code: 'TF', label: 'French Southern Territories +262' },
  { code: 'TG', label: 'Togo +228' },
  { code: 'TH', label: 'Thailand +66' },
  { code: 'TJ', label: 'Tajikistan +992' },
  { code: 'TK', label: 'Tokelau +690' },
  { code: 'TL', label: 'Timor-Leste +670' },
  { code: 'TM', label: 'Turkmenistan +993' },
  { code: 'TN', label: 'Tunisia +216' },
  { code: 'TO', label: 'Tonga +676' },
  { code: 'TR', label: 'Turkey +90' },
  { code: 'TT', label: 'Trinidad and Tobago +1-868' },
  { code: 'TV', label: 'Tuvalu +688' },
  { code: 'TW', label: 'Taiwan, Province of China +886' },
  { code: 'TZ', label: 'United Republic of Tanzania +255' },
  { code: 'UA', label: 'Ukraine +380' },
  { code: 'UG', label: 'Uganda +256' },
  { code: 'US', label: 'United States +1', suggested: true },
  { code: 'UY', label: 'Uruguay +598' },
  { code: 'UZ', label: 'Uzbekistan +998' },
  { code: 'VA', label: 'Holy See (Vatican City State) +379' },
  { code: 'VC', label: 'Saint Vincent and the Grenadines +1-784' },
  { code: 'VE', label: 'Venezuela +58' },
  { code: 'VG', label: 'British Virgin Islands +1-284' },
  { code: 'VI', label: 'US Virgin Islands +1-340' },
  { code: 'VN', label: 'Vietnam +84' },
  { code: 'VU', label: 'Vanuatu +678' },
  { code: 'WF', label: 'Wallis and Futuna +681' },
  { code: 'WS', label: 'Samoa +685' },
  { code: 'XK', label: 'Kosovo +383' },
  { code: 'YE', label: 'Yemen +967' },
  { code: 'YT', label: 'Mayotte +262' },
  { code: 'ZA', label: 'South Africa +27' },
  { code: 'ZM', label: 'Zambia +260' },
  { code: 'ZW', label: 'Zimbabwe +263' },
];
