import React, {  Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Checkbox,
  Button,
  Box
} from '@material-ui/core';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { newsletterActions } from '_actions';


const useStyles = makeStyles(theme => ({
  root: {},
  listItem : {
      fontWeight:"strong",
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2),
    backgroundColor: 'lightgray',
    
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));




const NewsletterList = ({ icon, className, items, itemMapper, onClick, actionPath}) => {


  const classes = useStyles();

  const [selectedIds, setSelectedIds] = useState([]);

  const handleOnChange = (e) => {
    if(e.target.checked) {
      setSelectedIds([...selectedIds, e.target.value]);
    } else {
      setSelectedIds(selectedIds.filter(value => value != e.target.value));
    }
  }

  const [all, setAll] = useState(false);

  const handleSelectAll = () => {
    setAll(true);
    
  }

  const handleDeselectAll = () => {
    setAll(false);
  }

  useEffect(()=> {
    if (all) {
      setSelectedIds(items.map(i =>  '' +  i.id));
    } else {
      setSelectedIds([]);
    }
  }, [all])

  const isChecked = (id) => {
    return selectedIds.includes('' +id) ? true : false;
  }


  const dispatch = useDispatch();
  const handleSync = () => {
    dispatch(newsletterActions.syncAll(selectedIds));
  }

 

  return (
    <Fragment>
      <Box padding={2} style={{ display:"flex", justifyContent:"space-between"}}>
        {all ? (
          <Button onClick={handleDeselectAll}>Deselect all</Button>
        ) : (
          <Button onClick={handleSelectAll}>Select all</Button>
        )}
        
        <Button
          onClick={handleSync}
          variant="contained" 
          color="primary">Sync Selected</Button></Box>
      
      <List className={classes.listRoot} >
          {items.map(i => {
            const item = itemMapper(i);
            
            if (!item) return null;

            return (
            <Fragment key={item.id}>
              
              <ListItem  >
                <ListItemAvatar style={{color: '#546e7a'}}>
                  <Checkbox onChange={handleOnChange} value={i.id} checked={isChecked(i.id)}/>
                </ListItemAvatar>
                
                <ListItemAvatar style={{color: '#546e7a'}}>
                      {item.icon ? item.icon : icon}
                 </ListItemAvatar>
                <ListItemText
                  classes={{root: classes.listItem}}
                  primary={item.primary}
                  secondary={item.secondary}
                />
                
              </ListItem>
              <Divider></Divider>
            </Fragment>
            )}
          )}
        </List>
    </Fragment>
  );
};

NewsletterList.propTypes = {
  className: PropTypes.string,
};

export default NewsletterList;
