import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import NewMessages from './components/NewMessages';
import LeadsOpened from './components/LeadsOpened';
import LeadsOk from './components/LeadsOk';
import { useDispatch, useSelector } from 'react-redux';
import { leadActions } from '_actions';
import LeadsErp from './components/LeadsErp/LeadsErp';
import LeadList from 'views/Lead/LeadsList/LeadList';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  
}));

const Dashboard = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(leadActions.getOverview());
  }, []);

  const leadReload = useSelector(state => state.config.leadReload);
  useEffect(() => {
    if(!leadReload) return;
    dispatch(leadActions.getOverview());
  }, [ leadReload]);


  return (
    <div className={classes.root}>
      <LeadList />
    </div>
  );
};

export default Dashboard;
