import { sourceWebsiteActions as actions} from '../../../_actions';
import React, { useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import ItemList from 'common/ItemList';
import { TablePagination } from '@material-ui/core';
import { Detail, Toolbar } from '.';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    backgroundColor: "white",
  },
  content: {
    marginTop: theme.spacing(0)
  }
}));

const List = ({mapper, icon}) => {
  
  const data = useSelector(state => state.sourceWebsite)
  const classes = useStyles();
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({text :  '',});




  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const handlePageChange = (event, page) => {
    setPage(page);
  };
  const handleRowsPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  
  // load data
  useEffect(() => {
    dispatch(actions.getAll(filter.text, page +1, rowsPerPage));
  }, [page, rowsPerPage, filter]);

 

  // modal
  const [open, setOpen] = React.useState(false);
  const [itemId, setItemId] = React.useState(null);
  const [item, setItem] = React.useState(null);
  useEffect(() => {
    const key = data.items.findIndex(item => item.id === itemId);
    if (key in data.items) {
      setItem(data.items[key]);
    } else {
      setItem(null);
    }
    
  }, [itemId, data]);

  
  const handleItemClick = (newId) => {
    setItemId(newId);
    setOpen(true);
  }

  const hadleFilterChange = (value) => {
    setFilter(value);
    setPage(0);
  }

 
  // renderer
  return (
    <div className={classes.root}>
      <Toolbar  setFilter={hadleFilterChange} handleClick={handleItemClick}/>
      
      <div className={classes.content}>
        <ItemList icon={icon} items={data.items} itemMapper={mapper} onClick={handleItemClick}/>
        <Detail setOpen={setOpen} open={open} item={item}></Detail>
      </div>

      { (data.items && data.items.length > 0) && (
      <TablePagination
          component="div"
          count={data.total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]}
        />
      )}
    </div>
  );
};

export default List;



