import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { Card, CardContent, CardHeader } from '@material-ui/core';



const PerformanceByDepartments = props => {
  const { series = [], categories = [], title, subheader } = props;




  const cartData = {
    options: {
      dataLabels: {
        enabled: true,
        //offsetX: 10,
        offsetY: -20,
        style: {
           colors: ['#555']
        },
      },
      chart: {
        type: 'bar',
        id: "PerformanceByDepartments",

      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top',
            
            
          }
        },
        
      },
      xaxis: {
        categories: categories
      }
    },
    series: series
  };


  return (categories && series) ?
    <Card>
      <CardHeader 
      title={title} 
      subheader={subheader}>
        
      </CardHeader>
      <CardContent>
        <ReactApexChart
          type="bar"
          options={cartData.options}
          series={cartData.series}
          height={350} />
      </CardContent>
    </Card> : <>loading</>

};

PerformanceByDepartments.propTypes = {
  series: PropTypes.array,
  categories: PropTypes.array,
};

export default PerformanceByDepartments;
