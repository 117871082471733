import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {  Fab, CircularProgress} from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';
import { useSelector, useDispatch } from 'react-redux';
import { leadActions } from '_actions';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  fabProgress: {
    position: 'absolute',
    top: -4,
    left: -4,
    zIndex: 1,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

const MessagesSync = props => {

  const { className, setFilter, ...rest } = props;

  const classes = useStyles();


  const dispatch = useDispatch();
  const handleSync = () => {
    dispatch(leadActions.fetchMail());
    
  }
  const loading = useSelector(state => state.messages.loading)


  return (
    
        <div className={classes.wrapper}>
          <Fab 
            size="small"
            aria-label="save"
            onClick={handleSync}
          >
          {loading ? <SyncIcon /> : <SyncIcon />}
          </Fab>
          {loading && <CircularProgress 
            size={56} 
            thickness={2}
            className={classes.fabProgress} />}
        </div>
    
  );
};

MessagesSync.propTypes = {
  className: PropTypes.string
};

export default MessagesSync;
