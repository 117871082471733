import { tagConstants } from '../_constants';
import { tagService } from '../_services';
import { alertActions } from '.';


export const tagActions = {
    save,
    getAll,
    getOne,
    _delete,
};




function save(tag) {

    return dispatch => {

        dispatch(request());

        tagService.doAction(tag.id, tagConstants.ACTION_SAVE, tag)
            .then(
                result => {
                    dispatch(alertActions.success('Source Saved'));
                    dispatch(success(result.data));
                },
                error => {
                    dispatch(failure(error));
                }
            );
       
            
    };

    function request() {
        return { type: tagConstants.KICK_REQUEST };
    }
    function success(tag) {
        return { type: tagConstants.KICK_SUCCESS, tag };
    }
    function failure(error) {
        return { type: tagConstants.KICK_FAILURE, error };
    }
}

function getAll(text = '', page = '', per_page = '') {
    return (dispatch, getState) => {
        const {departmentId} = getState().config;

        dispatch(request());

        tagService.getAll([departmentId], text, page, per_page)
            .then(
                tags => {
                    dispatch(success(tags.data, tags.meta));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };

    function request() { 
        return { type: tagConstants.GETALL_REQUEST } 
    }
    function success(tags, meta) { 
        return { type: tagConstants.GETALL_SUCCESS, tags, meta} 
    }
    function failure(error) { 
        return { type: tagConstants.GETALL_FAILURE, error } 
    }
}

function getOne(id) {
    
    return dispatch => {
        dispatch(request());

        tagService.getById(id)
            .then(
                tag => dispatch(success(tag.data)),
                error => dispatch(failure(error))
            );
    };

    function request() { 
        return { type: tagConstants.GETONE_REQUEST } 
    }
    function success(tag) { 
        return { type: tagConstants.GETONE_SUCCESS, tag } 
    }
    function failure(error) { 
        return { type: tagConstants.GETONE_FAILURE, id, error } 
    }
}

function _delete(id) {
    
    return dispatch => {
        dispatch(request());

        tagService._delete(id)
            .then(
                tag => dispatch(getAll()),
                //tag => dispatch(success(tag.data)),
                error => dispatch(failure(error))
            );
    };

    function request() { 
        return { type: tagConstants.DELETE_REQUEST } 
    }
    // function success(tag) { 
    //     return { type: tagConstants.DELETE_SUCCESS, tag } 
    // }
    function failure(error) { 
        return { type: tagConstants.DELETE_FAILURE, id, error } 
    }
}



