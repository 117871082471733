import { sourceWebsiteConstants } from '../_constants';
import { sourceWebsiteService } from '../_services';
import { alertActions } from '.';


export const sourceWebsiteActions = {
    save,
    getAll,
    getOne,
    _delete,
};




function save(sourceWebsite) {

    return dispatch => {

        dispatch(request());

        sourceWebsiteService.doAction(sourceWebsite.id, sourceWebsiteConstants.ACTION_SAVE, sourceWebsite)
            .then(
                result => {
                    dispatch(alertActions.success('Source Website Saved'));
                    dispatch(success(result.data));
                },
                error => {
                    dispatch(failure(error));
                }
            );
       
            
    };

    function request() {
        return { type: sourceWebsiteConstants.KICK_REQUEST };
    }
    function success(sourceWebsite) {
        return { type: sourceWebsiteConstants.KICK_SUCCESS, sourceWebsite };
    }
    function failure(error) {
        return { type: sourceWebsiteConstants.KICK_FAILURE, error };
    }
}

function getAll(text = '', page = '', per_page = '') {
    return (dispatch, getState) => {
        const {departmentId} = getState().config;

        dispatch(request());

        sourceWebsiteService.getAll([departmentId], text, page, per_page)
            .then(
                sourceWebsites => {
                    dispatch(success(sourceWebsites.data, sourceWebsites.meta));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };

    function request() { 
        return { type: sourceWebsiteConstants.GETALL_REQUEST } 
    }
    function success(sourceWebsites, meta) { 
        return { type: sourceWebsiteConstants.GETALL_SUCCESS, sourceWebsites, meta} 
    }
    function failure(error) { 
        return { type: sourceWebsiteConstants.GETALL_FAILURE, error } 
    }
}

function getOne(id) {
    
    return dispatch => {
        dispatch(request());

        sourceWebsiteService.getById(id)
            .then(
                sourceWebsite => dispatch(success(sourceWebsite.data)),
                error => dispatch(failure(error))
            );
    };

    function request() { 
        return { type: sourceWebsiteConstants.GETONE_REQUEST } 
    }
    function success(sourceWebsite) { 
        return { type: sourceWebsiteConstants.GETONE_SUCCESS, sourceWebsite } 
    }
    function failure(error) { 
        return { type: sourceWebsiteConstants.GETONE_FAILURE, id, error } 
    }
}

function _delete(id) {
    
    return dispatch => {
        dispatch(request());

        sourceWebsiteService._delete(id)
            .then(
                sourceWebsite => dispatch(getAll()),
                //sourceWebsite => dispatch(success(sourceWebsite.data)),
                error => dispatch(failure(error))
            );
    };

    function request() { 
        return { type: sourceWebsiteConstants.DELETE_REQUEST } 
    }
    // function success(sourceWebsite) { 
    //     return { type: sourceWebsiteConstants.DELETE_SUCCESS, sourceWebsite } 
    // }
    function failure(error) { 
        return { type: sourceWebsiteConstants.DELETE_FAILURE, id, error } 
    }
}



