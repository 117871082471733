import { sourceFormConstants } from '../_constants';

const initialStatus =  {
  successAction: false,
  items : [],
  total: 0,
  item : null,
  loading : false,
  error : null,

};

export function sourceForm(state = initialStatus, action) {

  
  switch (action.type) {
    case sourceFormConstants.KICK_REQUEST:
      return {
        ...state,
        successAction: false,
        doingAction: true,
        actionError: false,
        actionErrorMsg: null,

      };

    case sourceFormConstants.KICK_SUCCESS:
      const key = state.items.findIndex(item => item.id === action.sourceForm.id);
      let ii = state.items;
      
      if (key in ii) {
        ii.splice(key, 1, action.sourceForm);
      } else {
        ii.push(action.sourceForm);
      }

      return {
        ...state,
        successAction: true,
        doingAction: false,
        actionError: false,
        actionErrorMsg: null,
        items: ii,
      };
      break;

    case sourceFormConstants.KICK_FAILURE:
        return {
          ...state,
          successAction: false,
          doingAction: false,
          actionError: true,
          actionErrorMsg: action.error,


        };



    case sourceFormConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sourceFormConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.sourceForms,
        total: action.meta.total,

        loading: false
      };
    case sourceFormConstants.GETALL_FAILURE:
      return { 
        ...state,
        error: action.error,
        loading: false
      };
    case sourceFormConstants.GETONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sourceFormConstants.GETONE_SUCCESS:
      return {
        ...state,
        item: action.sourceForm,
        loading: false
      };
    case sourceFormConstants.GETONE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case sourceFormConstants.DELETE_REQUEST:
      // add 'deleting:true' property to sourceForm being deleted
      return {
        ...state,
        items: state.items.map(sourceForm =>
          sourceForm.id === action.id
            ? { ...sourceForm, deleting: true }
            : sourceForm
        )
      };
    case sourceFormConstants.DELETE_SUCCESS:
      // remove deleted sourceForm from state
      return {
        ...state,
        items: state.items.filter(sourceForm => sourceForm.id !== action.id)
      };
    case sourceFormConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to sourceForm 
      return {
        ...state,
        items: state.items.map(sourceForm => {
          if (sourceForm.id === action.id) {
            // make copy of sourceForm without 'deleting:true' property
            const { deleting, ...sourceFormCopy } = sourceForm;
            // return copy of sourceForm with 'deleteError:[error]' property
            return { ...sourceFormCopy, deleteError: action.error };
          }

          return sourceForm;
        })
      };
    default:
      return state;
  }
}