import { authHeader } from '../helpers';
import handleResponse from './handleResponse';


export const procedureService = {

    doAction,
    getAll,
    
    
    download,
    _delete,
};

function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/procedure/${id}`, requestOptions).then(handleResponse);
}

function download(fileName) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/media/download/${fileName}`, requestOptions);
}


function doAction(procedureId, action, data = []) {
    return kick({
        'action': action,
        'procedureId': procedureId,
        'data': data
    });
}

function kick(data) {


    console.group(data);
    const formData = new FormData();
    formData.append('procedureId', data.data.id);
    formData.append('name', data.data.name);
    formData.append('language', data.data.language);
    formData.append('file', data.data.file);
    formData.append('fileName', data.data.fileName);
    formData.append('departments', data.data.departments);


    const requestOptions = {
        method: 'POST',
        headers: authHeader(false),
        body: formData,
        redirect: 'follow',
    };


    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/procedure`, requestOptions).then(handleResponse);
}

function getAll(departmentIds = []) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/procedure?departmentIds=${departmentIds.join()}`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/procedure/${id}`, requestOptions).then(handleResponse);
}







function remove(modelName, messageId, fileName) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/media/${modelName}/${messageId}/${fileName}`, requestOptions).then(handleResponse);
}
