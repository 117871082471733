import React from 'react';
import Button from '@material-ui/core/Button';
import { Chip, Divider, FormControlLabel, Link, Paper, Typography } from '@material-ui/core';
import { ZnAttachmentSelect } from 'common/znFields/ZnAttachmentsSelect';
import { attachmentActions } from '_actions';




const ZnAttachmentsAreaReadOnly = props => {

  const {
    selectedAttachments,
    attachments,
    handleAttach,
    handleSelect,
    handleRemove,
    handleDownload
   } = props;

  const handleClick = event => {
    event.target.value = "";

  }

  const handleChange = (value) => {
    if (!value) return;
    handleSelect(value);
  }

  return (
    <Paper variant="outlined" elevation={1} style={{ marginBottom: "10px", padding: "10px" }}>
      <Typography  >OLD ATTACHMENTS - only for download</Typography>
      {/* <Button color="primary"
        component="label"
      >Upload Attachment<input
          onChange={handleAttach}
          onClick={handleClick}
          type="file"
          style={{ display: "none" }}
        />
      </Button> or */}
      {/* <ZnAttachmentSelect 
        onChange={handleChange}
        itemsDisabled={attachments.messageItems}
        ></ZnAttachmentSelect> */}
        
      {selectedAttachments.map((attachment, key) => {
        return (
          <Button size='small'
          variant='outlined'
            key={key}
            label={attachment.name}
           // onDelete={() => { handleRemove(attachment.id) }}
            onClick={() => { handleDownload(attachment.id) }}

          >{attachment.name}</Button>
        );
      })}

    </Paper>
  );
}


export default ZnAttachmentsAreaReadOnly;
