import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {  Checkbox, FormControlLabel, Hidden, AppBar, Toolbar } from '@material-ui/core';

import { SearchInput } from 'components';
import { newsletterConstants } from '_constants';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    //height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const NewslettersToolbar = props => {
  
  const { className, setFilter, filter,  ...rest } = props;

  const classes = useStyles();
 
  const [timer, setTimer] = useState();

  const handleTextChange = event => {
    clearTimeout(timer);
    const value = event.target.value;
    setTimer(setTimeout((event)=> {
      setFilter({
        ...filter,
        text: value
      });
  }, 800))
  };

  const handleChange = event => {

    const checked = event.target.checked;
    const value = event.target.value;

    let sts = filter.statuses;
    if (checked) {
      sts.push(value);
    } else {
      sts.splice( sts.indexOf(value), 1 );
    }

    setFilter({
      ...filter,
      statuses: sts,
    });
  };


  const getStautsChecked = (status) => {
    return filter.statuses.includes(status);

  }

  return (
    
    <AppBar color="default" position="sticky">
    <Toolbar>
      
    
        <SearchInput
        
          className={classes.searchInput}
          placeholder="Search"
          onChange={handleTextChange}
          name="search"
        />
        <Hidden mdDown  >
          <FormControlLabel control={
              <Checkbox onChange={handleChange}  
              checked={getStautsChecked(newsletterConstants.LEAD_STATUS_NEW)}
              value={newsletterConstants.LEAD_STATUS_NEW} 
              color="primary" />
            } 
            label="New" />
          <FormControlLabel control={<Checkbox onChange={handleChange}  
            checked={getStautsChecked(newsletterConstants.LEAD_STATUS_CHECKED)}
            value={newsletterConstants.LEAD_STATUS_CHECKED} 
            color="primary" />} 
            label="Checked" />
          <FormControlLabel control={<Checkbox onChange={handleChange}  
            checked={getStautsChecked(newsletterConstants.LEAD_STATUS_FIRST_CONTACT)} 
            value={newsletterConstants.LEAD_STATUS_FIRST_CONTACT} 
            color="primary" />} 
            label="Processing" />
          <FormControlLabel control={<Checkbox onChange={handleChange} 
          checked={getStautsChecked(newsletterConstants.LEAD_STATUS_OK)} 
            value={newsletterConstants.LEAD_STATUS_OK} 
            color="primary" />} 
            label="Converted" />
          <FormControlLabel control={<Checkbox onChange={handleChange} 
          checked={getStautsChecked(newsletterConstants.LEAD_STATUS_KO)} 
            value={newsletterConstants.LEAD_STATUS_KO} 
            color="primary" />} 
            label="Not Converted" />
          <FormControlLabel control={<Checkbox onChange={handleChange} 
            checked={getStautsChecked(newsletterConstants.LEAD_STATUS_ERP)} 
            value={newsletterConstants.LEAD_STATUS_ERP} 
            color="primary" />} 
            label="Elibra" />
          <FormControlLabel control={<Checkbox onChange={handleChange} 
            checked={getStautsChecked(newsletterConstants.LEAD_STATUS_ERP_COMPLETED)} 
            value={newsletterConstants.LEAD_STATUS_ERP_COMPLETED} 
            color="primary" />} 
            label="Elibra" />
        </Hidden>
        
      
        </Toolbar>
</AppBar>
   
    
  );
};

NewslettersToolbar.propTypes = {
  className: PropTypes.string
};

export default NewslettersToolbar;
