
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { List} from './components';

import { FormIcon } from 'icons';
import { sourceFormActions as actions } from '_actions';
import { Link } from '@material-ui/core';



const listMapper = (item) => {
  return {
    id: item.id,
    action : null,
    primary: item.full_name ,
    secondary: <Fragment>#{item.id} - {item.json_form ?? item.json_form} <Link target='blank' href={item.url} >{item.url}</Link></Fragment> ,
    
  };
}

const icon = <FormIcon />

const View = () => {

  const reducer = useSelector(state => state.sourceForm);

  return <List 
    mapper={listMapper} 
    icon={icon}
    actions={actions}
    reducer={reducer}
    />;
};

export default View;

