import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Button, Toolbar, Typography } from '@material-ui/core';
import { leadConstants } from '_constants';


const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const LeadFormToolbar = props => {
  const { className, onCancel, onSend, onSkip, lead, ...rest } = props;

  const classes = useStyles();

 
  const hadleCancelClick = () => {
    onCancel();
  }

  const hadleSendClick = () => {
    onSend();
  }

  const hadleSkipClick = () => {
    onSkip();
  }

  return (
    <AppBar color="default" position="sticky">
    <Toolbar>

        <Typography variant="h3">New E-Mail</Typography>
        <span className={classes.spacer} />
        
        <Button
        style={{ marginLeft: "10px"}}
          color="default"
          variant="contained"
          onClick={hadleCancelClick}
        >
          Cancel 
        </Button>
        <Button
        style={{ marginLeft: "10px"}}
          color="primary"
          variant="contained"
          onClick={hadleSendClick}
        >
          Send
        </Button>
        {lead.status === leadConstants.LEAD_STATUS_CHECKED && (
          <Button
        style={{ marginLeft: "10px"}}
          color="default"
          variant="contained"
          onClick={hadleSkipClick}
        >Skip
        </Button>
        ) }
        
        


      </Toolbar>
    </AppBar>
  );
};

LeadFormToolbar.propTypes = {
  className: PropTypes.string
};

export default LeadFormToolbar;
