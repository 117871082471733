import { authHeader } from '../helpers';
import handleResponse from './handleResponse';
import { sourceConstants } from '_constants';

export const sourceService = {
    doAction,
    getAll,
    getById,
    _delete,
};


function doAction(sourceId, action, data = []) {
    return kick({
        'action': action,
        'sourceId': sourceId,
        'data' : data
    });
}

function kick(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch( process.env.REACT_APP_API_ENDPOINT + `/api/source-kick`, requestOptions).then(handleResponse);
}

function getAll(departmentIds = [], text = '', page = '', per_page='')  {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/source?departmentIds=${departmentIds.join()}&text=${text}&page=${page}&per_page=${per_page}`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/source/${id}`, requestOptions).then(handleResponse);
}

function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/source/${id}`, requestOptions).then(handleResponse);
}

