import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MessageBoxListItem from './MessageBoxListItem';

const MessageBoxList = props => {
  
  const { className, messages,  ...rest } = props;

  return (
    <Fragment>
      {Array.isArray(messages) && messages.map(message => (
       <MessageBoxListItem key={message.id} message={message} messageCount={messages.length}></MessageBoxListItem>
      ))}
    </Fragment>
  );
};

MessageBoxList.propTypes = {
  className: PropTypes.string
};

export default MessageBoxList;
