import React, { useState, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  List,
  Avatar,
  Box,
  CardActionArea,
  CardActions,
} from '@material-ui/core';

import { leadConstants } from '_constants';
import { withSnackbar } from 'notistack';
import theme from 'theme';
import moment from 'moment';
import LeadDocumentForm from './LeadDocumentForm';
import { attachmentActions, leadActions } from '_actions';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: theme.spacing(0),
    
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  item: {
    display: 'flex',
    flexDirection: 'row'
  },
}));



const LeadDocuments = props => {

  const dispatch = useDispatch();

  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar, lead, className, ...rest } = props;


  const getDocuments = () => {
    if (lead && lead.documents ) return lead.documents;
    return [];
  }


  // FORM SUBMIT
  const [requestSubmit, setRequestSubmit] = useState(false);
  
  const handleRequestSubmit = () => {
    setRequestSubmit(true);
  }

  const onSubmit = (values, isValid) => {
    setRequestSubmit(false);
    if (!isValid) {
      props.enqueueSnackbar('Cannot send data. Check form errors!', { variant: "error" });
      return;
    }
    
    setAction(false);
    dispatch(leadActions.doAction(lead.id, leadConstants.LEAD_ACTION_ADD_DOCUMENT, values));
    setFormEdit(false);
    

  }

  const [formIsValid, setFormIsValid] = useState(false);
  const [action, setAction] = useState(null);


  const onFormValidation = (isValid) => {
    setFormIsValid(isValid);
  }

  useEffect(()=> {
    if (lead && lead.status === leadConstants.LEAD_STATUS_NEW && !formIsValid) {
      setFormEdit(true);
    }
  }, [formIsValid, lead]);

  const [formEdit, setFormEdit] = useState(false);

  const setIsFormEditable = (value) => {
    setFormEdit(value);
  }

  const editClick = () => {
    setIsFormEditable(true);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  const cancelClick = () => {
    setIsFormEditable(false);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  const handleClickDownload = (fileName) => {
    attachmentActions.download('document/' + lead.id + '/', fileName);
  };


  

  return (
      <Card {...rest} mt={2} className={clsx(classes.root, className)}>
        <CardHeader title="Documents"  action={formEdit ? (
                <Fragment>
                  <Button onClick={handleRequestSubmit}>SAVE</Button>
                  <Button onClick={cancelClick}>CLOSE</Button>
                </Fragment>
              ) : (
                  <Fragment>
                    <Button onClick={(e) => editClick()}>ADD DOCUMENT</Button>
                  </Fragment>
                )}/>
        <CardContent>

          {(formEdit) ? (<LeadDocumentForm 
          values="" 
          onSubmit={onSubmit} 
          onFormValidation={onFormValidation}
          requestSubmit={requestSubmit}/>) : null }

          {getDocuments().length === 0 && !formEdit ? (
              <Box  style={{ justifyContent: "center", display: "flex" }} >
                <Typography >
                Click the "NEW DOCUMENT" button to add the first one.</Typography>
              </Box>
          ) : (
            <List className={classes.root}>
              {getDocuments().map( document  => ( 
                <Card className={classes.root}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={classes.avatar} alt={document.user}>
                      </Avatar>
                    }
                    title={document.name}
                    subheader={moment(document.created_at).format('DD/MM/YYYY HH:mm')}
                  />
                  <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                    {document.content}
                    </Typography>
                  </CardContent>
                  <CardActionArea><CardActions><Button
                        onClick={e => handleClickDownload(document.fileName)}
                        //color="primary"
                      >DOWNLOAD</Button></CardActions></CardActionArea>
                </Card>
              ))}
            </List>
          )}
        </CardContent>
      </Card>
  );
};

LeadDocuments.propTypes = {
  className: PropTypes.string
};

export default withSnackbar(LeadDocuments);

