
export default (name, role, email, mobile, phone ) => {


  const fontFamily = "fontfamily-Calibri";
  const nameText = `${name} | ${role}`;
  const nameLength = nameText.length;


  const addressText = `Via del Lauro, 9 | 20121 Milan | Italy\nT +39 02 366 386 10 | M ${mobile} | E ${email}\n `;
  const addressLength = addressText.length;


  const emailText = `${email}\n`;
  const emailLength = emailText.length;


  const websiteText = `www.lexia.it\n`;
  const websiteLength = websiteText.length;

  const citiesText = `\nMilan | Rome | Palermo`;
  const citiesLength = citiesText.length;

  



  return {
    "contentBlocks": [
      {
        "key": "82eqg",
        "text": nameText,
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [
          {
            "offset": 0,
            "length": nameLength,
            "style": "color-rgb(0,0,0) marginbottom-0"
          },
          {
            "offset": 0,
            "length": nameLength,
            "style": "fontsize-14"
          },
          {
            "offset": 0,
            "length": nameLength,
            "style": fontFamily
          },
          {
            "offset": 0,
            "length": nameLength,
            "style": "marginbottom-0"
          },
        ],
        "entityRanges": [],
        "data": {
          "text-align": "start"
        }
      },
      {
        "key": "7u5e8",
        "text": " ",
        "type": "atomic",
        "depth": 0,
        "inlineStyleRanges": [
          {
            "offset": 0,
            "length": 1,
            "style": fontFamily
          }
        ],
        "entityRanges": [
          {
            "offset": 0,
            "length": 1,
            "key": 9000
          }
        ],
        "data": {}
      },
      {
        "key": "7aao4",
        "text": addressText,
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [
          {
            "offset": 0,
            "length": addressLength,
            "style": "color-rgb(0,0,0)"
          },
          {
            "offset": 0,
            "length": addressLength,
            "style": "fontsize-14"
          },
          {
            "offset": 0,
            "length": addressLength,
            "style": fontFamily
          }
        ],
        "entityRanges": [],
        "data": {}
      },
      // {
      //   "key": "36nrm",
      //   "text": emailText + websiteText + citiesText,
      //   "type": "unstyled",
      //   "depth": 0,
      //   "inlineStyleRanges": [
      //     {
      //       "offset": 0,
      //       "length": emailLength + websiteLength + citiesLength,
      //       "style": "color-rgb(0,0,0)"
      //     },
      //     {
      //       "offset": 0,
      //       "length": emailLength + websiteLength + citiesLength,
      //       "style": "fontsize-14"
      //     },
      //     {
      //       "offset": 0,
      //       "length": emailLength + websiteLength + citiesLength,
      //       "style": fontFamily
      //     },
      //     {
      //       "offset": 0,
      //       "length": emailLength,
      //       "style": "UNDERLINE"
      //     },
      //     {
      //       "offset": emailLength,
      //       "length": websiteLength,
      //       "style": "UNDERLINE"
      //     },
      //     {
      //       "offset": 0,
      //       "length": emailLength,
      //       "style": "color-rgb(178, 27, 22)"
      //     },
      //     {
      //       "offset": emailLength,
      //       "length": websiteLength,
      //       "style": "color-rgb(178, 27, 22)"
      //     }
      //   ],
      //   "entityRanges": [
      //     {
      //       "offset": 0,
      //       "length": 24,
      //       "key": 9001
      //     },
      //     {
      //       "offset": 25,
      //       "length": 12,
      //       "key": 9002
      //     }
      //   ],
      //   "data": {
      //     "text-align": "start"
      //   }
      // },
      {
        "key": "4b9ll",
        "text": "Il contenuto di questa e-mail è rivolto unicamente alle persone alle quali è indirizzato e può contenere informazioni la cui riservatezza è tutelata legalmente. Sono vietati la riproduzione, la diffusione e l’uso di questa e-mail in mancanza di autorizzazione del destinatario. Se avete ricevuto questa e-mail per errore vogliate cortesemente contattarci immediatamente.",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [
          {
            "offset": 0,
            "length": 370,
            "style": "color-rgb(0,0,0)"
          },
          {
            "offset": 0,
            "length": 370,
            "style": "fontsize-14"
          },
          {
            "offset": 0,
            "length": 370,
            "style": fontFamily
          }
        ],
        "entityRanges": [],
        "data": {
          "text-align": "start"
        }
      },
      {
        "key": "esf07",
        "text": "This e-mail is intended only for the person or entity to which it is addressed and may contain information that is privileged, confidential or otherwise protected from disclosure. Unauthorised reproduction, dissemination or use of this e-mail or of the information herein by anyone other than the intended recipient is prohibited. If you have received this e-mail by mistake, please notify us immediately.",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [
          {
            "offset": 0,
            "length": 405,
            "style": "color-rgb(0,0,0)"
          },
          {
            "offset": 0,
            "length": 405,
            "style": "fontsize-14"
          },
          {
            "offset": 0,
            "length": 405,
            "style": fontFamily
          }
        ],
        "entityRanges": [],
        "data": {}
      }
    ],
    "entityMap": {
      "9000": {
        "type": "IMAGE",
        "mutability": "MUTABLE",
        "data": {
         // "src": "https://www.lexia.it/wp-content/uploads/2017/04/lexia-logo-1.png",
          "src": "/logo-piccolo.png",
          "height": "80px",
          "width": "auto",
          "alignment": "left"
        }
      },
      "9001": {
        "type": "LINK",
        "mutability": "MUTABLE",
        "data": {
          "url": `mailto:${email}`,
          "title": `<span style="color: rgb(178, 27, 22);">${email}</span>`,
          "targetOption": "",
          "_map": {
            "type": "LINK",
            "mutability": "MUTABLE",
            "data": {
              "url": `mailto:${email}`,
              "title": `<span style="color: rgb(178, 27, 22);">${email}</span>`,
              "targetOption": ""
            }
          }
        }
      },
      "9002": {
        "type": "LINK",
        "mutability": "MUTABLE",
        "data": {
          "url": "http://www.lexia.it/",
          "title": "<span style=\"color: rgb(178, 27, 22);\">www.lexia.it</span>",
          "targetOption": "",
          "_map": {
            "type": "LINK",
            "mutability": "MUTABLE",
            "data": {
              "url": "http://www.lexia.it/",
              "title": "<span style=\"color: rgb(178, 27, 22);\">www.lexia.it</span>",
              "targetOption": ""
            }
          }
        }
      }
    }
  }
}
