export const customerConstants = {
  GETALL_REQUEST: 'CUSTOMER_GETALL_REQUEST',
  GETALL_SUCCESS: 'CUSTOMER_GETALL_SUCCESS',
  GETALL_FAILURE: 'CUSTOMER_GETALL_FAILURE',

  SYNCALL_REQUEST: 'CUSTOMER_SYNCALL_REQUEST',
  SYNCALL_SUCCESS: 'CUSTOMER_SYNCALL_SUCCESS',
  SYNCALL_FAILURE: 'CUSTOMER_SYNCALL_FAILURE',

  EXPORT_REQUEST: 'CUSTOMER_EXPORT_REQUEST',
  EXPORT_SUCCESS: 'CUSTOMER_EXPORT_SUCCESS',
  EXPORT_FAILURE: 'CUSTOMER_EXPORT_FAILURE',

  ACTION_SAVE: 'CUSTOMER_ACTION_SAVE',

  KICK_REQUEST: 'CUSTOMER_KICK_REQUEST',
  KICK_SUCCESS: 'CUSTOMER_KICK_SUCCESS',
  KICK_FAILURE: 'CUSTOMER_KICK_FAILURE',

  GETONE_REQUEST: 'CUSTOMER_GETONE_REQUEST',
  GETONE_SUCCESS: 'CUSTOMER_GETONE_SUCCESS',
  GETONE_FAILURE: 'CUSTOMER_GETONE_FAILURE',

  DELETE_REQUEST: 'CUSTOMER_DELETE_REQUEST',
  DELETE_SUCCESS: 'CUSTOMER_DELETE_SUCCESS',
  DELETE_FAILURE: 'CUSTOMER_DELETE_FAILURE',

  RECOVER_REQUEST: 'CUSTOMER_RECOVER_REQUEST',
  RECOVER_SUCCESS: 'CUSTOMER_RECOVER_SUCCESS',
  RECOVER_FAILURE: 'CUSTOMER_RECOVER_FAILURE',

  IMPORT_REQUEST: 'CUSTOMER_IMPORT_REQUEST',
  IMPORT_SUCCESS: 'CUSTOMER_IMPORT_SUCCESS',
  IMPORT_FAILURE: 'CUSTOMER_IMPORT_FAILURE',

  EXPORT_REQUEST: 'CUSTOMER_EXPORT_REQUEST',
  EXPORT_SUCCESS: 'CUSTOMER_EXPORT_SUCCESS',
  EXPORT_FAILURE: 'CUSTOMER_EXPORT_FAILURE',
};
