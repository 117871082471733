import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import ZnTextField from 'common/znFields/ZnTextField';
import { withSnackbar } from 'notistack';
import theme from 'theme';
import validate from 'validate.js';
import ZnAttachmentField from 'common/znFields/ZnAttachmentField';



const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 3,
      maximum: 1000
    }
  },
};

const LeadDocumentForm = props => {

  const { values, onSubmit, onFormValidation,  requestSubmit, className, ...rest } = props;



  /**
   * form managerment
   */
  const [formState, setFormState] = useState({
    loaded: false,
    isValid: false,
    values: {
      name: '',
      
      
    },
    touched: {},
    errors: {}
  });

  /**
   * form validation
   */
  const formValidation = () => {
    const errors = validate(formState.values, schema);
    onFormValidation(errors ? false : true);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }

  const hasError = (field) => {
    return formState.errors[field] ? true : false;
  }

  useEffect(() => {
    if (requestSubmit === true) {
      submit();
    }
  }, [requestSubmit]);


  useEffect(() => {
    formValidation();
  }, [formState.values]);

  /**
   * 
   * form change 
   */
  const handleChange = (event)  => {
    event.persist();
    LeadDocumenthandleFieldChangeForm(event.target.name, event.target.type === 'checkbox'
    ? event.target.checked
    : event.target.value);
  };



 const LeadDocumenthandleFieldChangeForm = (fieldName, value)  => {
   setFormState(formState => ({
     ...formState,
     values: {
       ...formState.values,
       [fieldName]:value,
         
     },
     touched: {
       ...formState.touched,
       [fieldName]: true
     }
   }));
 };



  /**
   * 
   * form submit
   */
  const handleSubmit = event => {
    if (event.type === 'keypress' && event.key !== 'Enter') {
      return;
    }
    submit();
  }

  const submit = () => {
    formValidation();
    onSubmit(formState.values, formState.isValid);
  }

  const handleAttach = (file, fileName) => {
    handleFieldChange('file', file);
    handleFieldChange('fileName', fileName);
  }

  const handleAttachDelete = () => {
    handleFieldChange('file', ``);
    handleFieldChange('fileName', ``);
  }

  const handleAttachClick = fileName => {
    //attachmentActions.download('attachments/att/' , fileName);

  }

  const handleFieldChange = (fieldName, value) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [fieldName]: value,

      },
      touched: {
        ...formState.touched,
        [fieldName]: true
      }
    }));
  };

  const getError = (field) => {
    return (formState.errors && formState.errors[field]) && formState.errors[field][0];
  }

  return (
    <form>
    <Fragment>
      <Grid
        container
        spacing={1}
        wrap="wrap"
      >
        <Grid item xs={12} lg={12}>
          

        <ZnTextField
            onKeyPress={handleSubmit}
            name="name"
            label="Name"
            onChange={handleChange}
            value={formState.values.name || ""}
            error={hasError('name')}
            helperText={
              getError('name')
            }
            margin="normal"
          />

        <ZnAttachmentField 
            fileName={formState.values.fileName} 
            onAttach={handleAttach}  
            onAttachDelete={handleAttachDelete}
            onAttachClick={handleAttachClick}/>

          
        </Grid>
      </Grid>
    </Fragment>
    </form>
  );
};

LeadDocumentForm.defaultProps = {
  onSubmit: function(values) {},
  onFormValidation: function(isValid) {},
}

LeadDocumentForm.propTypes = {
  onSubmit: PropTypes.func,
  onFormValidation: PropTypes.func,
};

export default withSnackbar(LeadDocumentForm);

