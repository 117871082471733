import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ZnTagSelect } from 'common/znFields/ZnTagsSelect';
import ZnDateRange from 'common/znFields/ZnDateRange';
import { ZnButton } from 'common/znFields/ZnButton';
import { MenuItem, Select, TextField } from '@material-ui/core';


const defaultFormState = {
  values: {
    text: "",
    tags: "",
    addedAt: {
      fromDate: new Date('1900-01-01'),
      toDate: new Date('1999-01-01'),
    }
  }
}

const SearchFrom = props => {
  const { className, setFilter, handleClick, handleChange, handleImport, handleExport, reducer, ...rest } = props;


  const [formState, setFormState] = useState(defaultFormState);

  useEffect(() => {
    console.log("formState.values", formState.values)

    handleChange && handleChange(formState.values)
  }, [formState])

  const onFieldChange = (event) => {

    handleFieldChange(event.target.name, event.target.type === 'checkbox'
      ? event.target.checked
      : event.target.value);
  };

  // const handleTextChange = event => {

  //   // on text editing we set form value with delay to prevent change it in realtime
  //   if (typing !== false) {
  //     clearTimeout(typing);
  //     setTyping(false);
  //   }

  //   const st = {
  //     ...formState,
  //     text: event.target.value
  //   };

  //   setTyping(
  //     setTimeout(function () {
  //       setFormState(st)
  //     }, 400)
  //   );
  // };


  const handleFieldChange = (fieldName, value) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [fieldName]: value,
      }
    }));
  };



  const handleClear = () => {
    setFormState(defaultFormState)
  }

  return (
    <>
      <TextField
        placeholder="Search"
        onChange={onFieldChange}
        name="text"
        value={formState.values.text}

      />
      <ZnTagSelect
        name="tags"
        value={formState.values.tags}
        onChange={onFieldChange}
      />


      <ZnDateRange
        name="addedAt"
        title="Added at"
        onChange={onFieldChange}
        defaultStartDate={formState.values.addedAt.fromDate}
        defaultEndDate={formState.values.addedAt.toDate}


      />

      <ZnButton
        onClick={handleClear}
      >Clear</ZnButton></>
  )

}

SearchFrom.propTypes = {
  className: PropTypes.string
};

export default SearchFrom;
