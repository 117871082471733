import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import ZnTextField from 'common/znFields/ZnTextField';
import { withSnackbar } from 'notistack';
import theme from 'theme';
import validate from 'validate.js';



const schema = {
  content: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 3,
      maximum: 1000
    }
  },
};

const LeadNoteForm = props => {

  const { values, onSubmit, onFormValidation,  requestSubmit, className, ...rest } = props;



  /**
   * form managerment
   */
  const [formState, setFormState] = useState({
    loaded: false,
    isValid: false,
    values: {
      date: '',
      content: '',
    },
    touched: {},
    errors: {}
  });

  /**
   * form validation
   */
  const formValidation = () => {
    const errors = validate(formState.values, schema);
    onFormValidation(errors ? false : true);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }

  const hasError = (field) => {
    return formState.errors[field] ? true : false;
  }

  useEffect(() => {
    if (requestSubmit === true) {
      submit();
    }
  }, [requestSubmit]);


  useEffect(() => {
    formValidation();
  }, [formState.values]);

  /**
   * 
   * form change 
   */
  const handleChange = (event)  => {
    event.persist();
    LeadNotehandleFieldChangeForm(event.target.name, event.target.type === 'checkbox'
    ? event.target.checked
    : event.target.value);
  };



 const LeadNotehandleFieldChangeForm = (fieldName, value)  => {
   setFormState(formState => ({
     ...formState,
     values: {
       ...formState.values,
       [fieldName]:value,
         
     },
     touched: {
       ...formState.touched,
       [fieldName]: true
     }
   }));
 };



  /**
   * 
   * form submit
   */
  const handleSubmit = event => {
    if (event.type === 'keypress' && event.key !== 'Enter') {
      return;
    }
    submit();
  }

  const submit = () => {
    formValidation();
    onSubmit(formState.values, formState.isValid);
  }

  return (
    <form>
    <Fragment>
      <Grid
        container
        spacing={1}
        wrap="wrap"
      >
        <Grid item xs={12} lg={12}>
          <ZnTextField
            name="content"
            label="New note"
            onChange={handleChange}
            value={formState.values.content}
            error={hasError('content')}
            helperText={
              hasError('content') ? formState.errors.content[0] : null
            }
            margin="normal"
            variant="outlined"
            multiline
            rows="6"
          />
        </Grid>
      </Grid>
    </Fragment>
    </form>
  );
};

LeadNoteForm.defaultProps = {
  onSubmit: function(values) {},
  onFormValidation: function(isValid) {},
}

LeadNoteForm.propTypes = {
  onSubmit: PropTypes.func,
  onFormValidation: PropTypes.func,
};

export default withSnackbar(LeadNoteForm);

