import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button
} from '@material-ui/core';
import { procedureActions } from '_actions';
import { ZnButton } from 'common/znFields/ZnButton';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    //minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ProceduresTable = props => {

  const { className, procedures, handleProcedureClick, handleProcedureClickDelete, ...rest } = props;

  const classes = useStyles();

  const [selectedProcedures, setSelectedProcedures] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);

  const handleSelectAll = event => {

    let selectedProcedures;

    if (event.target.checked) {
      selectedProcedures = procedures.map(procedure => procedure.id);
    } else {
      selectedProcedures = [];
    }

    setSelectedProcedures(selectedProcedures);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedProcedures.indexOf(id);
    let newSelectedProcedures = [];

    if (selectedIndex === -1) {
      newSelectedProcedures = newSelectedProcedures.concat(selectedProcedures, id);
    } else if (selectedIndex === 0) {
      newSelectedProcedures = newSelectedProcedures.concat(selectedProcedures.slice(1));
    } else if (selectedIndex === selectedProcedures.length - 1) {
      newSelectedProcedures = newSelectedProcedures.concat(selectedProcedures.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedProcedures = newSelectedProcedures.concat(
        selectedProcedures.slice(0, selectedIndex),
        selectedProcedures.slice(selectedIndex + 1)
      );
    }

    setSelectedProcedures(newSelectedProcedures);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const handleClickOpen = (id) => {
    handleProcedureClick(id);
  };

  const handleClickDownload = (fileName) => {
    procedureActions.download('procedures/att/', fileName);
  };

  const handleClickDelete = (id) => {
    handleProcedureClickDelete(id);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  {/* <TableCell>Language</TableCell> */}
                  <TableCell>File</TableCell>
                  {/* <TableCell>Departments</TableCell> */}
                  <TableCell style={{ textAlign: "center" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {procedures.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map(procedure => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={procedure.id}
                    selected={selectedProcedures.indexOf(procedure.id) !== -1}
                  >

                    <TableCell>
                      <Typography variant="body1">(#{procedure.id}) {procedure.name}</Typography>
                    </TableCell>
                    {/* <TableCell>
                      <ZnLanguageValue value={procedure.language}></ZnLanguageValue>
                    </TableCell> */}
                    <TableCell>
                      <Typography variant="body1">
                        {procedure.fileName}
                      </Typography>
                    </TableCell>
                    {/* <TableCell>
                      <ZnDepartmentValue value={procedure.departments} />
                    </TableCell> */}
                    <TableCell style={{ textAlign: "center" }}>
                      <Button
                        onClick={e => handleClickOpen(procedure.id)}
                        color="primary"
                      >Edit</Button>
                      <Button
                        onClick={e => handleClickDownload(procedure.fileName)}
                        color="primary"
                      >DOWNLOAD</Button>
                      <ZnButton
                        onClick={e => handleClickDelete(procedure.id)}
                        color="primary"
                        confirm
                        confirmTitle="Do you really want to delete it ?"
                        confirmText={`Please confirm you want to delete the procedure ${procedure.fileName}.`}
                      >Delete</ZnButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={procedures.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]}
        />
      </CardActions>
    </Card>
  );
};

ProceduresTable.propTypes = {
  className: PropTypes.string,
};

export default ProceduresTable;
