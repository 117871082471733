import { leadConstants } from '../_constants';


const initalState = {
  items : [],
  total : 0,
  overview: {
    new: 0,
    processing: 0,
    ok:0
  },
  loading : false,
  sending: false,
  error:null,
  sendMailRequest: false,

};

export function leads(state = initalState, action) {

  

  
  switch (action.type) {
   
    case leadConstants.SEND_MAIL_REQUEST:
      return {
        ...state,
        sendMailRequest: true,
     };

    case leadConstants.SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMailRequest: false,
        sending: true,
        error:null,
        
      };
    case leadConstants.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        sending:false,
        error:null,
        item: action.lead
      };
    case leadConstants.SEND_MESSAGE_FAILURE:
      return {
        ...state,
        sending:false,
        error: action.error
      };
    case leadConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case leadConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.leads,
        total: action.meta.total,
      };
    case leadConstants.GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case leadConstants.GETONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case leadConstants.GETONE_SUCCESS:
      return {
        ...state,
        item: action.lead,
        loading: false
      };
    case leadConstants.GETONE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case leadConstants.UPDATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case leadConstants.UPDATE_SUCCESS:
      return {
        ...state,
        item: action.lead,
        loading: false
      };
    case leadConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };


      case leadConstants.GETOVERVIEW_REQUEST:
        return {
          ...state,

        };
      case leadConstants.GETOVERVIEW_SUCCESS:
        return {
          ...state,

          overview: {
            new: action.overview.new,
            processing: action.overview.processing,
            ok:action.overview.ok,
            erp:action.overview.erp,
          },
        };

        
      case leadConstants.GETOVERVIEW_FAILURE:
        return {
          ...state,
          error: action.error,
          
        };

    default:
      return state;
  }
}