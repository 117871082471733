export const procedureConstants = {

    
   
    ACTION_SAVE: 'PROCEDURET_ACTION_SAVE',

    KICK_REQUEST: 'PROCEDURET_KICK_REQUEST',
    KICK_SUCCESS: 'PROCEDURET_KICK_SUCCESS',
    KICK_FAILURE: 'PROCEDURET_KICK_FAILURE',
 
    GETALL_REQUEST: 'PROCEDURET_GETALL_REQUEST',
    GETALL_SUCCESS: 'PROCEDURET_GETALL_SUCCESS',
    GETALL_FAILURE: 'PROCEDURET_GETALL_FAILURE',

    GETONE_REQUEST: 'PROCEDURET_GETONE_REQUEST',
    GETONE_SUCCESS: 'PROCEDURET_GETONE_SUCCESS',
    GETONE_FAILURE: 'PROCEDURET_GETONE_FAILURE',

    GETALL_BY_MESSAGE_REQUEST: 'PROCEDURET_GETALL_BY_MESSAGE_REQUEST',
    GETALL_BY_MESSAGE_SUCCESS: 'PROCEDURET_GETALL_BY_MESSAGE_SUCCESS',
    GETALL_BY_MESSAGE_FAILURE: 'PROCEDURET_GETALL_BY_MESSAGE_FAILURE',

    ATTACH_REQUEST: 'PROCEDURET_ATTACH_REQUEST',
    ATTACH_SUCCESS: 'PROCEDURET_ATTACH_SUCCESS',
    ATTACH_FAILURE: 'PROCEDURET_ATTACH_FAILURE',

    REMOVE_REQUEST: 'PROCEDURET_REMOVE_REQUEST',
    REMOVE_SUCCESS: 'PROCEDURET_REMOVE_SUCCESS',
    REMOVE_FAILURE: 'PROCEDURET_REMOVE_FAILURE',

    DELETE_REQUEST: 'PROCEDURET_DELETE_REQUEST',
    DELETE_SUCCESS: 'PROCEDURET_DELETE_SUCCESS',
    DELETE_FAILURE: 'PROCEDURET_DELETE_FAILURE',
    
};
