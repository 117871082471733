import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper, Input, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
  root: {
    border: '0',
    borderRadius: '4px',
    boxShadow: 'none',
    border: 0,
    backgroundColor: 'inherit',
    alignItems: 'bottom',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 180
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  input: {
    flexGrow: null,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  }
}));

const DateInput = props => {
  const { className, onChange, onKeyPress, style, ...rest } = props;

  const classes = useStyles();

  return (
    <Paper
      {...rest}
      className={clsx(classes.root, className)}
      style={style}
      
    >

      <Input
        width={10}
        fullWidth={false}
        {...rest}
        label="From"
        type="date"
        defaultValue="2017-05-24"
        className={classes.input}
        //disableUnderline
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
      
     
    </Paper>
  );
};

DateInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object
};

export default DateInput;
