import React, { useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { ProceduresToolbar, ProceduresTable } from '.';
import { useDispatch, useSelector } from 'react-redux';
import { procedureActions } from '../../../../_actions';
import ProcedureDetail from './ProcedureDetail';
import { isConstructorDeclaration } from 'typescript';
import ZnDraftArea from 'common/znFields/ZnDraftArea';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const ProcedureList = () => {
  const classes = useStyles();
  const procedures = useSelector(state => state.procedures)
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({
    text :  "",
    statuses : []
  });

  useEffect(() => {
    dispatch(procedureActions.getAll());
  }, []);

  useEffect(() => {
    getFiltered();
  }, []);

  const [open, setOpen] = React.useState(false);
  const [procedureId, setProcedureId] = React.useState(null);
  const [procedure, setProcedure] = React.useState(null);

  useEffect(() => {
    const key = procedures.items.findIndex(item => item.id === procedureId);

    if (key in procedures.items) {
      setProcedure(procedures.items[key]);
    } else {
      setProcedure(null);
    }
    
  }, [procedureId, procedures]);

  
  const handleProcedureClick = (newId) => {
    setProcedureId(newId);
    setOpen(true);
  }

  const handleProcedureClickDelete = (id) => {
    dispatch(procedureActions._delete(id));
  }

  const getFiltered = () => {
    
    return procedures.items.filter(
      (procedure) => {
        const text = filter.text.toLowerCase();
        
        return (
          procedure.name && procedure.name.toLowerCase().includes(text) 
          ||
          procedure.fileName && procedure.fileName.toLowerCase().includes(text) 
        );
      }
    );
    
  }

 

  return (
    <div className={classes.root}>
      <ProceduresToolbar  setFilter={setFilter} handleProcedureClick={handleProcedureClick}/>
      <div className={classes.content}>
        <ProceduresTable handleProcedureClick={handleProcedureClick} 
        handleProcedureClickDelete={handleProcedureClickDelete}
        procedures={getFiltered()} />
        <ProcedureDetail setOpen={setOpen} open={open} procedure={procedure}></ProcedureDetail>
      </div>
      {/* <ZnDraftArea ></ZnDraftArea> */}
    </div>
  );
};

export default ProcedureList;



