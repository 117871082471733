import { sourceFormConstants } from '../_constants';
import { sourceFormService } from '../_services';
import { alertActions } from '.';


export const sourceFormActions = {
    save,
    getAll,
    getOne,
    _delete,
};




function save(sourceForm) {

    return dispatch => {

        dispatch(request());

        sourceFormService.doAction(sourceForm.id, sourceFormConstants.ACTION_SAVE, sourceForm)
            .then(
                result => {
                    dispatch(alertActions.success('Source Form Saved'));
                    dispatch(success(result.data));
                },
                error => {
                    dispatch(failure(error));
                }
            );
       
            
    };

    function request() {
        return { type: sourceFormConstants.KICK_REQUEST };
    }
    function success(sourceForm) {
        return { type: sourceFormConstants.KICK_SUCCESS, sourceForm };
    }
    function failure(error) {
        return { type: sourceFormConstants.KICK_FAILURE, error };
    }
}

function getAll(text = '', source_website_id='',  page = '', per_page = '') {
    return (dispatch, getState) => {
        const {departmentId} = getState().config;

        dispatch(request());

        sourceFormService.getAll([source_website_id], text, page, per_page)
            .then(
                sourceForms => {
                    dispatch(success(sourceForms.data, sourceForms.meta));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };

    function request() { 
        return { type: sourceFormConstants.GETALL_REQUEST } 
    }
    function success(sourceForms, meta) { 
        return { type: sourceFormConstants.GETALL_SUCCESS, sourceForms, meta} 
    }
    function failure(error) { 
        return { type: sourceFormConstants.GETALL_FAILURE, error } 
    }
}

function getOne(id) {
    
    return dispatch => {
        dispatch(request());

        sourceFormService.getById(id)
            .then(
                sourceForm => dispatch(success(sourceForm.data)),
                error => dispatch(failure(error))
            );
    };

    function request() { 
        return { type: sourceFormConstants.GETONE_REQUEST } 
    }
    function success(sourceForm) { 
        return { type: sourceFormConstants.GETONE_SUCCESS, sourceForm } 
    }
    function failure(error) { 
        return { type: sourceFormConstants.GETONE_FAILURE, id, error } 
    }
}

function _delete(id) {
    
    return dispatch => {
        dispatch(request());

        sourceFormService._delete(id)
            .then(
                sourceForm => dispatch(getAll()),
                //sourceForm => dispatch(success(sourceForm.data)),
                error => dispatch(failure(error))
            );
    };

    function request() { 
        return { type: sourceFormConstants.DELETE_REQUEST } 
    }
    // function success(sourceForm) { 
    //     return { type: sourceFormConstants.DELETE_SUCCESS, sourceForm } 
    // }
    function failure(error) { 
        return { type: sourceFormConstants.DELETE_FAILURE, id, error } 
    }
}



