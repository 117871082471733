export const attachmentConstants = {

    
   
    ACTION_SAVE: 'ATTACHMENT_ACTION_SAVE',

    KICK_REQUEST: 'ATTACHMENT_KICK_REQUEST',
    KICK_SUCCESS: 'ATTACHMENT_KICK_SUCCESS',
    KICK_FAILURE: 'ATTACHMENT_KICK_FAILURE',
 
    GETALL_REQUEST: 'ATTACHMENT_GETALL_REQUEST',
    GETALL_SUCCESS: 'ATTACHMENT_GETALL_SUCCESS',
    GETALL_FAILURE: 'ATTACHMENT_GETALL_FAILURE',

    GETONE_REQUEST: 'ATTACHMENT_GETONE_REQUEST',
    GETONE_SUCCESS: 'ATTACHMENT_GETONE_SUCCESS',
    GETONE_FAILURE: 'ATTACHMENT_GETONE_FAILURE',

    GETALL_BY_MESSAGE_REQUEST: 'ATTACHMENT_GETALL_BY_MESSAGE_REQUEST',
    GETALL_BY_MESSAGE_SUCCESS: 'ATTACHMENT_GETALL_BY_MESSAGE_SUCCESS',
    GETALL_BY_MESSAGE_FAILURE: 'ATTACHMENT_GETALL_BY_MESSAGE_FAILURE',

    ATTACH_REQUEST: 'ATTACHMENT_ATTACH_REQUEST',
    ATTACH_SUCCESS: 'ATTACHMENT_ATTACH_SUCCESS',
    ATTACH_FAILURE: 'ATTACHMENT_ATTACH_FAILURE',

    REMOVE_REQUEST: 'ATTACHMENT_REMOVE_REQUEST',
    REMOVE_SUCCESS: 'ATTACHMENT_REMOVE_SUCCESS',
    REMOVE_FAILURE: 'ATTACHMENT_REMOVE_FAILURE',

    DELETE_REQUEST: 'ATTACHMENT_DELETE_REQUEST',
    DELETE_SUCCESS: 'ATTACHMENT_DELETE_SUCCESS',
    DELETE_FAILURE: 'ATTACHMENT_DELETE_FAILURE',
    
};
