import { authHeader } from '../helpers';
import handleResponse from './handleResponse';


export const messageService = {
    doAction,
    getAll,
    getById,
    _delete,
    exportExcel,
};

function exportExcel() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    fetch(process.env.REACT_APP_API_ENDPOINT + `/api/message/export`, requestOptions)
        .then(
            res => {
                const filename = res.headers.get('Content-Disposition').split('filename=')[1];
                res.blob().then(blob => {
                    var fileURL = window.URL.createObjectURL(blob);
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.download = filename;
                    fileLink.click();
                });
        });
}

function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/message/${id}`, requestOptions).then(handleResponse);
}


function doAction(messagelId, action, data = []) {
    return kick({
        'action': action,
        'messagelId': messagelId,
        'data' : data
    });
}

function kick(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch( process.env.REACT_APP_API_ENDPOINT + `/api/message-kick`, requestOptions).then(handleResponse);
}


function getAll(sourceFormId = '', leadStatus = [], text = '', leadId = '', check = false, page = '', per_page = '') {
    if(check) check = "1";
    else check= "";
    let statuses = leadStatus.join();
    
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/message?source_form_id=${sourceFormId}&lead-status=${statuses}&text=${text}&lead-id=${leadId}&check=${check}&page=${page}&per_page=${per_page}`, requestOptions).then(handleResponse);
    
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/message/${id}`, requestOptions).then(handleResponse);
}
