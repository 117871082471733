import { procedureConstants } from '../_constants';

const initialStatus = {
  successAction: false,
  items: [],
  messageItems: [],
  loading: false,
  error: null,

};

export function procedures(state = initialStatus, action) {


  switch (action.type) {


    case procedureConstants.KICK_REQUEST:
      return {
        ...state,
        successAction: false,
        doingAction: true,
        actionError: false,
        actionErrorMsg: null,
        loading: false,
      };

    case procedureConstants.KICK_SUCCESS:
      const key = state.items.findIndex(item => item.id === action.procedure.id);
      let ii = state.items;

      if (key in ii) {
        ii.splice(key, 1, action.procedure);
      } else {
        ii.push(action.procedure);
      }

      return {
        ...state,
        successAction: true,
        doingAction: false,
        actionError: false,
        actionErrorMsg: null,
        items: ii,
      };
      break;

    case procedureConstants.KICK_FAILURE:
      return {
        ...state,
        successAction: false,
        doingAction: false,
        actionError: true,
        actionErrorMsg: action.error,
        loading: false,
      };




    case procedureConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case procedureConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.procedures,
        loading: false
      };
    case procedureConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };


      case procedureConstants.GETALL_BY_MESSAGE_REQUEST:
        return {
          ...state,
          loading: true
        };
      case procedureConstants.GETALL_BY_MESSAGE_SUCCESS:
        return {
          ...state,
          messageItems: action.procedures,
          loading: false
        };
      case procedureConstants.GETALL_BY_MESSAGE_FAILURE:
        return {
          ...state,
          error: action.error,
          loading: false
        };


    case procedureConstants.GETONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case procedureConstants.GETONE_SUCCESS:
      return {
        ...state,
        item: action.procedure,
        loading: false
      };
    case procedureConstants.GETONE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case procedureConstants.ATTACH_REQUEST:
      return {
        ...state,
        loading: true
      };
    case procedureConstants.ATTACH_SUCCESS:
      return {
        ...state,
        messageItems: action.procedures,
        loading: false
      };
    case procedureConstants.ATTACH_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case procedureConstants.REMOVE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case procedureConstants.REMOVE_SUCCESS:
      return {
        ...state,
        messageItems: action.procedures,
        loading: false
      };
    case procedureConstants.REMOVE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };



    default:
      return state;
  }
}