import { surveyConstants } from '../_constants';

const initialStatus = {
  successAction: false,
  items: [],
  total: 0,
  loading: false,
  error: null,

};

export function surveys(state = initialStatus, action) {

  
  switch (action.type) {
    case surveyConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.surveys,
        total: action.meta.total,
        loading: false
      };
    case surveyConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    default:
      return state;
  }
}