import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery, Box } from '@material-ui/core';
import { Sidebar, Topbar, Footer, SidebarRight } from './components';
import UtilityBar from './components/UtilityBar';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  shiftContent: {
    paddingLeft: 240
  },
  shiftContentRight: {
    paddingRight: 300
  },
  content: {
    height: '100%',
    paddingTop: theme.spacing(8),
    // [theme.breakpoints.up('lg')]: {
    //   paddingLeft: theme.spacing(3),
    //   paddingRight: theme.spacing(3),
    // },
  }
}));

const Main = props => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });


  // LEFT SIDEBAR

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  const onClick = () => {
    handleSidebarClose();
  }


  // RIGHT SIDEBAR
  const [openSidebarRight, setOpenSidebarRight] = useState(false);

  const handleSidebarRightOpen = () => {
    setOpenSidebarRight(true);
  };

  const handleSidebarRightClose = () => {
    setOpenSidebarRight(false);
  };

  




  return (
    <div className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
        [classes.shiftContentRight]: isDesktop && openSidebarRight,
      })}>
      
      <Sidebar
        // onClick={onClick}
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      
      <main className={classes.content} >
        <Topbar onSidebarOpen={handleSidebarOpen}  onSidebarRightOpen={handleSidebarRightOpen}  />
        {children}
        <Footer />
      </main>

      <SidebarRight
        onClick={handleSidebarRightClose}
        onClose={handleSidebarRightClose}
        open={openSidebarRight}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />

    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
