/* eslint-disable no-use-before-define */
import React from 'react';
import { Divider } from '@material-ui/core';

export {
  ZnDivider,
}

const ZnDivider = () => {
  return (
    <Divider orientation="vertical" flexItem variant="middle" style={{ width: "2px" }} />
  );
}




