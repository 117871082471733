import React, { useState, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import MessageBox from './MessageBox';
import LeadDocuments from './LeadDocuments';
import LeadNotes from './LeadNotes';
import LeadAudit from './LeadAudit';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Tab, Tabs, Grid, 
} from '@material-ui/core';

import LeadActions from './LeadActions/LeadActions';
import { leadActions } from '_actions';
import { leadConstants } from '_constants';
import { withSnackbar } from 'notistack';
import theme from 'theme';
import LeadForm from './LeadForm';
import LeadCard from './LeadCard';
import LeadStatus from './LeadStatus';
import LeadStatusChip from './LeadStatusChip';

const useStyles = makeStyles(() => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  item: {
    display: 'flex',
    flexDirection: 'row'
  },
 
}));

const LeadBox = props => {

  const dispatch = useDispatch();

  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar, lead, className,showLead, showMailForm, setShowMailForm,  ...rest } = props;


  /**
   * manage tabs
   */
   function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={0}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /**
   * status change
   */

  const [action, setAction] = useState(null);
  const [requestSubmit, setRequestSubmit] = useState(false);


  const onStatusChange = function (e, lead, action) {
    if (action === leadConstants.LEAD_ACTION_SET_AS_CHECKED) {
      setAction(action);
      setRequestSubmit(true);
    } else {
      dispatch(leadActions.doAction(lead.id, action));
    }
  }

  const onSubmit = (values, isValid) => {
    setRequestSubmit(false);
    if (!isValid) {
      props.enqueueSnackbar('Cannot send data. Check form errors!', { variant: "error" });
      return;
    }
    
    setAction(false);
    dispatch(leadActions.doAction(values.id, action || leadConstants.LEAD_ACTION_SAVE, values));
    setFormEdit(false);

  }

  const handleRequestSubmit = () => {
    setRequestSubmit(true);
  }

  const [formIsValid, setFormIsValid] = useState(false);

  const onFormValidation = (isValid) => {
    setFormIsValid(isValid);
  }

  const [formEdit, setFormEdit] = useState(false);
  

  useEffect(()=> {
    if (lead && lead.status === leadConstants.LEAD_STATUS_NEW && !formIsValid) {
      setFormEdit(true);
    }
  }, [formIsValid, lead]);

 

  const editClick = () => {
    setFormEdit(true);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  const cancelClick = () => {
    setFormEdit(false);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }



  return (
    <Fragment>
      <LeadActions setShowMailForm={setShowMailForm} lead={lead} onStatusChange={onStatusChange} />
      <Grid
        spacing={1}
          container
          justify="space-between"
        >
        
        <Grid item xs={12} sm={formEdit && 8}> 
        {showLead() ? (
          <Fragment>
            <Card {...rest} className={clsx(classes.root, className)}>
              <CardContent >
                <div {...rest} className={clsx(classes.root, className)} >
                  <div className={classes.row}>
                    <Typography >ID: #{lead ? lead.id : ""}</Typography>
                    <span className={classes.spacer} />
                    {!formEdit &&(<Button onClick={(e) => editClick()}>EDIT</Button>)}
                  </div>
                  
                </div>
                <LeadCard values={lead}></LeadCard> 
              </CardContent>
            </Card>
            <Tabs value={value} onChange={handleChange} aria-label="tabs">
                <Tab label="Emails" />
                <Tab label="Notes" />
                <Tab label="Documents" />
                <Tab label="Activity" />
              </Tabs>
              <TabPanel value={value} index={0}>
                <MessageBox lead={lead}  showMailForm={showMailForm} setShowMailForm={setShowMailForm}/>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <LeadNotes lead={lead} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <LeadDocuments lead={lead} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <LeadAudit data={lead && lead.audit} />
              </TabPanel>
          
        </Fragment>
        ) : (<MessageBox lead={lead}  showMailForm={showMailForm} setShowMailForm={setShowMailForm}/>)}
        </Grid>
        {(formEdit) && (
          <Grid item xs={12} sm={4} > 
            <Card {...rest} className={clsx(classes.root, className)}>
              <CardContent >
                <div   {...rest} className={clsx(classes.root, className)} >
                  <div className={classes.row}>
                    <Typography ></Typography>
                    <span className={classes.spacer} />
                    <Fragment>
                        <Button onClick={handleRequestSubmit}>SAVE</Button>
                        <Button onClick={cancelClick}>CLOSE</Button>
                    </Fragment>
                  </div>
                </div>
                <LeadForm 
                    values={lead} 
                    onSubmit={onSubmit} 
                    onFormValidation={onFormValidation}
                    requestSubmit={requestSubmit}/>
                  
              </CardContent>
            </Card>
          </Grid> 
        )}     
      </Grid>
    </Fragment>
  );
};

LeadBox.propTypes = {
  className: PropTypes.string
};

export default withSnackbar(LeadBox);

