import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import { IconButton, InputAdornment } from "@material-ui/core";

const styles = {
  root: {
    background: "white"
  },
  input: {
    background: "white",
    "&:hover" : {
      background: "white"
    },
    "&:visited" : {
      background: "white"
    },
    "&:focus" : {
      background: "white"
    },
    "&:blur" : {
      background: "red"
    },
    "&:active" : {
      background: "white"
    }
  }
};

function ZnPasswordField(props) {
  
  const { classes } = props;

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const inputProps = () => {

    if (props.readOnly) {
      return {
        readOnly: true,
        className: classes.input,
        disableUnderline: true,
      }
    }

    return {
      readOnly: false,
      className: classes.input,
      endAdornment: <InputAdornment position="start">
      <IconButton edge="end" color="primary" 
      aria-label="toggle password visibility"
      onClick={handleClickShowPassword}
      onMouseDown={handleMouseDownPassword}>
        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </IconButton>
    </InputAdornment> 
    }
  }

  return (
    <Fragment>
    <TextField
      type={showPassword ? "text" : "password"}
      onKeyPress={props.onKeyPress}
      name={props.name}
      label={props.label}
      value={props.value}
      margin="normal"
      className={classes.root}
      InputProps={inputProps()}
      variant={ (props.readOnly) ? "filled":  "outlined"}
      fullWidth
      onChange={props.onChange}
      error={props.error}
      helperText={props.helperText}
      multiline={props.multiline}
      rows={props.rows}
    
    />
    
    </Fragment>

    



  );
}

ZnPasswordField.defaultProps = {
  name: "",
  label: "",
  value: "",
  readOnly: false,
}

ZnPasswordField.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ZnPasswordField);


