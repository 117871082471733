import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});




export default function DenseTable({name, data}) {
  const classes = useStyles();

  const getData = ()  => {
    
    if (data ) {
      return data;
    }
    return [];

  }

  
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>{name}</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">Checkin</TableCell>
            <TableCell align="right">Processed</TableCell>
            <TableCell align="right">Converted</TableCell>
            <TableCell align="right">Converted %</TableCell>
            <TableCell align="right">Checkin time</TableCell>
            <TableCell align="right">Reply time</TableCell>
            <TableCell align="right">Convertion time</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {getData().map((row, key) => (
            <TableRow key={key}>
              <TableCell component="th" scope="row">{row.name} (#{row.id})</TableCell>
              <TableCell align="right">{row.total_received}</TableCell>
              <TableCell align="right">{row.total_checkin}</TableCell>
              <TableCell align="right">{row.total_processed}</TableCell>
              <TableCell align="right">{row.total_converted}</TableCell>
              <TableCell align="right">{row.perc_converted}</TableCell>
              <TableCell align="right">{row.checkin}</TableCell>
              <TableCell align="right">{row.reply}</TableCell>
              <TableCell align="right">{row.ok}</TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
