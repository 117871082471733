import { messageConstants, leadConstants } from '../_constants';
import { messageService } from '../_services';
import { alertActions } from './alert.actions';
import { alertConstants } from '../_constants';
import { leadActions } from './lead.actions';


export const messageActions = {
    save,
    fetchMail,
    getAll,
    getOne,
    getAllNewMessages,
    getAllByLeadId,
    _delete,
};


function _delete(id) {
    
    return dispatch => {
        dispatch(request());

        messageService._delete(id)
            .then(
                messages => dispatch(getAll()),
                error => dispatch(failure(error))
            );
    };

    function request() { 
        return { type: messageConstants.DELETE_REQUEST } 
    }
    function success(user) { 
        return { type: messageConstants.DELETE_SUCCESS, user } 
    }
    function failure(error) { 
        return { type: messageConstants.DELETE_FAILURE, id, error } 
    }
}


function save(message) {

    
}

function fetchMail() {
    return getAllNewMessages(true);
}

function getAllNewMessages(check) {
    return getAll([
        leadConstants.LEAD_STATUS_NEW_MESSAGE,
        leadConstants.LEAD_STATUS_NEW,
    ], '', check);
}

function getAllByLeadId(leadId) {
    return getAll('', [], '', leadId);
}

function getAll(sourceFormId = null, leadStatus = [], text, leadId = '', check, page = '', per_page = '' ) {

    return dispatch => {
        dispatch(request());

        messageService.getAll(sourceFormId, leadStatus, text, leadId, check, page, per_page)
            .then(
                messages => {
                    dispatch(success(messages.data, messages.meta));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { 
        return { type: messageConstants.GETALL_REQUEST } 
    }
    function success(messages, meta) { 
        return { type: messageConstants.GETALL_SUCCESS, messages, meta } 
    }
    function failure(error) { 
        return { type: messageConstants.GETALL_FAILURE, message: error.message };
    }

}

function getOne(id) {

    
    return dispatch => {
        dispatch(request());

        messageService.getById(id)
            .then(
                message => dispatch(success(message.data)),
                error => dispatch(failure(error))
            );
    };

    function request() { 
        return { type: messageConstants.GETONE_REQUEST } 
    }
    function success(message) { 
        return { type: messageConstants.GETONE_SUCCESS, message } 
    }
    function failure(error) { 
        return { type: messageConstants.GETONE_FAILURE, id, error } 
    }
}
