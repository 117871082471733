import { leadConstants } from '_constants';
import { authHeader } from '../helpers';
import handleResponse from './handleResponse';
import moment from 'moment';


export const leadService = {
    getAll,
    getById,
    update,
    doAction,
    getOverview,
};

function doAction(leadId, action, data = []) {

    if (action === leadConstants.LEAD_ACTION_ADD_DOCUMENT) {
        return kickDocument(leadId, action, data);
    }

    return kick({
        'action': action,
        'leadId': leadId,
        'data': data
    });
}

function kickDocument(leadId, action, data = []) {

    const formData = new FormData();
    formData.append('action', action);
    formData.append('leadId', leadId);
    //formData.append('documentId', data.data.documentId);
    formData.append('name', data.name);
    formData.append('file', data.file);
    formData.append('fileName', data.fileName);


    const requestOptions = {
        method: 'POST',
        headers: authHeader(false),
        body: formData,
        redirect: 'follow',
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/lead-kick`, requestOptions).then(handleResponse);
}


function kick(data) {


    delete data.data.messages;
    delete data.data.audit;
    delete data.data.created_at;
    delete data.data.deleted_at;
    // delete data.data.documents;
    delete data.data.notes;
    delete data.data.source;
    delete data.data.status;
    delete data.data.updated_at;
    // delete data.data.user;

    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/lead-kick`, requestOptions).then(handleResponse);;
}


let controllers = [];
let controllersOverview = [];


function getAll(departmentIds = [], userIds = [], status = [],

    leadSearchText = '',
    leadSearchEmail = '',
    leadSearchPhone = '',
    leadSearchSubject = '',
    leadSearchNotes = '',
    from = null, to = null, page = '', per_page = '', fetchMail = false) {

    if (from) {
        from = moment(from).format('YYYY-MM-DD');
    }

    if (to) {
        to = moment(to).format('YYYY-MM-DD');
    }

    if (controllers.length > 0) {
        controllers.pop().abort();
    }

    const controller = new AbortController();
    const { signal } = controller;
    controllers.push(controller);


    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        signal: signal
    };


    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/lead?from=${from}&to=${to}&sync=${fetchMail}&status=${status.join()}&departmentIds=${departmentIds.join()}&userIds=${userIds.join()}`
        + `&text=${leadSearchText}`
        + `&email=${leadSearchEmail}`
        + `&phone=${leadSearchPhone}`
        + `&subject=${leadSearchSubject}`
        + `&notes=${leadSearchNotes}`
        + `&page=${page}&per_page=${per_page}`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/lead/${id}`, requestOptions).then(handleResponse);
}

function update(lead) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(lead)
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/lead/${lead.id}`, requestOptions).then(handleResponse);;
}


function getOverview(departmentIds = [], userIds = [], status = [],

    leadSearchText = '',
    leadSearchEmail = '',
    leadSearchPhone = '',
    leadSearchSubject = '',
    leadSearchNotes = '',
    from = null, to = null) {

    if (from) {
        from = moment(from).format('YYYY-MM-DD');
    }

    if (to) {
        to = moment(to).format('YYYY-MM-DD');
    }

    if (controllersOverview.length > 0) {
        controllersOverview.pop().abort();
    }

    const controller = new AbortController();
    const { signal } = controller;
    controllersOverview.push(controller);


    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        signal: signal
    };



    //return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/lead-status?userId=${userId}&departmentId=${departmentId}`, requestOptions).then(handleResponse);

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/lead-status?`
        + `from=${from}`
        + `&to=${to}`
        + `&status=${status.join()}`
        + `&departmentIds=${departmentIds.join()}`
        + `&userIds=${userIds.join()}`
        + `&text=${leadSearchText}`
        + `&email=${leadSearchEmail}`
        + `&phone=${leadSearchPhone}`
        + `&subject=${leadSearchSubject}`
        + `&notes=${leadSearchNotes}`
        , requestOptions).then(handleResponse);
}