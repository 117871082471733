import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';
import { Badge, AppBar, IconButton, Toolbar as MuiToolbar, Grid, useMediaQuery } from '@material-ui/core';
import { SearchInput } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import FilterIcon from '@material-ui/icons/FilterList';
import LeadsErp from 'views/Dashboard/components/LeadsErp';
import LeadsOk from 'views/Dashboard/components/LeadsOk';

import NewMessages from 'views/Dashboard/components/NewMessages';
import LeadsOpened from 'views/Dashboard/components/LeadsOpened';
import { configActions } from '_actions/config.actions';
import SidebarRight from './SidebarRight';
import theme from 'theme';


const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 35,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge)

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  appBar: {
    zIndex: 1
  }
}));

const LeadsToolbar = props => {



  const leadSearchStatuses = useSelector(state => state.config.leadSearchStatuses);

  const { className, setFilter, ...rest } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    text: "",
  });

  const [typing, setTyping] = useState(false);

  const dispatch = useDispatch();

  const handleTextChange = event => {

    if (typing !== false) {
      clearTimeout(typing);
      setTyping(false);
    }

    const st = {
      ...formState,
      text: event.target.value
    }

    setFormState(st);


    setTyping(setTimeout(function () {
      dispatch(configActions.setLeadSearchText(st.text));
    }, 400));


  };


  // RIGHT SIDEBAR
  const [openSidebarRight, setOpenSidebarRight] = useState(false);

  const handleSidebarRightOpen = () => {
    setOpenSidebarRight(true);
  };

  const handleSidebarRightClose = () => {
    setOpenSidebarRight(false);
  };

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });



  return (
    <Fragment>
      <SidebarRight
        onClick={handleSidebarRightClose}
        onClose={handleSidebarRightClose}
        open={openSidebarRight}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <AppBar className={classes.appBar} color="default" position="sticky">

        <MuiToolbar>
          <SearchInput
            className={classes.searchInput}
            placeholder="Search"
            onChange={handleTextChange}
            name="search"
          />
          <span className={classes.spacer} />
          <StyledBadge badgeContent={leadSearchStatuses.length} color="primary">
            <IconButton
              color="inherit"
              onClick={handleSidebarRightOpen}
            ><FilterIcon fontSize="default" />
            </IconButton>
          </StyledBadge>
        </MuiToolbar>
        <div style={{ padding: '5px' }}>
          <Grid container spacing={1}  >
            <Grid item xs={3} ><NewMessages /></Grid>
            <Grid item xs={3} ><LeadsOpened /></Grid>
            <Grid item xs={3} ><LeadsOk /></Grid>
            <Grid item xs={3} ><LeadsErp /></Grid>
          </Grid>
        </div>
      </AppBar>
    </Fragment>
  );
};

LeadsToolbar.propTypes = {
  className: PropTypes.string
};

export default LeadsToolbar;
