/* eslint-disable no-use-before-define */
import React, { Fragment } from 'react';
import ZnSelect from './ZnSelect';
import { useState } from 'react';
import { useEffect } from 'react';

export {
  ZnLanguageSelect as default,
  ZnLanguageValue,

}

const  ZnLanguageSelect = (props) => {

  const { id, label, onChange, onKeyPress,  value, error, helperText } = props;

  return (
    <ZnSelect
      id={id}
      label={label}
      onChange={onChange}
      onKeyPress={onKeyPress}
      value={value}
      error={error}
      helperText={helperText}
      items={countries()}
    />
  );

}

const ZnLanguageValue = (props) => {

  const {value} = props;

  const [label, setLabel] = useState(null);

  useEffect(() => {
    const code = countries().filter(item => { return item && item.code === value }).shift();
    if (code && code.label) setLabel(code.label);
  }, [value]);

  return (
    <Fragment>{label}</Fragment>
  );
}

const countries = () => [

  { code: 'EN', label: 'English' },
  { code: 'IT', label: 'Italian' },
  { code: 'FR', label: 'French' },
  { code: 'ES', label: 'Spanish' },
  { code: 'DE', label: 'Deutsche' },
 
];
