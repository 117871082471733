import { procedureConstants } from '../_constants';
import { procedureService } from '../_services';
import { alertActions } from './alert.actions';



export const procedureActions = {
    remove,
    save,
    getAll,
    getOne,
    download,
    _delete,
};


function _delete(id) {
    
    return dispatch => {
        dispatch(request());

        procedureService._delete(id)
            .then(
                procedures => dispatch(getAll()),
                error => dispatch(failure(error))
            );
    };

    function request() { 
        return { type: procedureConstants.DELETE_REQUEST } 
    }
    function success(user) { 
        return { type: procedureConstants.DELETE_SUCCESS, user } 
    }
    function failure(error) { 
        return { type: procedureConstants.DELETE_FAILURE, id, error } 
    }
}

function download(path, fileName) {

    
    procedureService.download(path + fileName).then(
        result => {

            
            result.blob().then(blob=> {

                // var blob = new Blob([], {type: 'application/pdf'});
            
            var downloadUrl = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = downloadUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();


            });
            


            

        }
    );



}





function save(procedure) {

    return dispatch => {
        dispatch(request());
        procedureService.doAction(procedure.id, procedureConstants.ACTION_SAVE, procedure)
            .then(
                result => {
                    dispatch(alertActions.success('Model Saved'));
                    dispatch(success(result.data));
                },
                error => {
                    dispatch(failure(error));
                }
            );


    };

    function request() {
        return { type: procedureConstants.KICK_REQUEST };
    }
    function success(procedure) {
        return { type: procedureConstants.KICK_SUCCESS, procedure };
    }
    function failure(error) {
        return { type: procedureConstants.KICK_FAILURE, error };
    }
}

function getAll() {
    return (dispatch, getState) => {

        const {departmentId} = getState().config;
        dispatch(request());

        procedureService.getAll([departmentId])
            .then(
                procedures => dispatch(success(procedures.data)),
                error => dispatch(failure(error))
            );
    };

    function request() {
        return { type: procedureConstants.GETALL_REQUEST }
    }
    function success(procedures) {
        return { type: procedureConstants.GETALL_SUCCESS, procedures }
    }
    function failure(error) {
        return { type: procedureConstants.GETALL_FAILURE, error }
    }
}

function getOne(id) {


    return dispatch => {
        dispatch(request());

        procedureService.getById(id)
            .then(
                procedure => dispatch(success(procedure.data)),
                error => dispatch(failure(error))
            );
    };

    function request() {
        return { type: procedureConstants.GETONE_REQUEST }
    }
    function success(procedure) {
        return { type: procedureConstants.GETONE_SUCCESS, procedure }
    }
    function failure(error) {
        return { type: procedureConstants.GETONE_FAILURE, id, error }
    }
}

function remove(modelName, messageId, fileName) {
    return dispatch => {
        dispatch(request());
        procedureService.remove(modelName, messageId, fileName)
            .then(
                procedures => {
                    dispatch(alertActions.success('File removed'));
                    dispatch(success(procedures));
                },
                error => {
                    dispatch(failure(error));
                }
            );


    };

    function request() {
        return { type: procedureConstants.REMOVE_REQUEST };
    }
    function success(procedures) {
        return { type: procedureConstants.REMOVE_SUCCESS, procedures };
    }
    function failure(error) {
        return { type: procedureConstants.REMOVE_FAILURE, error };
    }
}



