import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button
} from '@material-ui/core';
import { ZnDepartmentValue } from 'common/znFields/ZnDepartmentSelect';
import { ZnLanguageValue } from 'common/znFields/ZnLanguageSelect';
import { ZnButton } from 'common/znFields/ZnButton';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    //minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const MessageModelsTable = props => {
  
  const { className, messageModels, handleMessageModelClick, handleMessageModelClickDelete, ...rest } = props;

  const classes = useStyles();

  const [selectedMessageModels, setSelectedMessageModels] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);

  const handleSelectAll = event => {

    let selectedMessageModels;

    if (event.target.checked) {
      selectedMessageModels = messageModels.map(messageModel => messageModel.id);
    } else {
      selectedMessageModels = [];
    }

    setSelectedMessageModels(selectedMessageModels);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedMessageModels.indexOf(id);
    let newSelectedMessageModels = [];

    if (selectedIndex === -1) {
      newSelectedMessageModels = newSelectedMessageModels.concat(selectedMessageModels, id);
    } else if (selectedIndex === 0) {
      newSelectedMessageModels = newSelectedMessageModels.concat(selectedMessageModels.slice(1));
    } else if (selectedIndex === selectedMessageModels.length - 1) {
      newSelectedMessageModels = newSelectedMessageModels.concat(selectedMessageModels.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedMessageModels = newSelectedMessageModels.concat(
        selectedMessageModels.slice(0, selectedIndex),
        selectedMessageModels.slice(selectedIndex + 1)
      );
    }

    setSelectedMessageModels(newSelectedMessageModels);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const handleClickOpen = (id) => {
    handleMessageModelClick(id);
  };
  const handleClickDelete = (id) => {
    handleMessageModelClickDelete(id);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  
                  {/* <TableCell>Name</TableCell> */}
                  <TableCell>Subject</TableCell>
                  <TableCell>Language</TableCell>
                  <TableCell>Department</TableCell>
                  {/* <TableCell>Assigned to</TableCell> */}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {messageModels.slice(page*rowsPerPage, (page*rowsPerPage)+rowsPerPage).map(messageModel => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={messageModel.id}
                    selected={selectedMessageModels.indexOf(messageModel.id) !== -1}
                  >
                    <TableCell>{messageModel.subject}</TableCell>
                    <TableCell><ZnLanguageValue value={messageModel.language}></ZnLanguageValue></TableCell>
                    <TableCell><ZnDepartmentValue value={messageModel.department}></ZnDepartmentValue></TableCell>
                    <TableCell>
                      <Button 
                        onClick={e => handleClickOpen(messageModel.id)}
                         color="primary"
                        >Edit</Button>
                        <ZnButton
                        onClick={e => handleClickDelete(messageModel.id)}
                        color="primary"
                        confirm
                        confirmTitle="Do you really want to delete it ?"
                        confirmText={`Please confirm you want to delete the mesage model \n ${messageModel.subject}.`}
                        >Delete</ZnButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={messageModels.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]}
        />
      </CardActions>
    </Card>
  );
};

MessageModelsTable.propTypes = {
  className: PropTypes.string,
};

export default MessageModelsTable;
