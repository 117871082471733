import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ZnTextField from 'common/znFields/ZnTextField';
import { useState } from 'react';
import { ZnDepartmentSelect } from 'common/znFields/ZnDepartmentSelect';
import { messageModelActions } from '_actions';
import { useDispatch, useSelector } from 'react-redux';
import validate from 'validate.js';
import ZnDraft2Area from 'common/znFields/ZnDraft2Area';
import ZnLanguageSelect from 'common/znFields/ZnLanguageSelect';
import { attachmentActions } from '_actions/attachment.actions';
import ZnAttachmentsAreaReadOnly from 'common/znFields/ZnAttachmentsAreaReadOnly';
import { ZnAttachmentModelSelect } from 'common/znFields/ZnAttachmentModelSelect';

const schema = {
  // name: {
  //   presence: { allowEmpty: false, message: 'is required' },
  //   length: {
  //     maximum: 200
  //   }
  // },
  subject: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 200
    }
  },
  language: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 5
    }
  },
};

let messageId = Date.now();

const defaultFormState = {
  loaded: false,
  isValid: false,
  values: {
    name: '',
    subject: '',
    department: '',
    language: '',
    body: '',
    messageId: messageId,
    attachments: [],
    attachmentModels: [],
  },
  touched: {},
  errors: {}
}

const MessageModelDetail = props => {

  const { open, setOpen, messageModel, handleMessageModelClickDelete } = props;

  const dispatch = useDispatch();

  const handleChange = (event) => {
    event.persist();
    handleFieldChange(event.target.name, event.target.type === 'checkbox'
      ? event.target.checked
      : event.target.value);
  };

  const handleBodyChange = (value) => {
    handleFieldChange('body', value);
  }

  const departmentHandleChange = (event, values) => {
    handleFieldChange('department', values ? values.code : "");
  };

  const attachmentModelsHandleChange = (event) => {
    console.log("attachmentModelsHandleChange", event)
    handleFieldChange('attachmentModels', event.target.value ?? []);
  };

  const languageHandleChange = (event, values) => {
    handleFieldChange('language', values ? values.code : "");
  };

  const handleFieldChange = (fieldName, value) => {
    console.log('handleFieldChange')

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [fieldName]: value,
        messageId: messageId,
      },
      touched: {
        ...formState.touched,
        [fieldName]: true
      }
    }));
  };

  const handleSubmit = event => {

    if (!formState.isValid) return;

    if (event.type === 'keypress' && event.key !== 'Enter') {
      return;
    }
    event.preventDefault();
    
    dispatch(messageModelActions.save({ ...formState.values, name: formState.values.subject }));

  };


  const doingAction = useSelector(state => state.messageModels.doingAction);
  const successAction = useSelector(state => state.messageModels.successAction);
  const actionErrorMsg = useSelector(state => state.messageModels.actionErrorMsg);

  const [formState, setFormState] = useState(defaultFormState);


  useEffect(() => {
    if (successAction === true) handleClose();
  }, [successAction]);

  const handleClose = () => {
    console.log('close')
    if (!doingAction) {
      setOpen(false);
      setFormState(
        defaultFormState
      );
    }
  };

  const formValidation = () => {
    console.log('validation')

    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }

  useEffect(() => {
    formValidation();
  }, [formState.values]);


  const [tmpId, setTmpId] = useState(Date.now());

  useEffect(() => {
    console.log('mesodel / open')

    if (messageModel) {
      setFormState({
        ...formState,
        values: messageModel,
      });
    } else {
      setFormState(defaultFormState);
    }

    if (messageModel && messageModel.id) {
      setTmpId(messageModel.id);
    } else {
      setTmpId(Date.now());
    }

  }, [messageModel, open]);


  useEffect(() => {
    console.log('acrtionError')

    setFormState(formState => ({
      ...formState,
      isValid: actionErrorMsg ? false : true,
      errors: actionErrorMsg ? actionErrorMsg.errors : {},
    }));
  }, [actionErrorMsg]);



  const hasError = (field) => {
    return formState.errors[field] ? true : false;
  }

  const getError = (field) => {
    return formState.errors[field] && formState.errors[field][0];
  }


  // ATTACHMENTS

  // const messageModelAttachment = useSelector(state => state.attachments.messageModel);
  // useEffect(() => {

  //   if (!(messageModelAttachment instanceof Object)) return;

  //   const attachments = formState.values.attachments;

  //   attachments.push({
  //     id: messageModelAttachment.id,
  //     name: messageModelAttachment.name,
  //   });
  //   handleFieldChange('attachments', attachments);

  // }, [messageModelAttachment]);

  const attachments = useSelector(state => state.attachments);


  const attachmentsAreaHadleDownload = (attachementId) => {
    attachmentActions.download(attachementId);
  }

  return (
    <div>

      <Dialog
        // fullScreen
        fullWidth={true}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {messageModel ? "Message Model detail" : "New Message Model"}
        </DialogTitle>
        <DialogContent>
          <ZnTextField
            onKeyPress={handleSubmit}
            name="subject"
            label="Subject"
            onChange={handleChange}
            value={formState.values.subject}
            error={hasError('subject')}
            helperText={
              getError('subject')
            }
            margin="normal"
            variant="outlined"
          />
          <ZnLanguageSelect
            id="language"
            label="language"
            onChange={languageHandleChange}
            onKeyPress={handleSubmit}
            value={formState.values.language}
            error={hasError('language')}
            helperText={
              getError('language')
            }
          />
          <ZnDepartmentSelect
            id="department"
            label="Department"
            onChange={departmentHandleChange}
            onKeyPress={handleSubmit}
            value={formState.values.department}
            error={hasError('department')}
            helperText={
              getError('department')
            }
          />
          <ZnAttachmentModelSelect
            id="attachments-model"
            label="Attachments"
            onChange={attachmentModelsHandleChange}
            onKeyPress={handleSubmit}
            value={formState.values.attachmentModels}
            error={hasError('attachmentModels')}
            helperText={
              getError('attachmentModels')
            }
          ></ZnAttachmentModelSelect>

          <ZnAttachmentsAreaReadOnly
            tmpId={tmpId}
            selectedAttachments={formState.values.attachments}
            attachments={attachments}
            // handleRemove={attachmentsAreaHadleRemove}
            // handleAttach={attachmentsAreaHadleAttach}
            // handleSelect={attachmentsAreaHadleSelect}
            handleDownload={attachmentsAreaHadleDownload}
          ></ZnAttachmentsAreaReadOnly>

          <ZnDraft2Area
            onChange={handleBodyChange}
            value={formState.values.body}
          ></ZnDraft2Area>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

MessageModelDetail.defaultProps = {
  open: false,
  messageModel: {},
}
export default MessageModelDetail;
