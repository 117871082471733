import React from 'react';
import { 
  ProceduresDetail, 
  ProceduresList, 
  ProceduresEdit} from './components';

import {
  useParams
} from 'react-router'

const Procedure = () => {



  return         <ProceduresList />;
}

export default Procedure;
