import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import TableList from 'common/TableList';
import { TablePagination } from '@material-ui/core';
import { Edit, Toolbar } from '.';
import Import from './Import';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    backgroundColor: 'white'
  },
  content: {
    marginTop: theme.spacing(0)
  }
}));

const List = ({ mapper, icon, actions, reducer }) => {
  const data = reducer
  const classes = useStyles()
  const dispatch = useDispatch()

  // filer
  const [filter, setFilter] = useState({
    text: "",
    tags: [],
    added_at_from: "",
    added_at_to: ""
  })



  // sort orderBY
  const [orderBy, setOrderBy] = useState()
  const [orderDirection, setOrderDirection] = useState('asc')
  const handleClickOrderBy = (orderBy, orderDirection) => {
    setOrderBy(orderBy)
    setOrderDirection(orderDirection)
  }

  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [page, setPage] = useState(0)
  const handlePageChange = (event, page) => {
    setPage(page)
  }
  const handleRowsPerPageChange = event => {
    setPage(0)
    setRowsPerPage(event.target.value);
  }

  // load data
  useEffect(() => {
    console.log('FILTERS', filter)
    dispatch(actions.getAll(filter.text, filter.tags, filter.added_at_from, filter.added_at_to, page + 1, rowsPerPage, orderBy, orderDirection));
  }, [page, rowsPerPage, filter, orderBy, orderDirection]);

  // modal
  const [open, setOpen] = React.useState(false);
  const [itemId, setItemId] = React.useState(null);
  const [item, setItem] = React.useState(null);


  useEffect(() => {
    const key = data.items.findIndex(item => item.id === itemId);
    if (key in data.items) {
      setItem(data.items[key]);
    } else {
      setItem(null);
    }
  }, [itemId, data]);

  const handleItemClick = newId => {
    setItemId(newId);
    setOpen(true);
  };

  const hadleFilterChange = value => {
    console.log("VALUE", value);
    setFilter({
      text: value.text ?? '',
      tags: value.tags ?? [],
      added_at_from: (value.addedAt && value.addedAt.startDate) ? moment(value.addedAt.startDate).format('YYYY-MM-DD') : '',
      added_at_to: (value.addedAt && value.addedAt.endDate) ? moment(value.addedAt.endDate).format('YYYY-MM-DD') :'',

    });
    setPage(0);
  };


  const [importOpen, setImportOpen] = React.useState(false);

  const handleImport = () => {
    setImportOpen(true);
  }


  const handleExport = () => {
    dispatch(actions._export(filter.text, filter.tags, filter.added_at_from, filter.added_at_to, page + 1, rowsPerPage, orderBy, orderDirection));

  }

  const handleItemClickDelete = (id) => {
    dispatch(actions._delete(id, filter.text));
  }

  // renderer
  return (
    <div className={classes.root}>
      <Toolbar
        setFilter={hadleFilterChange}
        handleClick={handleItemClick}

        handleImport={handleImport}
        handleExport={handleExport}
        reducer={reducer}
      ></Toolbar>

      <div className={classes.content}>
        <TableList
          icon={icon}
          items={data.items}
          itemMapper={mapper}
          onClick={handleItemClick}
          onClickDelete={handleItemClickDelete}
          onClickOrderBy={handleClickOrderBy}
        />
        {/* <Edit
          actions={actions}
          setOpen={setOpen}
          open={open}
          item={item}
          reducer={reducer}
        /> */}
        <Import
          actions={actions}
          setOpen={setImportOpen}
          open={importOpen}
          reducer={reducer}

        />
      </div>

      {data.items && data.items.length > 0 && (
        <TablePagination
          component="div"
          count={data.total}
          onPageChange={handlePageChange}
          // onChangePage={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          // onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]}
        />
      )}

<Edit
        actions={actions}
        setOpen={setOpen}
        open={open}
        item={item}
        reducer={reducer}
      />
    </div>
  );
};

export default List;
