import React, { useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { newsletterActions } from '_actions';
import { newsletterConstants } from '_constants';
import { Backdrop,  Chip, CircularProgress, TablePagination, Typography } from '@material-ui/core';
import {default as ItemList} from 'common/NewsletterList';
import SendIcon from '@material-ui/icons/Send';
import { configActions } from '_actions/config.actions';




const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    backgroundColor: "white",

  },
  content: {
    marginTop: theme.spacing(0)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    color: '#fff',
  },
}));


const NewsletterList = () => {

  const classes = useStyles();

  const newsletters = useSelector(state => state.newsletters);

  const loading = useSelector(state => state.newsletters.loading);

  const dispatch = useDispatch();

  
  const newsletterReload = useSelector(state => state.config.newsletterReload);


  const page = useSelector(state => state.config.newsletterSearchPage);
  const rowsPerPage = useSelector(state => state.config.newsletterSearchRowsPerPage);

  const handlePageChange = (event, page) => {
    dispatch(configActions.setNewsletterPage(page));
  };

  const handleRowsPerPageChange = event => {
    dispatch(configActions.setNewsletterRowsPerPage(event.target.value));
  };


  // search box
  useEffect(() => {
    dispatch(configActions.setActiveSearchBox('newsletter'));
  }, []);
  useEffect(() => {
    return () => {
      dispatch(configActions.setActiveSearchBox(null));
    };
  }, []);

  useEffect(() => {
    dispatch(newsletterActions.getAll( page + 1, rowsPerPage,));
  }, [page, rowsPerPage]);

  useEffect(() => {
    if(!newsletterReload) return;
    dispatch(newsletterActions.getAll( page + 1, rowsPerPage,));
  }, [ newsletterReload]);




  const statusMapper = (status) => {
    const sts = newsletterConstants.LEAD_STATUS_MESSAGES[status].process_status;
    const color = newsletterConstants.LEAD_STATUS_MESSAGES[status].process_color;
    
    return (<Chip variant="default" size="small" style={{
      backgroundColor: color,
      color: '#ffffff'
    }} label={sts} />);
  }

  const newsletterMapper = (newsletter) => {
    

    
        return {
          id: newsletter.id,
          icon: <SendIcon fontSize="default" />,
          action: null,
          primary:   <Typography component="h5" variant="h5" >{newsletter.email}</Typography>,
          secondary: <Fragment>{newsletter.name}<br/>{"#" + newsletter.id}</Fragment>,
        };
     
   
    
  }

  const getOpacity = () => {
    return loading ? '0.5' : '100';
  }

  return (
    <div className={classes.root}>
      <div className={classes.content} style={{opacity: getOpacity()}} >
        <ItemList icon={(<SendIcon />)} items={newsletters.items} itemMapper={newsletterMapper}  actionPath="/newsletters/" />
      </div>
      <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
      </Backdrop>
      { (newsletters.items && newsletters.items.length > 0) && (
        <TablePagination
          component="div"
          count={newsletters.total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]}
        />
      )}
    </div>
  );
};

export default NewsletterList;



