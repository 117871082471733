import React from 'react';
import {  useSelector } from 'react-redux';
import { leadConstants } from '_constants';
import SummaryBox from '../SummaryBox';
import NewReleasesOutlinedIcon from '@material-ui/icons/NewReleasesOutlined';


const NewMessages = props => {
  
  const total = useSelector(state => state.leads.overview.new);

  return (
    <SummaryBox 
    icon={<NewReleasesOutlinedIcon fontSize="large" />}
      title="New"
      subtitle={leadConstants.LEAD_STATUS_MESSAGES.LEAD_STATUS_NEW.process_action}
      total={total}
      color={leadConstants.LEAD_STATUS_COLORS.NEW}
      statuses={[
        leadConstants.LEAD_STATUS_NEW_MESSAGE,
        leadConstants.LEAD_STATUS_NEW,
        leadConstants.LEAD_STATUS_CHECKED,
      ]}
    />
  );
};

export default NewMessages;
