import React, {  Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowForwardIos';

import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  
  Grid,
  Hidden,
  
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';
import { history } from 'helpers';

import { ZnDepartmentValue } from 'common/znFields/ZnDepartmentSelect';
import { ZnUserValue } from 'common/znFields/ZnUserSelect';




const useStyles = makeStyles(theme => ({
  root: {},
  listItem : {
      fontWeight:"strong",
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));




const NewslettersTable = ({ className, newsletters }) => {


  const classes = useStyles();


  return (
    <Fragment>
      <Hidden smDown>
        <Table>
          <TableHead>
            <TableRow >
              <TableCell colSpan="1">ID</TableCell>
              <TableCell colSpan="2">Newsletter</TableCell>
              <TableCell colSpan="1" >Department</TableCell>
              <TableCell colSpan="1" >Assigned to</TableCell>
              <TableCell colSpan="1" align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {newsletters.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map(newsletter => ( */}
            {newsletters.map(newsletter => (
              <TableRow
                style={{ backgroundColor: "white" }}
                className={classes.tableRow}
                hover
                key={newsletter.id}
                // selected={selectedNewsletters.indexOf(newsletter.id) !== -1}
                onClick={() => history.push("/newsletters/" + newsletter.id)}
              >
                <TableCell >
                  #{newsletter.id}
                </TableCell>
                <TableCell colSpan="2">

                  <Grid container justify="space-between">
                    <Typography variant="h5" align="left" >
                      {newsletter.email}
                    </Typography>

                  </Grid>

                  <Grid container justify="space-between">
                    <Typography align="left">
                      {newsletter.name}
                    </Typography>

                  </Grid>

                  <Grid container justify="space-between">

                    <Typography align="left">
                      {newsletter.phone}
                    </Typography>

                  </Grid>

                </TableCell>

                <TableCell >
                  <ZnDepartmentValue value={newsletter.department}></ZnDepartmentValue>
                </TableCell>
                <TableCell >
                  <ZnUserValue value={newsletter.user}></ZnUserValue>
                </TableCell>

                <TableCell >
                  <Grid container justify="flex-end">
                    <Typography align="right">
                      Updated {moment(newsletter.updated_at).fromNow()}
                    </Typography>
                  </Grid>
                  <Grid container justify="flex-end">
                    <Typography align="left">&nbsp;</Typography>
                  </Grid>
                  <Grid container justify="flex-end">
                    
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Hidden>
      <Hidden mdUp>

      <List className={classes.listRoot} >
          {newsletters.map(item => (
            <Fragment key={item.id}>
              
              <ListItem  >
                <ListItemAvatar>
                  <Avatar aria-label="recipe" className={classes.avatar}
                    alt={item.email}>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                classes={{root: classes.listItem}}
                
                  primary={item.name + " <" + item.email + ">"}
                  
                  secondary={(
                    <Fragment>
                      <ZnDepartmentValue value={item.department} />
                      &nbsp;-&nbsp;
                      <ZnUserValue value={item.user} />
                      <br/>
                      {"#" + item.id + " - " + moment(item.updated_at).fromNow()}
                    </Fragment>)}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="show"
                    onClick={() => history.push("/newsletters/" + item.id)}
                  >
                    <ArrowRightIcon ></ArrowRightIcon>
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider></Divider>
            </Fragment>
          ))}
        </List>

            


      </Hidden>
    </Fragment>
  );
};

NewslettersTable.propTypes = {
  className: PropTypes.string,
};

export default NewslettersTable;
