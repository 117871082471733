import React, { useEffect } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useState } from 'react';
import { stateFromHTML } from 'draft-js-import-html';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


const ZnDraftArea = (props) => {

  const { onChange } = props;

  const [editorValue, setEditorValue] = useState();

  const [editorState, setEditorState] = useState(
    true ? EditorState.createWithContent(stateFromHTML("<p>pippolo puzzolo</p>")) : EditorState.createEmpty());

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setEditorValue(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  }


  useEffect(() => {
    onChange(editorValue);
  }, [editorValue]);

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
    />
  );
}

ZnDraftArea.defaultProps = {
  value: "",
  onChange: (e, v) => { },
}

export default ZnDraftArea;


