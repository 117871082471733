import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { SearchInput } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const ProceduresToolbar = props => {
  const { className, setFilter, handleProcedureClick, ...rest } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    text: "",
  });

  useEffect(() =>{
    setFilter(formState);

  });

  const handleTextChange = event => {
    setFormState({
      ...formState,
      text: event.target.value
    });
    setFilter(formState);
  };

 
  const handleAddProcedureClick = () => {
    handleProcedureClick(null);
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search"
          onChange={handleTextChange}
          name="search"
        />
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddProcedureClick}
        >
          New
        </Button>
      </div>
    </div>
  );
};

ProceduresToolbar.propTypes = {
  className: PropTypes.string
};

export default ProceduresToolbar;
