import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const styles = {
  root: {
    background: "white"
  },
  input: {
    background: "white",
    "&:hover" : {
      background: "white"
    },
    "&:visited" : {
      background: "white"
    },
    "&:focus" : {
      background: "white"
    },
    "&:blur" : {
      background: "red"
    },
    "&:active" : {
      background: "white"
    }
  }
};

function ZnTextField(props) {
  
  const { classes } = props;

  const inputProps = () => {

    if (props.readOnly) {
      return {
        readOnly: true,
        className: classes.input,
        disableUnderline: true,
      }
    }

    return {
      readOnly: false,
      className: classes.input,
    }
  }

  return (
    <TextField
      onKeyPress={props.onKeyPress}
      name={props.name}
      label={props.label}
      value={props.value}
      type={props.type}
      margin="normal"
      className={classes.root}
      InputProps={inputProps()}
      variant={ (props.readOnly) ? "filled":  "outlined"}
      fullWidth
      onChange={props.onChange}
      error={props.error}
      helperText={props.helperText}
      multiline={props.multiline}
      rows={props.rows}
      
    />

    



  );
}

ZnTextField.defaultProps = {
  name: "",
  label: "",
  value: "",
  readOnly: false,
}

ZnTextField.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ZnTextField);


