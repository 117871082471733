import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import List from './components/List';
import Detail from './components/Detail';

import { PeopleIcon } from 'icons';
import { customerActions as actions } from '_actions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const listMapper = item => {
  return {
    id: item.id,
    action: null,
    primary: item.name + ' ' + item.surname + ' (' + item.phone + ')',
    secondary: item.email
  };
};

const icon = <PeopleIcon />;

const View = () => {
  const reducer = useSelector(state => state.customers);
  const {id} = useParams();
  
  return id ? (
    <Detail id={id} mapper={listMapper} icon={icon} actions={actions} reducer={reducer}></Detail>
  ) : (
    <List mapper={listMapper} icon={icon} actions={actions} reducer={reducer} />
  );
};

export default View;
