/* eslint-disable no-use-before-define */
import React, { Fragment } from 'react';
import { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

export {
  ZnButton,
}

const ZnButton = (props) => {

  const {
    children,
    onClick,
    color,
    confirm = false,
    confirmTitle,
    confirmText

  } = props;

  const [modalOn, setModalOn] = useState(false);


  const handleOnClick = () => {
    if (confirm) {
      setModalOn(true);
    } else {
      onClick && onClick()
    }
  }

  const handleNo = () => {
    setModalOn(false);
  }
  const handleYes = () => {
    setModalOn(false);
    onClick()

  }

  return (
    <Fragment>
      <Button
        onClick={handleOnClick}
        color={color}
      >{children}</Button>
      <Dialog
        open={modalOn}
        onClose={handleNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{confirmTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} color="primary">
            YES
          </Button>
          <Button onClick={handleNo} color="primary" autoFocus>
            NO
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}




