import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { leads } from './leads.reducer';
import { messages } from './messages.reducer';
import { messageModels } from './messageModels.reducer';
import { attachments } from './attachments.reducer';
import { procedures } from './procedure.reducer';
import { config } from './config.reducer';
import { analytics } from './analytic.reducer';
import { newsletters } from './newsletter.reducer';
import { surveys } from './survey.reducer';
import { source } from './source.reducer';
import { sourceWebsite } from './source.website.reducer';
import { sourceForm } from './source.form.reducer';
import { customers } from './crm.customer.reducer';
import { companies } from './crm.company.reducer';
import { tags } from './crm.tag.reducer';

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  leads,
  messages,
  messageModels,
  alert,
  attachments,
  config,
  procedures,
  analytics,
  newsletters,
  surveys,
  source,
  sourceWebsite,
  sourceForm,
  customers,
  companies,
  tags,

});

export default rootReducer;
