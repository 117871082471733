export const tagConstants = {

    ACTION_SAVE: 'TAG_ACTION_SAVE',

    KICK_REQUEST: 'TAG_KICK_REQUEST',
    KICK_SUCCESS: 'TAG_KICK_SUCCESS',
    KICK_FAILURE: 'TAG_KICK_FAILURE',

    GETALL_REQUEST: 'TAG_GETALL_REQUEST',
    GETALL_SUCCESS: 'TAG_GETALL_SUCCESS',
    GETALL_FAILURE: 'TAG_GETALL_FAILURE',

    GETONE_REQUEST: 'TAG_GETONE_REQUEST',
    GETONE_SUCCESS: 'TAG_GETONE_SUCCESS',
    GETONE_FAILURE: 'TAG_GETONE_FAILURE',

    DELETE_REQUEST: 'TAG_DELETE_REQUEST',
    DELETE_SUCCESS: 'TAG_DELETE_SUCCESS',
    DELETE_FAILURE: 'TAG_DELETE_FAILURE',


};
