import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Button, Link, Toolbar as MuiToolbar, Typography } from '@material-ui/core';
import { history } from 'helpers';
import { ZnButton } from 'common/znFields/ZnButton';
import { NavLink, useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const DetailToolbar = props => {
  const { className, id, onClickEdit, onClickDelete, ...rest } = props;



  const classes = useStyles();

  const handleItemClickDelete = () => {
    onClickDelete && onClickDelete()
  }

  const handleItemEdit = () => {
    onClickEdit && onClickEdit()
  }


  const handleBack = () => {
    history.push("/customer/")
  };


  return (
    <AppBar color="default" position="sticky">
      <MuiToolbar>
        <NavLink to="/customer" ><Typography >  {'<'} go back</Typography></NavLink>
        <span className={classes.spacer} />
        <Button
          onClick={e => handleItemEdit(id)}
          color="primary"
        >Edit</Button>

        <ZnButton
          onClick={e => handleItemClickDelete(id)}
          color="primary"
          confirm
          confirmTitle="Do you really want to delete it ?"
          // confirmText={`Please confirm you want to delete the customer ${i.name}.`}
          confirmText={`Please confirm you want to delete the customer .`}
        >Delete</ZnButton>

      </MuiToolbar>
    </AppBar>
  );
};

DetailToolbar.propTypes = {
  className: PropTypes.string
};

export default DetailToolbar;
