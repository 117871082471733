import { tagConstants } from '../_constants';

const initialStatus =  {
  successAction: false,
  items : [],
  total: 0,
  item : null,
  loading : false,
  error : null,

};

export function tags(state = initialStatus, action) {

  
  switch (action.type) {
    case tagConstants.KICK_REQUEST:
      return {
        ...state,
        successAction: false,
        doingAction: true,
        actionError: false,
        actionErrorMsg: null,

      };

    case tagConstants.KICK_SUCCESS:
      const key = state.items.findIndex(item => item.id === action.tag.id);
      let ii = state.items;
      
      if (key in ii) {
        ii.splice(key, 1, action.tag);
      } else {
        ii.push(action.tag);
      }

      return {
        ...state,
        successAction: true,
        doingAction: false,
        actionError: false,
        actionErrorMsg: null,
        items: ii,
      };
      break;

    case tagConstants.KICK_FAILURE:
        return {
          ...state,
          successAction: false,
          doingAction: false,
          actionError: true,
          actionErrorMsg: action.error,


        };



    case tagConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tagConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.tags,
        total: action.meta.total,

        loading: false
      };
    case tagConstants.GETALL_FAILURE:
      return { 
        ...state,
        error: action.error,
        loading: false
      };
    case tagConstants.GETONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tagConstants.GETONE_SUCCESS:
      return {
        ...state,
        item: action.tag,
        loading: false
      };
    case tagConstants.GETONE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case tagConstants.DELETE_REQUEST:
      // add 'deleting:true' property to tag being deleted
      return {
        ...state,
        items: state.items.map(tag =>
          tag.id === action.id
            ? { ...tag, deleting: true }
            : tag
        )
      };
    case tagConstants.DELETE_SUCCESS:
      // remove deleted tag from state
      return {
        ...state,
        items: state.items.filter(tag => tag.id !== action.id)
      };
    case tagConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to tag 
      return {
        ...state,
        items: state.items.map(tag => {
          if (tag.id === action.id) {
            // make copy of tag without 'deleting:true' property
            const { deleting, ...tagCopy } = tag;
            // return copy of tag with 'deleteError:[error]' property
            return { ...tagCopy, deleteError: action.error };
          }

          return tag;
        })
      };
    default:
      return state;
  }
}