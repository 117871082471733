import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Checkbox, FormControlLabel, Hidden, AppBar, Toolbar as MuiToolbar, Button } from '@material-ui/core';
import { SearchInput } from 'components';


import { leadConstants } from '_constants';
import { messageService } from '_services';
import { ZnSimpleSourceWebsiteSelect, ZnSourceWebsiteSelect } from 'common/znFields/ZnSourceSelect';


const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  fabProgress: {
    position: 'absolute',
    top: -4,
    left: -4,
    zIndex: 1,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

const Toolbar = props => {

  const { className, setFilter, handleNewItemClick, ...rest } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    values: {
    text: "",
      statuses: [],
      source_website_id: null
    }

  });

  useEffect(() => {
    setFilter(formState.values);
  }, [formState]);



  const handleTextChange = event => {
    handleFieldChange('text', event.target.value);
  };
   
  const selectHandleChange = (value) => {
    handleFieldChange('source_website_id', value);
  };
    
  const handleFieldChange = (fieldName, value) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [fieldName]: value,
      },
      touched: {
        ...formState.touched,
        [fieldName]: true
      }
    }));
  };


 
  const handleAddClick = () => {
    handleNewItemClick(null);
  }


  return (
    <AppBar color="default" position="sticky">
    <MuiToolbar>

        <SearchInput
          className={classes.searchInput}
          placeholder="Search"
          onChange={handleTextChange}
          name="search"
        />
      <div >
        <ZnSimpleSourceWebsiteSelect
        // style={{maxWidth: "100px"}}
          id="source_website_id"
          label="Website"
          onChange={selectHandleChange}
        /></div>
        
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddClick}
        >New</Button>


      </MuiToolbar>
    </AppBar>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
