import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import TableList from 'common/TableList';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Chip, Divider, Grid, List, ListItem, ListItemText, Table, TableCell, TablePagination, TableRow, Typography } from '@material-ui/core';
import { Edit, Toolbar } from '.';
import { ZnButton } from 'common/znFields/ZnButton';
import DetailToolbar from './DetailToolbar';
import { Block, Check, Done, Warning } from '@material-ui/icons';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    backgroundColor: 'white'
  },
  content: {
    marginTop: theme.spacing(0),
    padding: theme.spacing(2),

  },
  spacer: {
    flexGrow: 1
  },
}));

const Detail = ({ id, mapper, icon, actions, reducer }) => {

  const history = useHistory();


  const data = reducer
  const classes = useStyles()
  const dispatch = useDispatch()


  // load data
  useEffect(() => {
    dispatch(actions.getById(id));
  }, [id]);

  // modal
  const [open, setOpen] = React.useState(false);
  const [itemId, setItemId] = React.useState(null);
  const [item, setItem] = React.useState(null);
  useEffect(() => {
    setItem(data.item);
  }, [data]);

  const handleItemEdit = newId => {
    setItemId(newId);
    setOpen(true);
  };


  const handleItemClickDelete = () => {
    dispatch(actions._delete(item.id));
    history.push('/customer')
    
  }


  // renderer
  return (
    <div className={classes.root}>
      <DetailToolbar
        id={item?.id}
        onClickEdit={handleItemEdit}
        onClickDelete={handleItemClickDelete}
      />
      <div className={classes.content}>

        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={4}>
            <Card >
              <CardHeader title='Customer'></CardHeader>
              <CardContent>

                <Table>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell><Typography >{(item && item.name) ? item.name : '-'}</Typography></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Surname</TableCell>
                    <TableCell><Typography >{(item && item.name) ? item.name : '-'}</Typography></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell><Typography >{(item && item.email) ? item.email : '-'}</Typography></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Phone</TableCell>
                    <TableCell><Typography >{(item && item.phone) ? item.phone : '-'}</Typography></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Country</TableCell>
                    <TableCell><Typography >{(item && item.country) ? item.country : '-'}</Typography></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Company</TableCell>
                    <TableCell><Typography >{(item && item.company) ? item.company : '-'}</Typography></TableCell>
                  </TableRow>
                </Table>

              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card
            >
              <CardHeader title='Marketing'></CardHeader>
              <CardContent>
                <Table>
                  <TableRow>
                    <TableCell>Added from</TableCell>
                    <TableCell><Typography >{(item && item.added_from) ? item.added_from : '-'}</Typography></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Added at</TableCell>
                    <TableCell><Typography >{(item && item.added_at) ? moment(item.added_at).format('DD/MM/YYYY kk:mm') : '-'}</Typography></TableCell>

                  </TableRow>
                  <TableRow>
                    <TableCell>Privacy Mkt</TableCell>
                    <TableCell><Typography >{item && item.privacy ? <Check></Check> : '-'}
                    </Typography></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Privacy Mkt from</TableCell>
                    <TableCell><Typography >{(item && item.privacy_at) ? moment(item.privacy_at).format('DD/MM/YYYY') : '-'}</Typography></TableCell>
                  </TableRow>
                </Table>
              </CardContent>
              <CardActions>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <CardHeader   title="Maichimp" action={ 
                item && item.mc_last_error === '0' ? (<Done color='success'></Done>):
                item && item.mc_last_error === '' ? '': (<Warning color='primary'></Warning>)}></CardHeader>

              
              <CardContent>
                <Table>

                  <TableRow>
                    <TableCell>Synched at</TableCell>
                    <TableCell><Typography >{(item && item.mc_synched_at) ? moment(item.mc_synched_at).format('DD/MM/YYYY kk:mm') : '-'}</Typography></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Last error</TableCell>
                    <TableCell>{(item && item.mc_last_error != 0) ? (<Typography color='primary' >{item.mc_last_error}</Typography>) : '-'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Mc Status</TableCell>
                    <TableCell>
                      <Typography >{(item && item.mc_info) ? item.mc_info.status : '-'}</Typography></TableCell>
                  </TableRow>


                </Table>
              </CardContent>
              <CardActions>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card
            >
              <CardHeader title='Tags'></CardHeader>
              <CardContent>
                {(item && item.tags.length == 0) && (
                  <Typography align='center'>The customer has no tags.</Typography>
                )}
                <List>
                  {item && item.tags.map((v, k) => (
                    <ListItem><Chip label={v} /></ListItem>
                  ))}
                </List>


              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <Edit
        actions={actions}
        setOpen={setOpen}
        open={open}
        item={item}
        reducer={reducer}
      />
    </div>
  );
};




export default Detail;
