import { surveyConstants } from '../_constants';
import { surveyService } from '../_services';

export const surveyActions = {
    getAll,
};



function getAll(page = '', per_page = '') {

    return (dispatch) => {


        dispatch(request());

        surveyService.getAll(page, per_page,)
            .then(
                surveys => dispatch(success(surveys.data, surveys.meta)),
                error => dispatch(failure(error))
            );
    };

    function request() {
        return { type: surveyConstants.GETALL_REQUEST }
    }
    function success(surveys, meta) {
        return { type: surveyConstants.GETALL_SUCCESS, surveys, meta }
    }
    function failure(error) {
        return { type: surveyConstants.GETALL_FAILURE, error }
    }
}
