import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import theme from "theme";

const styles = {
  root: {
    zIndex: 10,
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - 240px)'
    }
  },
  show: {
    transform: "translate(0, 0)",
    transition: "transform .3s"
  },
  hide: {
    transform: "translate(0, -56px)",
    transition: "transform .3s",

    [theme.breakpoints.up('lg')]: {
      transform: "translate(0, -64px)"
    },
    
    
  }
};

class CollapsibleAppBar extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      shouldShow: true
    };

    this.lastScroll = null;

    this.handleScroll = this.handleScroll.bind(this);
    // Alternatively, you can throttle scroll events to avoid
    // updating the state too often. Here using lodash.
    // this.handleScroll = _.throttle(this.handleScroll.bind(this), 100);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    const lastScroll = window.scrollY;

    if (lastScroll < 20) {
      this.setState((prevState) => ({
        ...prevState,
        shouldShow: true
      }));
      return;
    }

    

    if (lastScroll === this.state.lastScroll) {
      return;
    }

    if (Math.abs(lastScroll-this.lastScroll)< 20) return;

    const shouldShow =
      this.lastScroll !== null ? lastScroll < this.lastScroll : null;


    if (shouldShow !== this.state.shouldShow) {
      this.setState((prevState) => ({
        ...prevState,
        shouldShow
      }));
    }

    this.lastScroll = lastScroll;
  }

  getScrollClassName() {
    if (this.state.shouldShow === null) {
      return "";
    }

    return this.state.shouldShow
      ? this.props.classes.show
      : this.props.classes.hide;
  }

  render() {
    const { classes } = this.props;
    return (
      <AppBar position="fixed" className={`${classes.root} ${this.getScrollClassName()}`} >
        {this.props.children}
      </AppBar>
    );
  }
}

CollapsibleAppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CollapsibleAppBar);
