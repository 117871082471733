/* eslint-disable no-use-before-define */
import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Select, MenuItem, Chip, TextField, OutlinedInput, FormControl, InputLabel } from '@material-ui/core';
import { tagActions } from '_actions';

export {
  ZnTagSelect,

}


const defaultCurrentItem = [""]

const ZnTagSelect = (props) => {

  const {
    name,
    onChange,
    value,
  } = props;


  const items = useSelector(state => state.tags.items);
  const [currentItem, setCurrentItem] = useState(defaultCurrentItem);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(tagActions.getAll());
  }, []);

  const handleChange = event => {
    setCurrentItem(event.target.value);
  };

  useEffect(()=> {
    if (value == "") {
      setCurrentItem(defaultCurrentItem)
    }
  }, [value])

  useEffect(() => {
    onChange && onChange({
      target: {
        name: name,
        value: currentItem
      }
    });
  }, [currentItem]);


  return (
    <Select
      style={{ marginLeft: '10px' }}
      name={name}
      value={currentItem}
      onChange={handleChange}
      multiple
      displayEmpty
    >
      <MenuItem disabled value="">Tags:</MenuItem>

      {items.map((v, k) => (
        <MenuItem value={v.id}>{v.name}</MenuItem>
      ))}

    </Select>
  );

}

