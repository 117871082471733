export const messageConstants = {

    STATUS_DRAFT: 'MESSAGE_STATUS_DRAFT',
    STATUS_OUTGOING: 'MESSAGE_STATUS_OUTGOING',
    STATUS_SENT: 'MESSAGE_STATUS_SENT',
    STATUS_ERROR: 'MESSAGE_STATUS_ERROR',



    DIRECTION_INCOMING: "MESSAGE_DIRECTION_INCOMING",
    DIRECTION_OUTGOING: "MESSAGE_DIRECTION_OUTGOING",

    ACTION_SAVE: 'MESSAGE_ACTION_SAVE',

    KICK_REQUEST: 'MESSAGE_KICK_REQUEST',
    KICK_SUCCESS: 'MESSAGE_KICK_SUCCESS',
    KICK_FAILURE: 'MESSAGE_KICK_FAILURE',
   
    GETALL_REQUEST: 'MESSAGE_GETALL_REQUEST',
    GETALL_SUCCESS: 'MESSAGE_GETALL_SUCCESS',
    GETALL_FAILURE: 'MESSAGE_GETALL_FAILURE',

    GETONE_REQUEST: 'MESSAGE_GETONE_REQUEST',
    GETONE_SUCCESS: 'MESSAGE_GETONE_SUCCESS',
    GETONE_FAILURE: 'MESSAGE_GETONE_FAILURE',

    DELETE_REQUEST: 'MESSAGE_DELETE_REQUEST',
    DELETE_SUCCESS: 'MESSAGE_DELETE_SUCCESS',
    DELETE_FAILURE: 'MESSAGE_DELETE_FAILURE',
};
