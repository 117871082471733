import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Button, Toolbar as MuiToolbar } from '@material-ui/core';
import { SearchInput } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = props => {
  const { className, setFilter, handleClick, ...rest } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    text: ''
  });

  const [typing, setTyping] = useState(false);

  const handleTextChange = event => {
    if (typing !== false) {
      clearTimeout(typing);
      setTyping(false);
    }

    const st = {
      ...formState,
      text: event.target.value
    };

    setFormState(st);

    setTyping(
      setTimeout(function() {
        setFilter(st);
      }, 400)
    );
  };

  const handleAddClick = () => {
    handleClick(null);
  };

  return (
    <AppBar color="default" position="sticky">
      <MuiToolbar>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search"
          onChange={handleTextChange}
          name="search"
        />
        <span className={classes.spacer} />
        <Button color="primary" variant="contained" onClick={handleAddClick}>
          New
        </Button>
      </MuiToolbar>
    </AppBar>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
