import { newsletterConstants } from '../_constants';
import { newsletterService } from '../_services';
import { alertActions } from './alert.actions';
import { alertConstants } from '../_constants';




export const newsletterActions = {
    syncAll,
    getAll,
};



function syncAll(ids) {

    return dispatch => {
        dispatch(request());
        
        newsletterService.syncAll(ids)
            .then(
                newsletters => {
                    dispatch(alertActions.success('E-mails marked to be sync'));
                    dispatch(success(newsletters.data, newsletters.meta));
                },
                error => dispatch(failure(error))
            );


    };

    function request() {
        return { type: newsletterConstants.SYNCALL_REQUEST };
    }
    function success(newsletters, meta) {
        return { type: newsletterConstants.SYNCALL_SUCCESS, newsletters, meta }
    }
    function failure(error) {
        return { type: newsletterConstants.SYNCALL_FAILURE, error };
    }
}

function getAll( page = '', per_page = '') { 

    return (dispatch) => {

        
        dispatch(request());

        newsletterService.getAll(page, per_page,)
            .then(
                newsletters => dispatch(success(newsletters.data, newsletters.meta)),
                error => dispatch(failure(error))
            );
    };

    function request() {
        return { type: newsletterConstants.GETALL_REQUEST }
    }
    function success(newsletters, meta) {
        return { type: newsletterConstants.GETALL_SUCCESS, newsletters, meta }
    }
    function failure(error) {
        return { type: newsletterConstants.GETALL_FAILURE, error }
    }
}
