import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { ZnUserValue } from 'common/znFields/ZnUserSelect';



const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});



const LeadAudit = ({data}) => {

  const classes = useStyles();

  const getData = ()  => {
    
    if (data ) {
      return data;
    }
    return [];

  }


  return (

    

        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>User</TableCell>
                <TableCell align="right">Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getData().map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">{row.action} </TableCell>
                  <TableCell align="left">
                    <ZnUserValue value={row.userId} />
                    </TableCell>
                  <TableCell align="right">{moment(row.created_at).fromNow()}</TableCell>
                  

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>


     

  );
};



export default LeadAudit;

