import React from 'react';
import { useSelector } from 'react-redux';
import List from './components/List';

import { BusinessIcon, PeopleIcon } from 'icons';
import { companyActions as actions } from '_actions';

const listMapper = item => {
  return {
    id: item.id,
    action: null,
    primary: item.name,
    secondary: null
  };
};

const icon = <BusinessIcon />

const View = () => {
  const reducer = useSelector(state => state.companies);

  return (
    <List mapper={listMapper} icon={icon} actions={actions} reducer={reducer} />
  );
};

export default View;
