import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ZnTextField from 'common/znFields/ZnTextField';
import { useState } from 'react';
import { ZnDepartmentMultiSelect } from 'common/znFields/ZnDepartmentSelect';
import { attachmentActions } from '_actions';
import { useDispatch, useSelector } from 'react-redux';
import validate from 'validate.js';
import ZnLanguageSelect from 'common/znFields/ZnLanguageSelect';
import ZnAttachmentField from 'common/znFields/ZnAttachmentField';



const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 200
    }
  },

  file: function(value, attributes, attributeName, options, constraints) {
    if (attributes.fileName && attributes.fileName !== '' ) return null;
    return {
      presence: { allowEmpty: false, message: 'is required' }

    };
  }
};


const AttachmentDetail = props => {

  const { open, setOpen, attachment } = props;

  const dispatch = useDispatch();

  const handleChange = (event) => {
    event.persist();
    handleFieldChange(event.target.name, event.target.type === 'checkbox'
      ? event.target.checked
      : event.target.value);
  };




  const languageHandleChange = (event, values) => {
    handleFieldChange('language', values ? values.code : "");
  };

  const departmentsHandleChange = ( values) => {
    handleFieldChange('departments',values );
  };

  const handleFieldChange = (fieldName, value) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [fieldName]: value,

      },
      touched: {
        ...formState.touched,
        [fieldName]: true
      }
    }));
  };

  const handleSubmit = event => {

    if (!formState.isValid) return;

    if (event.type === 'keypress' && event.key !== 'Enter') {
      return;
    }
    event.preventDefault();
    setFormState(formState => ({
      ...formState,
      touched: schema
    }));


    dispatch(attachmentActions.save(formState.values));
  };

  const doingAction = useSelector(state => state.attachments.doingAction);
  const successAction = useSelector(state => state.attachments.successAction);
  const actionErrorMsg = useSelector(state => state.attachments.actionErrorMsg);

  useEffect(() => {
    if (successAction === true) handleClose();
  }, [successAction]);

  const handleClose = () => {
    if (!doingAction) {
      setOpen(false);
      setFormState(
        {
          loaded: false,
          isValid: false,
          values: {
            name: '',
            language: '',
            file: "", 
            fileName: "",
            departments: [],
          },
          touched: {},
          errors: {}
        }
      );
    }
  };

  const [formState, setFormState] = useState({
    loaded: false,
    isValid: false,
    values: {
      name: '',
      language: '',
      file: "", 
      fileName: "",
      departments: [],
    },
    touched: {},
    errors: {}
  });

  const formValidation = () => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }

  useEffect(() => {
    formValidation();
  }, [formState.values]);

  useEffect(() => {
    if (attachment) {
      setFormState({
        ...formState,
        values: attachment,
      });
    } else {
      setFormState({
        loaded: false,
        isValid: false,
        values: {
          name: '',
          language: '',
          file: "", 
          fileName: "",
          departments: [],

        },
        touched: {},
        errors: {}
      });
    }
  }, [attachment, open]);


  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      isValid: actionErrorMsg ? false : true,
      errors: actionErrorMsg ? actionErrorMsg.errors : {},
    }));
  }, [actionErrorMsg]);

  const hasError = (field) => {
    return (formState.errors && formState.errors[field]) ? true : false;
  }

  const getError = (field) => {
    return (formState.errors && formState.errors[field]) && formState.errors[field][0];
  }


  const handleClick = event => {
    event.target.value = "";

  }

  const handleAttach = (file, fileName) => {
    handleFieldChange('file', file);
    handleFieldChange('fileName', fileName);
  }

  const handleAttachDelete = () => {
    handleFieldChange('file', ``);
    handleFieldChange('fileName', ``);
  }

  const handleAttachClick = fileName => {
    attachmentActions.download('attachments/att/' , fileName);

  }

  

  return (
    <div>

      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {attachment ? "Attachment detail" : "New attachment"}
        </DialogTitle>
        <DialogContent>
          <ZnLanguageSelect
            id="language"
            label="language"
            onChange={languageHandleChange}
            onKeyPress={handleSubmit}
            value={formState.values.language}
            error={hasError('language')}
            helperText={
              getError('language')
            }
          />
          <ZnTextField
            onKeyPress={handleSubmit}
            name="name"
            label="Name"
            onChange={handleChange}
            value={formState.values.name || ""}
            error={hasError('name')}
            helperText={
              getError('name')
            }
            margin="normal"
          />
          <ZnDepartmentMultiSelect
            id="departments"
            label="Departments"
            onChange={departmentsHandleChange}
            onKeyPress={handleSubmit} 
            values={formState.values.departments || []}
            error={hasError('departments')}
            helperText={
              getError('departments')
            }
          />





          
<ZnAttachmentField 
            fileName={formState.values.fileName} 
            onAttach={handleAttach}  
            onAttachDelete={handleAttachDelete}
            onAttachClick={handleAttachClick}/>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AttachmentDetail.defaultProps = {
  open: false,
  attachment: {},
}
export default AttachmentDetail;
