import React, { useEffect, Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Toolbar, AppBar, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { analyticActions } from '_actions';
import ZnDateRange from 'common/znFields/ZnDateRange';
import moment from 'moment';
import PerformanceByDepartments from './components/Carts/PerformanceByDepartments';



const defaultStartDate = new Date('2020-01-01');
const defaultEndDate = new Date();

const useStyles = makeStyles(theme => ({
  cardbox: {
    maxWidth: '100px',
    backgroundColor: 'lightgray',
    borderRadius: 20
  },
  root: {
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));



const Analytics = () => {

  const classes = useStyles();

  const cartData = useSelector(state => state.analytics.cart3)

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const handleDateRangeChange = (dateRange) => {
    setStartDate(dateRange.startDate);
    setEndDate(dateRange.endDate);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    const from = moment(startDate).format('YYYY-MM-DD');
    const to = moment(endDate).format('YYYY-MM-DD');

    dispatch(analyticActions.getCart3(from, to));
  }, [startDate, endDate]);



  return (
    <Fragment>
      <AppBar color="default" position="sticky">
        <Toolbar>
          <ZnDateRange
            onChange={handleDateRangeChange}
            defaultStartDate={defaultStartDate}
            defaultEndDate={defaultEndDate}
          />
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <Grid
          container
          spacing={4}
        >
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Typography variant='h4'>PERFORMANCE</Typography>
          </Grid>
          
          
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <PerformanceByDepartments
             title="Departments Performance" 
             subheader="performance by departments in days"
              categories={cartData?.performanceByDepartments?.categories}
              series={cartData?.performanceByDepartments?.series}
            />
          </Grid>


        </Grid>
      </div>
    </Fragment>
  );
};

export default Analytics;
