/* eslint-disable no-use-before-define */
import React, { Fragment } from 'react';
import ZnSelect from './ZnSelect';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { messageModelActions } from '_actions';

export {
  ZnMessageModelSelect,
  ZnMessageValue,
}

const ZnMessageModelSelect = (props) => {

  const { 
    id, 
    label, 
    onChange, 
    onKeyPress, 
    value, 
    error, 
    helperText ,
    language
  } = props;


  const messageModels = useSelector(state => state.messageModels.items)
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  useEffect(() => {
    dispatch(messageModelActions.getAll());
  }, []);

  useEffect(() => {
    // const country = language === 'IT' ? 'IT' : 'EN';
    setItems(countries(messageModels));
  }, [messageModels]);

  return (
   
    <ZnSelect
      id={id}
      label={label}
      onChange={onChange}
      onKeyPress={onKeyPress}
      value={value}
      error={error}
      helperText={helperText}
      items={items}
    />
  );
}

const ZnMessageValue = (props) => {

  const { value, language } = props;

  const [label, setLabel] = useState(null);


  const messageModels = useSelector(state => state.messageModels.items)
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  useEffect(() => {
    dispatch(messageModelActions.getAll());
  }, []);

  useEffect(() => {
    setItems(countries(messageModels));
  }, [messageModels]);

  useEffect(() => {
    const code = items.filter(item => { return item && item.code === value }).shift();
    if (code && code.label) setLabel(code.label);
  }, [value]);

  return (
  <Fragment>{label}</Fragment>
  );
}

const countries = (models) => {

  if (!Array.isArray(models)) return [];

  return models.map(x => {
    return { code: x.id, label: x.name };
  });
}


