import { sourceWebsiteConstants } from '../_constants';

const initialStatus =  {
  successAction: false,
  items : [],
  total: 0,
  item : null,
  loading : false,
  error : null,

};

export function sourceWebsite(state = initialStatus, action) {

  
  switch (action.type) {
    case sourceWebsiteConstants.KICK_REQUEST:
      return {
        ...state,
        successAction: false,
        doingAction: true,
        actionError: false,
        actionErrorMsg: null,

      };

    case sourceWebsiteConstants.KICK_SUCCESS:
      const key = state.items.findIndex(item => item.id === action.sourceWebsite.id);
      let ii = state.items;
      
      if (key in ii) {
        ii.splice(key, 1, action.sourceWebsite);
      } else {
        ii.push(action.sourceWebsite);
      }

      return {
        ...state,
        successAction: true,
        doingAction: false,
        actionError: false,
        actionErrorMsg: null,
        items: ii,
      };
      break;

    case sourceWebsiteConstants.KICK_FAILURE:
        return {
          ...state,
          successAction: false,
          doingAction: false,
          actionError: true,
          actionErrorMsg: action.error,


        };



    case sourceWebsiteConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sourceWebsiteConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.sourceWebsites,
        total: action.meta.total,

        loading: false
      };
    case sourceWebsiteConstants.GETALL_FAILURE:
      return { 
        ...state,
        error: action.error,
        loading: false
      };
    case sourceWebsiteConstants.GETONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sourceWebsiteConstants.GETONE_SUCCESS:
      return {
        ...state,
        item: action.sourceWebsite,
        loading: false
      };
    case sourceWebsiteConstants.GETONE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case sourceWebsiteConstants.DELETE_REQUEST:
      // add 'deleting:true' property to sourceWebsite being deleted
      return {
        ...state,
        items: state.items.map(sourceWebsite =>
          sourceWebsite.id === action.id
            ? { ...sourceWebsite, deleting: true }
            : sourceWebsite
        )
      };
    case sourceWebsiteConstants.DELETE_SUCCESS:
      // remove deleted sourceWebsite from state
      return {
        ...state,
        items: state.items.filter(sourceWebsite => sourceWebsite.id !== action.id)
      };
    case sourceWebsiteConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to sourceWebsite 
      return {
        ...state,
        items: state.items.map(sourceWebsite => {
          if (sourceWebsite.id === action.id) {
            // make copy of sourceWebsite without 'deleting:true' property
            const { deleting, ...sourceWebsiteCopy } = sourceWebsite;
            // return copy of sourceWebsite with 'deleteError:[error]' property
            return { ...sourceWebsiteCopy, deleteError: action.error };
          }

          return sourceWebsite;
        })
      };
    default:
      return state;
  }
}