export const configConstants = {
    SET_DEPARTMENT: "SET_DEPARTMENT",
    SET_USER: "SET_USER",
    
    SET_ACTIVE_SEARCH_BOX: "CONFIG_SET_ACTIVE_SEARCH_BOX",
    
    SET_LEAD_RELOAD: "CONFIG_SET_LEAD_RELOAD",
    
    RESET_LEAD_SEARCH: "CONFIG_RESET_LEAD_SEARCH",
    SET_LEAD_SEARCH_TEXTS: "CONFIG_SET_LEAD_SEARCH_TEXTS",
    SET_LEAD_SEARCH_TEXT: "CONFIG_SET_LEAD_SEARCH_TEXT",
    SET_LEAD_SEARCH_EMAIL: "CONFIG_SET_LEAD_SEARCH_EMAIL",
    SET_LEAD_SEARCH_PHONE: "CONFIG_SET_LEAD_SEARCH_PHONE",
    SET_LEAD_SEARCH_SUBJECT: "CONFIG_SET_LEAD_SEARCH_SUBJECT",
    SET_LEAD_SEARCH_NOTES: "CONFIG_SET_LEAD_SEARCH_NOTES",

    SET_LEAD_SEARCH_STATUSES: "CONFIG_SET_LEAD_SEARCH_STATUSES",
    SET_LEAD_SEARCH_DATES: "CONFIG_SET_LEAD_SEARCH_DATES",
    SET_LEAD_SEARCH_DATE_FROM: "CONFIG_SET_LEAD_SEARCH_DATE_FROM",
    SET_LEAD_SEARCH_DATE_TO: "CONFIG_SET_LEAD_SEARCH_DATE_TO",

    SET_LEAD_PAGE: "CONFIG_SET_LEAD_PAGE",
    SET_LEAD_ROWS_PER_PAGE: "CONFIG_SET_LEAD_ROWS_PER_PAGE",
    
    SET_MESSAGE_SEARCH_TEXT: "CONFIG_SET_MESSAGE_SEARCH_TEXT",
    SET_MESSAGE_SEARCH_STATUSES: "CONFIG_SET_MESSAGE_SEARCH_STATUSES",

    SET_NEWSLETTER_PAGE: "CONFIG_SET_NEWSLETTER_PAGE",
    SET_NEWSLETTER_ROWS_PER_PAGE: "CONFIG_SET_NEWSLETTER_ROWS_PER_PAGE",

    SET_SURVEY_PAGE: "CONFIG_SET_SURVEY_PAGE",
    SET_SURVEY_ROWS_PER_PAGE: "CONFIG_SET_SURVEY_ROWS_PER_PAGE",

};
