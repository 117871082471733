import { authHeader } from '../helpers';
import handleResponse from './handleResponse';


export const attachmentService = {
    
    doAction,
    getAll,
    getById,
    attach,
    add,
    addFromMessageModel,
    remove,
    getAllByMessageId,
    download,
    _delete,
};

function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/attachment/${id}`, requestOptions).then(handleResponse);
}

function download(attachementId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/media/download/${attachementId}`, requestOptions);
}


function doAction(attachmentId, action, data = []) {
    return kick({
        'action': action,
        'attachmentId': attachmentId,
        'data' : data
    });
}

function kick(data) {
    

    const formData = new FormData();
    formData.append('attachmentId', data.data.id);
    formData.append('name', data.data.name);
    formData.append('language', data.data.language);
    formData.append('file', data.data.file);
    formData.append('fileName', data.data.fileName);
    formData.append('departments', data.data.departments);
    

    const requestOptions = {
        method: 'POST',
        headers: authHeader(false),
        body: formData,
        redirect: 'follow',
    };
    

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/attachment`, requestOptions).then(handleResponse);
}

function getAll(departmentIds = []) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/attachment?departmentIds=${departmentIds.join()}`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/attachment/${id}`, requestOptions).then(handleResponse);
}


function getAllByMessageId(modelName, messageId) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/media/${modelName}/${messageId}`, requestOptions).then(handleResponse);

}

function attach(modelName, messageId, file) {

    const formData = new FormData();
    formData.append('modelName', modelName);
    formData.append('messageId', messageId);
    formData.append('media', file);
    

    const requestOptions = {
        method: 'POST',
        headers: authHeader(false),
        body: formData,
        redirect: 'follow',
    };
    

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/media/${modelName}`, requestOptions).then(handleResponse);
}

function add(modelName, messageId, fileName) {

    const formData = new FormData();
    formData.append('modelName', modelName);
    formData.append('messageId', messageId);
    formData.append('fileName', fileName);
    

    const requestOptions = {
        method: 'POST',
        headers: authHeader(false),
        body: formData,
        redirect: 'follow',
    };
    

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/media/${modelName}`, requestOptions).then(handleResponse);
}

function addFromMessageModel(modelName, messageId, messageModelId) {

    const formData = new FormData();
    formData.append('modelName', modelName);
    formData.append('messageId', messageId);
    formData.append('messageModelId', messageModelId);
    

    const requestOptions = {
        method: 'POST',
        headers: authHeader(false),
        body: formData,
        redirect: 'follow',
    };
    

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/media/${modelName}`, requestOptions).then(handleResponse);
}

function remove(modelName, messageId, fileName) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/media/${modelName}/${messageId}/${fileName}`, requestOptions).then(handleResponse);
}
