import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField
} from '@material-ui/core';
import { alertActions, userActions } from '_actions';
import { useDispatch } from 'react-redux';
import ZnPasswordField from 'common/znFields/ZnPasswordField';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Password = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    password: '',
    confirm: ''
  });

  const dispatch = useDispatch();
  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = event => {
    if(values.password === ``){
      return;
    }
    if(values.password !== values.confirm) {
      dispatch(alertActions.error("Password mismatch."));
      return;
    }
    dispatch(userActions.updatePassword(values.password));
    setValues({
      password: '',
      confirm: ''
    });

  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          subheader="Update password"
          title="Password"
        />
        <Divider />
        <CardContent>
          <ZnPasswordField
            fullWidth
            label="New Password"
            name="password"
            onChange={handleChange}
            value={values.password}
            variant="outlined"
          />
          <ZnPasswordField
            fullWidth
            label="Confirm password"
            name="confirm"
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            value={values.confirm}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <CardActions >
          <Button 
            color="primary"
            variant="outlined"
            onClick={handleSubmit}
          >
            Update Password
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
