import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowForwardIos';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TableSortLabel,
  Box,
  Chip,
  Tooltip

} from '@material-ui/core';
import { history } from 'helpers';
import LongMenu from './LongMenu';
import Typography from 'theme/typography';
import { ZnButton } from './znFields/ZnButton';
import { Check, Done, Flag, Warning } from '@material-ui/icons';
import moment from 'moment';



const useStyles = makeStyles(theme => ({
  root: {},
  listItem: {
    fontWeight: "strong",
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2),
    backgroundColor: 'lightgray',

  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const headCells = [
  {
    id: 'id',
    label: 'Id',
    sortable: true,
  },
  {
    id: 'added_from',
    label: 'Added from',
    sortable: true,
  },
  {
    id: 'email',
    label: 'Email',
    sortable: true,
  },
  {
    id: 'name',
    label: 'Name',
    sortable: true,
  },
  {
    id: 'surname',
    label: 'Surname',
    sortable: true,
  },
  {
    id: 'phone',
    label: 'Phone',
    sortable: true,
  },
  {
    id: 'company',
    label: 'Company',
    sortable: true,
  },
  {
    id: 'countries.name',
    label: 'Country',
    sortable: false,
  },
  // {
  //   id: 'privacy',
  //   label: 'Privacy Mkt',
  //   sortable: true,
  // },
  {
    id: 'tags',
    label: 'Tags',
    sortable: false,
  },
  {
    id: 'mc_status',
    label: 'MC',
    sortable: false,
  },
];


const TableList = ({ icon, currentItemId, className, items, itemMapper, onClick, onClickDelete, onClickOrderBy, actionPath }) => {


  const classes = useStyles();

  const handleClick = (item) => {
    if (item.action) {
      history.push(item.action)
    } else {
      if (onClick) onClick(item.id);
    }
  }

  // sort / orderBy
  const [orderBy, setOrderBy] = useState();
  const [orderDirection, setOrderDirection] = useState('asc');

  useEffect(() => {
    if (onClickOrderBy) onClickOrderBy(orderBy, orderDirection);
  }, [orderBy, orderDirection])

  const createSortHandler = (property) => (event) => {
    setOrderBy(property);
    setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc')

  };



  return (

    <div className={classes.inner}>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell key={headCell.id}>

                {headCell.sortable ? (
                  <TableSortLabel
                    active={headCell.id == orderBy}
                    direction={orderDirection}
                    onClick={createSortHandler(headCell.id)}
                  >
                    <Box component="span" >
                      {headCell.label}
                    </Box>
                  </TableSortLabel>
                ) : (
                  <Fragment>{headCell.label}</Fragment>

                )}

              </TableCell>

            ))}
            <TableCell style={{ textAlign: "center" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {items.map((i, k) => {

            // const item = itemMapper(i); if (!item) return null;
            return (

              <TableRow
                className={classes.tableRow}

                key={k}
              >
                <TableCell>
                  #{i.id}
                </TableCell>
                <TableCell>
                  {i.added_from ?? '-'}
                </TableCell>
                <TableCell>
                  {i.email ?? '-'}
                </TableCell>
                <TableCell>
                  {i.name ?? '-'}
                </TableCell>
                <TableCell>
                  {i.surname ?? '-'}
                </TableCell>
                <TableCell>
                  {i.phone ?? '-'}
                </TableCell>
                <TableCell>
                  {i.company ?? '-'}
                </TableCell>
                <TableCell>
                  {i.country ?? '-'}
                </TableCell>
                {/* <TableCell>
                  {i.privacy ? <Check></Check> : ''}
                </TableCell> */}
                <TableCell>
                  {i.tags &&  i.tags.map((v, k)=>(
                    <Chip size='small' label={v}/>
                  ))}
                </TableCell>
                <TableCell>
                {i.mc_last_error === '0' && (<Done color='success'></Done>)}
                {i.mc_last_error === '' && ''}
                {(i.mc_last_error !== '0' && i.mc_last_error !== '' ) && (
                  <Tooltip title={i.mc_last_error} >
                <Warning color='primary'></Warning></Tooltip>
                )}

                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <IconButton edge="end" aria-label="show" onClick={() => history.push("/customer/" + i.id)}>
                    <ArrowRightIcon ></ArrowRightIcon>
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>

  );

  
};

TableList.propTypes = {
  className: PropTypes.string,
};

export default TableList;
