import React from 'react';
import { Chip } from '@material-ui/core';
import { leadConstants } from '_constants';

const LeadStatusChip = ({ status }) => {

  if(!leadConstants.LEAD_STATUS_MESSAGES[status]) return null;

  
  const sts = leadConstants.LEAD_STATUS_MESSAGES[status].process_status;
  const color = leadConstants.LEAD_STATUS_MESSAGES[status].process_color;

  return (<Chip variant="default" size="small" style={{
    backgroundColor: color,
    color: '#ffffff'
  }} label={sts} />);

};

export default LeadStatusChip;
