/* eslint-disable no-use-before-define */
import React, { Fragment } from 'react';
import { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { useEffect } from 'react';

export {
  ZnModalConfirm,
}

const ZnModalConfirm = (props) => {

  const {
    data,
    opened = false,
    onConfirm = ()=>{},
    onDeny = ()=>{},
    confirmTitle = 'Are you sure?',
    confirmText = 'To confirm it?'
  } = props;

  // const [modalOn, setModalOn] = useState(false);

  // useEffect(()=> {
  //   setModalOn(opened);
  // }, 
  // [opened]);

  const handleNo = () => {
    onDeny(data)
    // setModalOn(false);
  }
  const handleYes = () => {
    // setModalOn(false);
    onConfirm(data)
  }

  return (
    <Dialog
      // open={modalOn}
      open={opened}
      onClose={handleNo}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{confirmTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {confirmText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleYes} color="primary">
          YES
        </Button>
        <Button onClick={handleNo} color="primary" autoFocus>
          NO
        </Button>
      </DialogActions>
    </Dialog>
  );
}




